import React from 'react';
import { useSelector } from 'react-redux';
import classes from "./infoSnackbar.module.css"
import { selectOpenInfoSnackBar } from './infoSnackbarSlice';
import check from "../../ui/Assets/Icons/checkmark.png"


function InfoSnackbar() {
const open = useSelector(selectOpenInfoSnackBar)

  return (
    <div className={classes.snackBarContainer}>
    { open ?   
        <div className={classes.snackBarContents} >
            <div className={classes.body}>
                <div className={classes.icon}><img src={check} alt=""/></div>
                <div className={classes.snackBarTitle}>
                    <p>
                Copied to clipboard!
                    </p>
                </div>
            </div>
        </div> : ""}
    </div>
  )
}

export default InfoSnackbar