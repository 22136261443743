import React, { useState } from "react";
import classes from "./vdsCheckTool.module.css";
import HorizontalDivider from "../../components/Horizontal Divider/HorizontalDivider";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../ui/Loading Spinner/LoadingSpinner";
import search from "../../ui/Assets/Icons/search.png";
import {
  selectVinForVDSCheck,
  setVinForVDSCheck,
} from "../backendDataInfoPage/backendInfoSlice";
import { triggerVDS } from "../backendDataInfoPage/triggerVDS";
import {
  selectVinStagesAndEnvironmentsCheck,
  selectIsLoadingDataVinCheck,
  selectVinAvailableInOtherEnvOrReg,
  selectVehicleNotFound,
} from "../backendDataInfoPage/vdsCheckSlice";
import VdsCheckTableCustom from "../backendDataInfoPage/vdsCheckTableCustom";

function VdsCheckTool() {
  const [vinError, setVinError] = useState(false);

  const dispatch = useDispatch();

  const vin = useSelector(selectVinForVDSCheck);
  const vdsCheck = useSelector(selectVinStagesAndEnvironmentsCheck);
  const vinAvailableInOtherEnvOrReg = useSelector(
    selectVinAvailableInOtherEnvOrReg
  );
  const isLoadingDataVINCheck = useSelector(selectIsLoadingDataVinCheck);
  const vehicleNotFound = useSelector(selectVehicleNotFound);

  const vinHandler = (userEnteredVin) => {
    dispatch(setVinForVDSCheck(userEnteredVin));
  };

  const searchVinHandler = (dispatch, vin) => {
    const vinRegex = /^[A-Za-z0-9]{17}$/;
    if (vin !== "") {
      if (vinRegex.test(vin)) {
        triggerVDS(dispatch, "live", "ece", vin);

        setVinError(false);
      } else {
        console.log("Invalid VIN!");
        setVinError(true);
      }
    } else {
      console.log("VIN is empty!");
    }
  };

  return (
    <div className={classes.vinByUuIDContainer}>
      <div className={classes.vinByUuIDSection}>
        <div className={classes.vinByUuIDSectionTitle}>
          Check Rel.4 VIN availability
        </div>
        <HorizontalDivider />
        <div className={classes.vinByUuIDSectionActionSection}>
          <div className={classes.regionSelectionRow}></div>
          <div
            className={classes.flexContainerRowSubtitle}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            {" "}
            Insert VIN:{" "}
          </div>
          <div className={classes.flexContainerRowSearch}>
            {/*  */}
            <input
              id="searchDetails"
              autoComplete="off"
              required
              data-cy="searchInput"
              value={vin || ""}
              onChange={(event) => vinHandler(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  vinHandler(event.target.value);
                }
              }}
              style={{ userSelect: "text", cursor: "auto" }}
              readOnly={false}
            ></input>
            <label
              htmlFor="searchDetails"
              className={classes.searchLabel}
              data-cy="searchLabel"
              style={{ userSelect: "text", cursor: "auto" }}
            ></label>
            <div className={classes.actionBtn}>
              <img
                src={search}
                alt=""
                onClick={() => {
                  searchVinHandler(dispatch, vin);
                }}
              ></img>{" "}
            </div>
          </div>
        </div>
        {vinError ? (
          <div className={classes.flexContainerRowError}>
            Please enter a valid VIN{" "}
          </div>
        ) : (
          <div
            className={classes.flexContainerRow}
            style={{ height: "1.5rem" }}
          ></div>
        )}

        {
          // "please wait" if VIN is checking :
          isLoadingDataVINCheck ? (
            <LoadingSpinner />
          ) : // if VIN does not exist on any env/regions:
          vehicleNotFound && !isLoadingDataVINCheck ? (
            <VdsCheckTableCustom />
          ) : // if VIN exists, but not with current selection:
          !isLoadingDataVINCheck && !vdsCheck && vinAvailableInOtherEnvOrReg ? (
            <VdsCheckTableCustom />
          ) : // if Vin is OK
          !isLoadingDataVINCheck && vdsCheck && vinAvailableInOtherEnvOrReg ? (
            <VdsCheckTableCustom />
          ) : (
            // but if nothing is performed, nothing is displayed:
            ""
          )
        }
      </div>
    </div>
  );
}
export default VdsCheckTool;
