import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";
import { postWithAuth } from "../../utils/api";

const initialState = {
  environmentPseudonymizer: "approval",
  // presetEnvironmentPseudonymizer: "approval",
  regionPseudonymizer: "ece",
  // presetRegionPseudonymizer: "ece",
  audiencePseudonymizer: "t1-v_p:odc|porsche|default|vehicle",
  presetAudiencePseudonymizer: "t1-v_p:odc|porsche|default|vehicle",
  vinPseudonymizer: "",
  vinPseudonymizerManual: "",
  pVinPseudonymizer: "",
  mbbUserIdPseudonymizer: "",
  pUserPseudonymizer: "",
  isLoadingDataPseudonymizer: false,
  userFromPseudoUser: {},
  pseudoUserFromUser: {},
  vinFromPseudoVin: {},
  pseudoVinFromVin: {},
  pseudoVINFromVINList: [],
  resultContent: "",
  errorDisplay: false,
  loadingCSV: false,
  pVINListForCSV: [],
  vinListForCSV: [],
  orderedCSVVinList: [],
  pseudoVINListWithVINS: [],
};

// Slice
const pseudonymizerSlice = createSlice({
  name: "pseudonymizer",
  initialState,
  reducers: {
    setEnvironmentPseudonymizer: (state, action) => {
      state.environmentPseudonymizer = action.payload;
    },
    setRegionPseudonymizer: (state, action) => {
      state.regionPseudonymizer = action.payload;
    },
    setVinPseudonymizer: (state, action) => {
      state.vinPseudonymizer = action.payload;
    },
    setPVinPseudonymizer: (state, action) => {
      state.pVinPseudonymizer = action.payload;
    },
    setVinPseudonymizerManual: (state, action) => {
      state.vinPseudonymizerManual = action.payload;
    },
    setMbbUserIdPseudonymizer: (state, action) => {
      state.mbbUserIdPseudonymizer = action.payload;
    },
    setPUserPseudonymizer: (state, action) => {
      state.pUserPseudonymizer = action.payload;
    },
    setAudiencePseudonymizer: (state, action) => {
      state.audiencePseudonymizer = action.payload;
    },
    setIsLoadingDataPseudonymizer: (state, action) => {
      state.isLoadingDataPseudonymizer = action.payload;
    },
    setUserFromPseudoUser: (state, action) => {
      state.userFromPseudoUser = action.payload;
    },
    setPseudoUserFromUser: (state, action) => {
      state.pseudoUserFromUser = action.payload;
    },
    setPseudoVINFromVIN: (state, action) => {
      state.pseudoVinFromVin = action.payload;
    },
    setPseudoVINFromVINList: (state, action) => {
      state.pseudoVINFromVINList = action.payload;
    },
    setVinFromPseudoVin: (state, action) => {
      state.vinFromPseudoVin = action.payload;
    },
    setResultContent: (state, action) => {
      state.resultContent = action.payload;
    },
    clearResultContent: (state, action) => {
      state.resultContent = "";
    },
    setErrorDisplay: (state, action) => {
      state.errorDisplay = action.payload;
    },
    setLoadingCSV: (state, action) => {
      state.loadingCSV = action.payload;
    },
    setPVINListForCSV: (state, action) => {
      state.pVINListForCSV = [...state.pVINListForCSV, action.payload];
      console.log(action.payload);
    },
    setVinListForCSV: (state, action) => {
      state.vinListForCSV = action.payload;
    },
    setOrderedCSVVinList: (state, action) => {
      state.orderedCSVVinList = action.payload;
    },
    clearPseudoVinList: (state, action) => {
      state.pVINListForCSV = [];
    },
    setPseudoVINListWithVINS: (state, action) => {
      state.pseudoVINListWithVINS = [
        ...state.pseudoVINListWithVINS,
        action.payload,
      ];
      console.log(action.payload);
    },
    clearPseudoVINListWithVINS: (state, action) => {
      state.pseudoVINListWithVINS = [];
    },
    clearPseudoVINFromVINList: (state, action) => {
      state.pseudoVINFromVINList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVINFrompVIN.fulfilled, (state, action) => {});
    builder.addCase(getpVINFromVIN.fulfilled, (state, action) => {});
    builder.addCase(getUserFromPseudoUser.fulfilled, (state, action) => {});
    builder.addCase(getPseudoUserFromUser.fulfilled, (state, action) => {});
  },
});

// Selectors

export const selectEnvironmentPseudonymizer = (state) =>
  state.pseudonymizer.environmentPseudonymizer;
export const selectRegiontPseudonymizer = (state) =>
  state.pseudonymizer.regionPseudonymizer;
export const selectVinPseudonymizer = (state) =>
  state.pseudonymizer.vinPseudonymizer;
export const selectVinPseudonymizerManual = (state) =>
  state.pseudonymizer.vinPseudonymizerManual;
export const selectPVinPseudonymizer = (state) =>
  state.pseudonymizer.pVinPseudonymizer;
export const selectMbbUserIdPseudonymizer = (state) =>
  state.pseudonymizer.mbbUserIdPseudonymizer;
export const selectPUserPseudonymizer = (state) =>
  state.pseudonymizer.pUserPseudonymizer;
export const selectAudiencePseudonymizer = (state) =>
  state.pseudonymizer.audiencePseudonymizer;
export const selectIsLoadingDataPseudonymizer = (state) =>
  state.pseudonymizer.isLoadingDataPseudonymizer;
export const selectUserFromPseudoUser = (state) =>
  state.pseudonymizer.userFromPseudoUser;
export const selectPseudoUserFromUser = (state) =>
  state.pseudonymizer.pseudoUserFromUser;
export const selectVinFromPseudoVin = (state) =>
  state.pseudonymizer.vinFromPseudoVin;
export const selectPseudoVinFromVin = (state) =>
  state.pseudonymizer.pseudoVinFromVin;
// export const selectPresetEnvironmentPseudonymizer = (state) =>
//   state.pseudonymizer.presetEnvironmentPseudonymizer;
// export const selectPresetRegionPseudonymizer = (state) =>
//   state.pseudonymizer.presetRegionPseudonymizer;
export const selectResultContent = (state) => state.pseudonymizer.resultContent;
export const selectErrorDisplay = (state) => state.pseudonymizer.errorDisplay;
export const selectPresetAudiencePseudonymizer = (state) =>
  state.pseudonymizer.presetAudiencePseudonymizer;
export const selectLoadingDSV = (state) => state.pseudonymizer.loadingCSV;
export const selectPVINListForCSV = (state) =>
  state.pseudonymizer.pVINListForCSV;
export const selectOrderedCSVVinList = (state) =>
  state.pseudonymizer.orderedCSVVinList;
export const selectVinListForCSV = (state) => state.pseudonymizer.vinListForCSV;
export const selectPseudoVINListWithVINS = (state) =>
  state.pseudonymizer.pseudoVINListWithVINS;
export const selectPseudoVINFromVINList = (state) =>
  state.pseudonymizer.pseudoVINFromVINList;

// Actions

export const {
  setAudiencePseudonymizer,
  setEnvironmentPseudonymizer,
  setIsLoadingDataPseudonymizer,
  setPUserPseudonymizer,
  setRegionPseudonymizer,
  setVinPseudonymizer,
  setMbbUserIdPseudonymizer,
  setPVinPseudonymizer,
  setPseudoUserFromUser,
  setUserFromPseudoUser,
  setPseudoVINFromVIN,
  setVinFromPseudoVin,
  setResultContent,
  clearResultContent,
  setErrorDisplay,
  setLoadingCSV,
  setPVINListForCSV,
  setVinListForCSV,
  clearPseudoVinList,
  setOrderedCSVVinList,
  setPseudoVINListWithVINS,
  clearPseudoVINListWithVINS,
  setVinPseudonymizerManual,
  setPseudoVINFromVINList,
  clearPseudoVINFromVINList,
} = pseudonymizerSlice.actions;

//Getters

// Get pVIN from VIN - all requests at once

export const getpVINFromVIN = createAsyncThunk(
  "getpVINFromVIN",
  async ({ payload }, { dispatch }) => {
    dispatch(setIsLoadingDataPseudonymizer(true));
    try {
      const url = "GETPVINFROMVINLISTFROMCSV";
      const response = await postWithAuth(url, {}, payload);

      if (response && Array.isArray(response)) {
        dispatch(setPseudoVINFromVINList(response));
        response.forEach((item) => {
          const { Vin, PVin } = item;
          dispatch(setPseudoVINFromVIN(item));
          dispatch(setPVINListForCSV(PVin));
          dispatch(setPseudoVINListWithVINS({ vin: Vin, pseudoVIN: PVin }));
        });
        dispatch(setErrorDisplay(false));
      } else {
        console.log("Unexpected response format:", response);
        dispatch(setErrorDisplay(true));
      }
    } catch (error) {
      console.error("Error in getpVINFromVIN:", error);
      dispatch(setErrorDisplay(true));
    } finally {
      dispatch(setIsLoadingDataPseudonymizer(false));
    }
  }
);

export const getpVINFromVINManual = createAsyncThunk(
  "getpVINFromVIN",
  async (
    { environment, region, vin, pVin, mbbUserId, pUser, audience },
    { dispatch }
  ) => {
    dispatch(setIsLoadingDataPseudonymizer(true));
    dispatch(clearResultContent());

    try {
      const response = await postWithAuth(
        "GETPSEUDOVINFROMVIN",
        {},
        { environment, region, vin, pVin, mbbUserId, pUser, audience }
      );
      if (response && !response.error) {
        // Process the response data
        dispatch(setPseudoVINFromVIN(response));
        dispatch(
          setResultContent(
            response["ns2:pseudoVehicle"]["audience"]["pseudoVIN"]["#text"]
          )
        );
        console.log(response);
        dispatch(setErrorDisplay(false));
      } else if (response && response.error) {
        dispatch(setResultContent(`No pVIN found`));
        dispatch(setErrorDisplay(false));
        console.log("Error:", response.error.errorCode["#text"]);
        console.log("Description:", response.error.description["#text"]);
      } else {
        console.log("Houston, we have a problem");
        dispatch(setErrorDisplay(true));
      }
    } catch (error) {
      logError(error);
      errorHandler(error, dispatch);
      dispatch(setErrorDisplay(true));
    } finally {
      dispatch(setIsLoadingDataPseudonymizer(false));
    }
  }
);

// Get VIN from pVIN

export const getVINFrompVIN = createAsyncThunk(
  "getVINFrompVIN",
  async (
    { environment, region, vin, pVin, mbbUserId, pUser, audience },
    { dispatch }
  ) => {
    dispatch(setIsLoadingDataPseudonymizer(true));
    dispatch(clearResultContent());
    try {
      const response = await postWithAuth(
        "GETVINFROMPSEUDOVIN",
        {},
        { environment, region, vin, pVin, mbbUserId, pUser, audience }
      );
      if (response && !response.error) {
        dispatch(setVinFromPseudoVin(response));
        dispatch(
          setResultContent(
            response["ns2:vehicleVin"]["vin"]["#text"] !== undefined
              ? response["ns2:vehicleVin"]["vin"]["#text"]
              : `No pVIN found `
          )
        );
        dispatch(setErrorDisplay(false));
        console.log(response);
      } else if (response && response.error) {
        // Handle error response
        dispatch(setResultContent(`No VIN found `));
        dispatch(setErrorDisplay(false));
        console.log("Error:", response.error.errorCode["#text"]);
        console.log("Description:", response.error.description["#text"]);
      } else {
        console.log("Houston, we have a problem");
        dispatch(setErrorDisplay(true));
      }
    } catch (error) {
      logError(error);
      errorHandler(error, dispatch);
      dispatch(setErrorDisplay(true));
    } finally {
      dispatch(setIsLoadingDataPseudonymizer(false));
    }
  }
);

// Get user from pseudo user

export const getUserFromPseudoUser = createAsyncThunk(
  "getUserFromPseudoUser",
  async (
    { environment, region, vin, pVin, mbbUserId, pUser, audience },
    { dispatch }
  ) => {
    dispatch(setIsLoadingDataPseudonymizer(true));
    dispatch(clearResultContent());
    try {
      const response = await postWithAuth(
        "GETUSERFROMPSEUDOUSER",
        {},
        { environment, region, vin, pVin, mbbUserId, pUser, audience }
      );
      if (response && !response.error) {
        dispatch(setUserFromPseudoUser(response));
        dispatch(setResultContent(response["user"]["#text"]));
        dispatch(setErrorDisplay(false));
        console.log(response);
      } else if (response && response.error) {
        // Handle error response
        dispatch(setResultContent(`No user found`));
        dispatch(setErrorDisplay(false));
        console.log("Error:", response.error.errorCode["#text"]);
        console.log("Description:", response.error.description["#text"]);
      } else {
        console.log("Houston, we have a problem");
        dispatch(setErrorDisplay(true));
      }
    } catch (error) {
      logError(error);
      errorHandler(error, dispatch);
      dispatch(setErrorDisplay(true));
    } finally {
      dispatch(setIsLoadingDataPseudonymizer(false));
    }
  }
);

// Get pseudo user from user

export const getPseudoUserFromUser = createAsyncThunk(
  "getPseudoUserFromUser",
  async (
    { environment, region, vin, pVin, mbbUserId, pUser, audience },
    { dispatch }
  ) => {
    dispatch(setIsLoadingDataPseudonymizer(true));
    dispatch(clearResultContent());
    try {
      const response = await postWithAuth(
        "GETPSEUDOUSERFROMUSER",
        {},
        { environment, region, vin, pVin, mbbUserId, pUser, audience }
      );
      if (response && !response.error) {
        dispatch(setPseudoUserFromUser(response));
        dispatch(
          setResultContent(
            response["ns2:pseudoUser"]["audience"]["pseudoMbbUser"]["#text"]
          )
        );
        dispatch(setErrorDisplay(false));
        console.log(response);
      } else if (response && response.error) {
        // Handle error response

        dispatch(setResultContent(`No pseudo user found `));
        dispatch(setErrorDisplay(false));
        console.log("Error:", response.error.errorCode["#text"]);
        console.log("Description:", response.error.description["#text"]);
      } else {
        console.log("Houston, we have a problem");
        dispatch(setErrorDisplay(true));
      }
    } catch (error) {
      logError(error);
      errorHandler(error, dispatch);
      dispatch(setErrorDisplay(true));
    } finally {
      dispatch(setIsLoadingDataPseudonymizer(false));
    }
  }
);
export default pseudonymizerSlice.reducer;
