import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadingSpinner from "../../ui/Loading Spinner/LoadingSpinner";
import ProjectItem from "../../components/Projects List/Project Item/ProjectItem";
import {
  getProjects,
  selectAllProjects,
  selectFilterDevice,
  selectFilterName,
  selectFilterRelease,
  selectFilterSOP,
  selectFilterYear,
} from "../../pages/Homepage/projectSlice";
import classes from "./ProjectList.module.css";
import SearchBox from "../Search box/SearchBox";
import SearchBoxName from "../Search box/SearchBoxModel";
import SearchBoxSOP from "../Search box/SearchBoxSOP";
import SearchBoxYear from "../Search box/SearchBoxYear";
import SearchBoxRelease from "../Search box/SearchBoxRelease";
import SearchBoxDevice from "../Search box/SearchBoxDevice";

function ProjectsList({ loadProjectDetails }) {
  const dispatch = useDispatch();
  const [selectedReferenceId, setSelectedReferenceId] = useState(null);
  const filterName = useSelector(selectFilterName);
  const filterSOP = useSelector(selectFilterSOP);
  const filterYear = useSelector(selectFilterYear);
  const filterRelease = useSelector(selectFilterRelease);
  const filterDevice = useSelector(selectFilterDevice);
  const projects = useSelector(selectAllProjects);

  useLayoutEffect(() => {
    if (projects && projects.length > 0) {
      setSelectedReferenceId(projects[0].referenceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    dispatch(getProjects());
  }, [
    dispatch,
    filterName,
    filterSOP,
    filterYear,
    filterRelease,
    filterDevice,
  ]);

  return (
    <div className={classes.gridContainer} data-cy="gridContainer">
      <div className={classes.gridItemHeader} data-cy="gridItemHeader">
        <div className={classes.flexContainerHeader}>
          <div
            className={classes.flexItemHeader}
            data-cy="flexItemHeaderProject"
            style={{ maxWidth: "7.5%" }}
          >
            <div
              style={{
                opacity: "0",
                maxWidth: "10%",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                left: "30%",
                right: "30%",
                maxHeight: "3rem",
              }}
            >
              {" "}
              <SearchBox />{" "}
            </div>
          </div>
          <div
            className={classes.flexItemHeader}
            style={{ maxWidth: "13%", marginRight: "-0.5rem" }}
            data-cy="flexItemHeaderName"
          >
            <div style={{ marginLeft: "-1.75rem" }}>Name</div>
            <div
              style={{
                maxWidth: "5rem",
                maxHeight: "3rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SearchBoxName />
            </div>
          </div>
          <div
            className={classes.flexItemHeader}
            style={{ maxWidth: "30%" }}
            data-cy="flexItemHeaderCode"
          >
            <div style={{ marginLeft: "5rem" }}>SOP</div>
            <div
              style={{
                maxWidth: "5rem",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                left: "30%",
                right: "30%",
                maxHeight: "3rem",
              }}
            >
              <SearchBoxSOP />
            </div>
          </div>
          <div
            className={classes.flexItemHeader}
            data-cy="flexItemHeaderRelease"
            style={{ maxWidth: "10%" }}
          >
            <div style={{ marginLeft: "4rem" }}>Rel.</div>
            <div
              style={{
                maxWidth: "5rem",
                display: "flex",
                flexDirection: "column",
                maxHeight: "3rem",
              }}
            >
              <SearchBoxRelease />
            </div>
          </div>
          <div
            className={classes.flexItemHeader}
            style={{ maxWidth: "10%" }}
            data-cy="flexItemHeaderYear"
          >
            <div style={{ marginLeft: "2rem" }}>Year</div>
            <div
              style={{
                maxWidth: "5rem",
                display: "flex",
                flexDirection: "column",
                maxHeight: "3rem",
              }}
            >
              <SearchBoxYear />
            </div>
          </div>

          <div
            className={classes.flexItemHeader}
            data-cy="flexItemHeaderRelease"
            style={{ maxWidth: "10%" }}
          >
            <div>Device</div>
            <div
              style={{
                maxWidth: "5rem",
                display: "flex",
                flexDirection: "column",
                maxHeight: "3rem",
              }}
            >
              <SearchBoxDevice />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.gridItemHeader}>
        <div
          style={{
            width: "100%",
            height: "1.5px",
            backgroundColor: "var(--dark-gray)",
            opacity: "0.5",
            marginBottom: "0.5rem",
          }}
        />
      </div>

      <div className={classes.gridItemRow} data-cy="gridItemRow">
        <div className={classes.flexContainerRow} data-cy="flexContainerRow">
          <ul style={{ padding: "0", margin: "0" }} data-cy="project-list">
            {projects ? (
              projects
                .filter((project) => {
                  const filteredProjects =
                    project.masterdata.modelRange.includes(
                      filterName.toUpperCase()
                    );
                  return filteredProjects;
                })
                .filter((project) => {
                  const filteredProjects = project.sopName
                    .toLowerCase()
                    .includes(filterSOP);
                  return filteredProjects;
                })
                .filter((project) => {
                  const filteredProjects =
                    project.masterdata.modelYear.includes(filterYear);
                  return filteredProjects;
                })
                .filter((project) => {
                  const sopSegments = project.sopName.split("_");
                  const sopDevice = sopSegments[2].toLowerCase();
                  const filteredProjects = sopDevice.includes(
                    filterDevice.toUpperCase()
                  );
                  return filteredProjects;
                })
                .filter((project) => {
                  const filteredProjects =
                    project.releaseVersion.includes(filterRelease);
                  return filteredProjects;
                })
                .map((item, i) => (
                  <li
                    key={i}
                    style={{ listStyle: "none", margin: "0", padding: "0" }}
                    data-cy="project-item"
                  >
                    <ProjectItem
                      key={i}
                      selected={selectedReferenceId === item.referenceId}
                      // selected={
                      //   selectedReferenceId === item.referenceId
                      //   // &&
                      //   // selectedSOP === item.sop
                      // }
                      setSelected={() => {
                        // dispatch(setSOP(sop));
                        // setSelectedSOP(item.sop);
                        setSelectedReferenceId(item.referenceId);
                      }}
                      loadProjectDetails={loadProjectDetails}
                      referenceId={item.referenceId}
                      masterdata={{
                        modelYear: item.masterdata.modelYear,
                        modelRange: item.masterdata.modelRange,
                        modelCode: item.masterdata.modelCode,
                      }}
                      releaseVersion={item.releaseVersion}
                      sop={item.sopName}
                    />
                  </li>
                ))
            ) : (
              <LoadingSpinner />
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ProjectsList;
