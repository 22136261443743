import { createSlice } from "@reduxjs/toolkit";
import { postWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";

const initialState = {
  vehicleClaims: {},
  isLoadingDataVehicleClaims: false,
};

// Slice
const vehicleClaimsSlice = createSlice({
  name: "vehicleClaims",
  initialState,
  reducers: {
    setVehicleClaims: (state, action) => {
      state.vehicleClaims = action.payload;
    },
    setIsLoadingDataVehicleClaims: (state, action) => {
      state.isLoadingDataVehicleClaims = action.payload;
    },
  },
});

// Selectors

export const selectVehicleClaims = (state) => state.vehicleClaims.vehicleClaims;
export const selectIsLoadingDataVehicleClaims = (state) =>
  state.vehicleClaims.isLoadingDataVehicleClaims;

// Actions

export const { setVehicleClaims, setIsLoadingDataVehicleClaims } =
  vehicleClaimsSlice.actions;

//Getter

export const getVehicleClaims = (environment, region, vin) => (dispatch) => {
  dispatch(setIsLoadingDataVehicleClaims(true));
  postWithAuth("GETVEHICLECLAIMS", {}, { environment, region, vin })
    .then((response) => {
      if (response !== null) {
        dispatch(setVehicleClaims(response));
      } else {
        console.log(response);
      }
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
    })
    .finally(() => {
      dispatch(setIsLoadingDataVehicleClaims(false));
    });
};

export default vehicleClaimsSlice.reducer;
