import { createSlice } from "@reduxjs/toolkit";
import { postWithAuth } from "#utils/api.js";
import { logError } from "#utils/utils.js";
import { errorHandler } from "#components/Error Handler/errorHandler.js";

const initialState = {
  digitalTwinResponse: {},
  csoTopicCount: {},
  isLoadingDataDigitalTwin: false,
  isLoadingDataCsoTopicCount: false,
};

// Slice
const digitalTwin = createSlice({
  name: "digitalTwin",
  initialState,
  reducers: {
    setDigitalTwin: (state, action) => {
      state.digitalTwinResponse = action.payload;
    },
    setIsLoadingDataDigitalTwin: (state, action) => {
      state.isLoadingDataDigitalTwin = action.payload;
    },
    setCsoTopicCount: (state, action) => {
      state.csoTopicCount = action.payload;
    },
    setIsLoadingDataCsoTopicCount: (state, action) => {
      state.isLoadingDataCsoTopicCount = action.payload;
    },
  },
});

// Selectors

export const selectDigitalTwin = (state) =>
  state.digitalTwin.digitalTwinResponse;
export const selectIsLoadingDataDigitalTwin = (state) =>
  state.digitalTwin.isLoadingDataDigitalTwin;
export const selectCsoTopicCount = (state) => state.digitalTwin.csoTopicCount;
export const selectIsLoadingDataCsoTopicCount = (state) =>
  state.digitalTwin.isLoadingDataCsoTopicCount;

// Actions

export const {
  setIsLoadingDataDigitalTwin,
  setDigitalTwin,
  setCsoTopicCount,
  setIsLoadingDataCsoTopicCount,
} = digitalTwin.actions;

//Getter

export const getDigitalTwin = (environment, region, vin) => (dispatch) => {
  dispatch(setIsLoadingDataDigitalTwin(true));
  postWithAuth("GETDIGITALTWIN", {}, { environment, region, vin })
    .then((response) => {
      if (response !== null) {
        dispatch(setDigitalTwin(response));
      } else {
        console.log(response);
      }
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
    })
    .finally(() => {
      dispatch(setIsLoadingDataDigitalTwin(false));
    });
};

export const getCsoTopicCount = (environment, region, vin) => (dispatch) => {
  dispatch(setIsLoadingDataCsoTopicCount(true));
  postWithAuth("GETCSOTOPICCOUNT", {}, { environment, region, vin })
    .then((response) => {
      if (response !== null) {
        dispatch(setCsoTopicCount(response));
      } else {
        console.log(response);
      }
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
    })
    .finally(() => {
      dispatch(setIsLoadingDataCsoTopicCount(false));
    });
};

export default digitalTwin.reducer;
