import React from "react";
import logo from "../ui/Assets/Icons/logo.svg";
import classes from "./auth.module.css";

export default function AuthError({ errorCode, errorMessage }) {
  return (
    <div className={classes.gridContainer}>
      <div className={classes.flexContainerHeader}>
        <div className={classes.logo}>
          <img src={logo} alt="" style={{ width: "150px" }} />
        </div>
      </div>
      <div
        className={classes.flexContainerRow}
        style={{ color: "var(--primary" }}
      >
        {" "}
        Authentication failed
      </div>
      <div className={classes.gridItemRow}>
        Please try again and if error persists, contact support team
      </div>
      <div className={classes.gridItemRow}>{errorMessage}</div>
    </div>
  );
}
