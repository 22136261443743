import { getVehicleStagesAndEnvironments } from "./vdsCheckSlice";

// With Redux Middleware

export const triggerVDS = (dispatch, environment, region, vin) => {
  dispatch(
    getVehicleStagesAndEnvironments({
      environment,
      region,
      vin: vin.toUpperCase(),
    })
  );
};
