import React, { useEffect, useState } from "react";
import "react-international-phone/style.css";
import classes from "./certificateBundles.module.css";
import HorizontalDivider from "../../components/Horizontal Divider/HorizontalDivider";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getCertificateBundles,
  selectEnvCertificateBundles,
  selectIsLoadingDataCertificateBundles,
  selectPresetEnvCertificateBundles,
  selectPresetRegCertificateBundles,
  selectRegCertificateBundles,
  selectRegisterAndPairCertificateBundles,
  selectVinCertificateBundles,
  setCertificateBundles,
  setEnvCertificateBundles,
  setRegCertificateBundles,
  setRegisterAndPairCertificateBundles,
  setVinCertificateBundles,
} from "./certificateBundlesSlice";
import LoadingSpinner from "#ui/Loading Spinner/LoadingSpinner.jsx";

function CertificateBundles() {
  const [registerAndPair, setRegisterAndPair] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const dispatch = useDispatch();

  const vinCertificateBundles = useSelector(selectVinCertificateBundles);
  const regCertificateBundles = useSelector(selectRegCertificateBundles);
  const envCertificateBundles = useSelector(selectEnvCertificateBundles);
  const presetRegCertificateBundles = useSelector(
    selectPresetRegCertificateBundles
  );
  const presetEnvCertificateBundles = useSelector(
    selectPresetEnvCertificateBundles
  );
  const registerAndPairCertificateBundles = useSelector(
    selectRegisterAndPairCertificateBundles
  );
  const isLoadingDataCertificateBundles = useSelector(
    selectIsLoadingDataCertificateBundles
  );

  const vinHandlerCertificateBundles = (userInsertedVIN) => {
    dispatch(setVinCertificateBundles(userInsertedVIN.toUpperCase()));
    if (
      userInsertedVIN.toUpperCase().startsWith("BP") &&
      userInsertedVIN.toUpperCase().length === 17
    ) {
      setValidationError(false);
    } else {
      setValidationError(true);
    }
  };
  const regionHandlerCertificateBundles = (userSelectedRegion) => {
    dispatch(setRegCertificateBundles(userSelectedRegion));
  };

  const environmentHandlerCertificateBundles = (userSelectedEnvironment) => {
    dispatch(setEnvCertificateBundles(userSelectedEnvironment));
  };

  const getCertificateBundlesHandler = (region, vin, registerAndPair) => {
    if (vin.toUpperCase().startsWith("BP")) {
      setValidationError(false);
      dispatch(
        getCertificateBundles({ region, vin, registration: registerAndPair })
      );
    } else {
      setValidationError(true);
    }
  };

  const handleCheckboxChange = (event) => {
    setRegisterAndPair(event.target.checked);
  };

  return (
    <div className={classes.certificateBundlesContainer}>
      <div className={classes.certificateBundlesSection}>
        <div className={classes.certificateBundlesSectionTitle}>
          VIN Certificate Bundles{" "}
        </div>
        <HorizontalDivider />
        <div className={classes.certificateBundlesSectionActionSection}>
          <div className={classes.regionSelectionRow}>
            <div className={classes.regionSection}>
              <FormControl>
                <FormLabel id="reg-radio-buttons-group-label">Region</FormLabel>
                <RadioGroup
                  aria-labelledby="reg-radio-buttons-group-label"
                  value={
                    regCertificateBundles !== undefined &&
                    regCertificateBundles !== ""
                      ? regCertificateBundles
                      : presetRegCertificateBundles
                  }
                  onChange={(event) => {
                    regionHandlerCertificateBundles(event.target.value);
                  }}
                  name="radio-buttons-group"
                  row
                >
                  <FormControlLabel
                    value="ece"
                    control={<Radio size="small" />}
                    label="ECE"
                  />
                  <FormControlLabel
                    value="nar"
                    control={<Radio size="small" />}
                    // disabled
                    label="NAR"
                  />
                  <FormControlLabel
                    value="cn"
                    control={<Radio size="small" />}
                    // disabled
                    label="CN"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className={classes.environmentSection}>
              <FormControl>
                <FormLabel id="env-radio-buttons-group-label">
                  Environment
                </FormLabel>
                <RadioGroup
                  aria-labelledby="env-radio-buttons-group-label"
                  value={
                    envCertificateBundles !== undefined &&
                    envCertificateBundles !== ""
                      ? envCertificateBundles
                      : presetEnvCertificateBundles
                  }
                  onChange={(event) => {
                    environmentHandlerCertificateBundles(event.target.value);
                  }}
                  name="radio-buttons-group"
                  row
                >
                  <FormControlLabel
                    value="demo"
                    disabled
                    control={<Radio size="small" />}
                    label="Demo"
                  />
                  <FormControlLabel
                    value="approval"
                    control={<Radio size="small" />}
                    label="Approval"
                  />
                  <FormControlLabel
                    value="live"
                    control={<Radio size="small" />}
                    label="Live"
                    disabled
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div
            className={classes.flexContainerRowSubtitle}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            {" "}
            Insert VIN:{" "}
          </div>
          <div className={classes.flexContainerRowSearch}>
            {/*  */}
            <input
              id="searchDetails"
              autoComplete="off"
              required
              data-cy="searchInput"
              value={vinCertificateBundles || ""}
              onChange={(event) => {
                vinHandlerCertificateBundles(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  getCertificateBundlesHandler(event.target.value);
                }
              }}
              style={{ userSelect: "text", cursor: "auto" }}
              readOnly={false}
            ></input>

            <label
              htmlFor="searchDetails"
              className={classes.searchLabel}
              data-cy="searchLabel"
              style={{ userSelect: "text", cursor: "auto" }}
            ></label>
          </div>
        </div>
        {/* Validation box */}
        {validationError ? (
          <div className={classes.validationTextBox}>
            Entered VIN should have 17 characters and start with "BP" ( only
            virtual bundles can be created (so only BP vins and not WP) )
          </div>
        ) : (
          <div className={classes.validationTextBox}></div>
        )}
        {/* End of validation box */}
        {/* Here the checkbox */}
        <div className={classes.certificateBundlesCheckboxSection}>
          <FormControl>
            <FormControlLabel
              //   value="true"
              control={
                <Checkbox size="small" onChange={handleCheckboxChange} />
              }
              label="Register & Pair VIN ?"
              xs={{ fontFamily: "var(--regular)", fontSize: "3rem" }}
            ></FormControlLabel>
          </FormControl>
        </div>
        {isLoadingDataCertificateBundles ? (
          <div className={classes.actionBtnCertificateBundlesLoadingSpinner}>
            {" "}
            <LoadingSpinner />
          </div>
        ) : (
          <div
            className={
              vinCertificateBundles.toUpperCase().startsWith("BP") &&
              vinCertificateBundles.toUpperCase().length === 17
                ? classes.actionBtnCertificateBundles
                : classes.actionBtnCertificateBundlesUnavailable
            }
            onClick={() => {
              if (
                vinCertificateBundles.toUpperCase().startsWith("BP") &&
                vinCertificateBundles.toUpperCase().length === 17
              ) {
                dispatch(setCertificateBundles({}));
                getCertificateBundlesHandler(
                  regCertificateBundles !== undefined &&
                    regCertificateBundles !== ""
                    ? regCertificateBundles
                    : presetRegCertificateBundles,
                  vinCertificateBundles,
                  registerAndPair
                );
              } else {
                console.log("invalid vin");
              }
            }}
          >
            Get Certificate Bundles
          </div>
        )}
      </div>
    </div>
  );
}

export default CertificateBundles;
