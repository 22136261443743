import { createRoot } from "react-dom/client";
import React from "react";
import App from "./App.jsx";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Provider } from "react-redux";
import { store } from "./utils/store.js";
import DummyPageToGetHref from "./pages/dummyPageToGetHref/dummyPageToGetHref.jsx";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <DummyPageToGetHref />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
