import { createSlice } from "@reduxjs/toolkit";
import { postWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";

const initialState = {
  connectionStatusLive: {},
  connectionPingTimestampLive: "",
  pingDataLive: [],
  pingTimeStampLive: [],
  noDeviceFound: false,
  accessForbiddenToLiveStatus: false,
  isLoadingDataConnectionStatusLive: [],
};

// Slice
const connectionStatusLiveSlice = createSlice({
  name: "connectionStatusLive",
  initialState,
  reducers: {
    setConnectionStatusLive: (state, action) => {
      state.connectionStatusLive = action.payload;
    },
    setPingDataLive: (state, action) => {
      state.pingDataLive = [...state.pingDataLive, action.payload];
    },
    setPingTimestampLive: (state, action) => {
      state.pingTimeStampLive = [...state.pingTimeStampLive, action.payload];
    },
    setConnectionPingTimestampLive: (state, action) => {
      state.connectionPingTimestampLive = action.payload;
    },
    clearPingDataLive: (state) => {
      state.pingDataLive = [];
    },
    clearPingTimeStampLive: (state) => {
      state.pingTimeStampLive = [];
    },
    setNoDeviceFound: (state, action) => {
      state.noDeviceFound = action.payload;
    },
    setAccessForbiddenToLiveStatus: (state, action) => {
      state.accessForbiddenToLiveStatus = action.payload;
    },
    setIsLoadingDataConnectionStatusLive: (state, action) => {
      state.isLoadingDataConnectionStatusLive = action.payload;
    },
  },
});

// Selectors

export const selectConnectionStatusLive = (state) =>
  state.connectionStatusLive.connectionStatusLive;
export const selectPingDataLive = (state) =>
  state.connectionStatusLive.pingDataLive;
export const selectPingTimestampLive = (state) =>
  state.connectionStatusLive.pingTimeStampLive;
export const selectConnectionPingTimestampLive = (state) =>
  state.connectionStatusLive.connectionPingTimestampLive;
export const selectAccessForbiddenToLiveStatus = (state) =>
  state.connectionStatusLive.accessForbiddenToLiveStatus;
export const selectNoDeviceFound = (state) =>
  state.connectionStatusLive.noDeviceFound;
export const selectIsLoadingDataConnectionStatusLive = (state) =>
  state.connectionStatusLive.isLoadingDataConnectionStatusLive;
// Actions

export const {
  setAccessForbiddenToLiveStatus,
  setConnectionPingTimestampLive,
  setPingDataLive,
  setPingTimestampLive,
  setConnectionStatusLive,
  clearPingDataLive,
  setNoDeviceFound,
  clearPingTimeStampLive,
  setIsLoadingDataConnectionStatusLive,
} = connectionStatusLiveSlice.actions;

//Getter

export const getConnectionStatusLive =
  (environment, region, vin) => (dispatch) => {
    dispatch(setIsLoadingDataConnectionStatusLive(true));
    postWithAuth("GETCONNECTIONSTATUSLIVE", {}, { environment, region, vin })
      .then((response) => {
        if (response !== null) {
          const endTime = new Date();
          const hours = String(endTime.getHours()).padStart(2, "0");
          const minutes = String(endTime.getMinutes()).padStart(2, "0");
          const seconds = String(endTime.getSeconds()).padStart(2, "0");
          let endTimestamp = `${hours}:${minutes}:${seconds}`;
          dispatch(setConnectionStatusLive(response));
          dispatch(setConnectionPingTimestampLive(endTimestamp));
          dispatch(setPingDataLive(response));
          dispatch(setPingTimestampLive(endTimestamp));

          if (response.Devices[0] === undefined) {
            dispatch(setNoDeviceFound(true));
          }
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        logError(error);
        errorHandler(error, dispatch);
        if (error.code === "ERR_BAD_REQUEST") {
          dispatch(setAccessForbiddenToLiveStatus(true));
        }
      })
      .finally(() => {
        dispatch(setIsLoadingDataConnectionStatusLive(false));
      });
  };

// // With timeouts in it:
// export const getConnectionStatusLive =
//   (environment, region, vin) => (dispatch) => {
//     dispatch(setIsLoadingDataConnectionStatusLive(true));

//     // Helper function to send request and update state
//     const sendRequestAndUpdateState = () => {
//       postWithAuth("GETCONNECTIONSTATUSLIVE", {}, { environment, region, vin })
//         .then((response) => {
//           if (response !== null) {
//             const endTime = new Date();
//             const hours = String(endTime.getHours()).padStart(2, "0");
//             const minutes = String(endTime.getMinutes()).padStart(2, "0");
//             const seconds = String(endTime.getSeconds()).padStart(2, "0");
//             let endTimestamp = `${hours}:${minutes}:${seconds}`;
//             dispatch(setConnectionStatusLive(response));
//             dispatch(setConnectionPingTimestampLive(endTimestamp));
//             dispatch(setPingDataLive(response));
//             dispatch(setPingTimestampLive(endTimestamp));

//             if (response.Devices[0] === undefined) {
//               dispatch(setNoDeviceFound(true));
//             }
//           } else {
//             console.log(response);
//           }
//         })
//         .catch((error) => {
//           logError(error);
//           errorHandler(error, dispatch);
//           if (error.code === "ERR_BAD_REQUEST") {
//             dispatch(setAccessForbiddenToLiveStatus(true));
//           }
//         })
//         .finally(() => {
//           dispatch(setIsLoadingDataConnectionStatusLive(false));
//         });
//     };

//     // Send the request three times in sequence
//     sendRequestAndUpdateState(); // First call
//     setTimeout(() => sendRequestAndUpdateState(), 15000); // Second call after 1 second
//     setTimeout(() => sendRequestAndUpdateState(), 30000); // Third call after 2 seconds
//   };

export default connectionStatusLiveSlice.reducer;
