import React, { useEffect, useLayoutEffect } from "react";
import classes from "./HomePage.module.css";
import ProjectsList from "../../components/Projects List/ProjectsList.jsx";
import ProjectDetails from "../../components/Project Details/ProjectDetails.jsx";
import FullButton from "../../ui/Buttons/Full Button/FullButton";
import HorizontalDivider from "../../components/Horizontal Divider/HorizontalDivider";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllProjects,
  setIsFirstVisit,
  selectIsFirstVisit,
  getProjects,
  selectIsSelected,
  selectSelectedModelId,
  setIsSelected,
  setSelectedModel,
  setSelectedModelId,
} from "../Homepage/projectSlice";
import { getTests } from "../TestData Selection/testsSlice";
import LastResultBtn from "../../components/lastResultsBtn/LastResultBtn";
import { selectLastResultAvailable } from "../Results Report Page/resultsSlice";

function HomePage() {
  const dispatch = useDispatch();
  const isFirstVisit = useSelector(selectIsFirstVisit);
  const id = useSelector(selectSelectedModelId);
  const isSelected = useSelector(selectIsSelected);
  const projects = useSelector(selectAllProjects);
  const lastResultsAvailable = useSelector(selectLastResultAvailable);

  useLayoutEffect(() => {
    const hasBeenVisitedBefore = sessionStorage.getItem("hasBeenVisited");
    if (!hasBeenVisitedBefore) {
      sessionStorage.setItem("hasBeenVisited", "true");
      dispatch(setIsFirstVisit(true));
      window.location.reload();
      console.log("if it hasn't been visited before" + isFirstVisit);
      dispatch(getProjects());
      dispatch(getTests());
    } else {
      console.log(window.location.href);
      console.log("if it hasn't been visited before" + isFirstVisit);
      dispatch(getProjects());
      dispatch(getTests());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (projects && projects.length > 0) {
      dispatch(setSelectedModel(projects[0].referenceId));
      dispatch(setSelectedModelId(projects[0].referenceId));
      dispatch(setIsSelected(true));
    }
    // dispatch(getProjectsWithSOP(projects, projectSOP));
    // if (projects.length > 0) {
    //   const newProjectsWithSOP = getProjectsWithSOP(projects, projectSOP);
    //   setProjectsWithSOP(newProjectsWithSOP);
    //   dispatch(setAllProjectsWithSOP(projectsWithSOP));
    // }
  }, [dispatch, projects]);

  function loadingHandler(referenceId) {
    dispatch(setSelectedModelId(referenceId));
    dispatch(setSelectedModel(referenceId));
    dispatch(setIsSelected(true));
  }

  return (
    <div className={classes.gridContainer}>
      <div className={classes.flexContainerHeader}>
        <div className={classes.flexItemHeader}></div>
        <div className={classes.flexContainerRow}> </div>
        <div className={classes.flexContainerRow}> </div>
        <div className={classes.flexContainerRow}> </div>
        <div className={classes.flexContainerRowTitle}>
          Select Vehicle-Device
        </div>{" "}
        <div
          className={classes.flexContainerRow}
          style={{ justifyContent: "flex-end", marginRight: "10rem" }}
        >
          {" "}
          {lastResultsAvailable ? (
            <LastResultBtn />
          ) : (
            <div style={{ height: "1.5rem", width: "7.5rem" }} />
          )}
        </div>
        <HorizontalDivider />
        <div className={classes.flexContainerRow}> </div>
        <div className={classes.gridItemRow}></div>
      </div>
      <div className={classes.gridItemRow}>
        <div
          style={{
            width: "50vw",
            marginLeft: "5%",
            height: "47.5vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <ProjectsList loadProjectDetails={loadingHandler} />
          {/* <ProjectListReactPrime /> */}
        </div>
        <div
          style={{
            width: "35vw",
            height: "47.5vh",
            overflowY: "scroll",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <ProjectDetails />
        </div>
      </div>
      <div className={classes.gridItemRow}></div>
      <div className={classes.gridItemRow}>
        <div className={classes.actionSection}>
          <FullButton
            disabledConditions={!isSelected}
            buttonText={"Proceed with selection"}
            destination={`/data-and-info/${id}`}
          />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
