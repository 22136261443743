import axios from "axios";
import { setOpen, setSnackBar } from "../Snackbar/snackbarSlice";

export const errorHandler = (error, dispatch) => {
  if (isValidationError(error)) {
    dispatch(setOpen(true));
    dispatch(
      setSnackBar({
        errorCode: " " + error.response.status,
        errorMessage:
          "A BAD REQUEST was sent to the server. If issue persists, please contact helpdesk. ",
      })
    );
  } else if (isInternalError(error)) {
    dispatch(setOpen(true));
    dispatch(
      setSnackBar({
        errorCode: " " + error.response.status,
        errorMessage:
          "The server failed to complete the request because server encountered an error. If issue persists, please contact helpdesk.",
      })
    );
  } else if (isNetworkError(error)) {
    dispatch(setOpen(true));
    dispatch(
      setSnackBar({
        errorCode: " " + error.response.status,
        errorMessage:
          "There seems to be a network or connectivity issue. If issue persists, please contact helpdesk.  ",
      })
    );
    // } else if (isUnexpectedError(error)) {
    //   dispatch(setOpen(true))
    //   dispatch(
    //     setSnackBar({
    //         errorCode: " " + error.response.status,
    //         errorMessage: "An unexpected error occured. If issue persists, please contact helpdesk. " ,
    //     })
    //   );
  }
};

function axiosResponseIsValidationResponse(response) {
  return response.status === 400;
}

function axiosResponseIsInternalResponse(response) {
  return response.status === 500;
}

export function isValidationError(error) {
  return Boolean(
    axios.isAxiosError(error) &&
      error.response &&
      axiosResponseIsValidationResponse(error.response)
  );
}

export function isNetworkError(error) {
  return Boolean(
    axios.isAxiosError(error) && error.message === "Network Error"
  );
}

export function isInternalError(error) {
  return Boolean(
    axios.isAxiosError(error) &&
      error.response &&
      axiosResponseIsInternalResponse(error.response)
  );
}

export function isUnexpectedError(error) {
  return Boolean(axios.isAxiosError(error));
}
