import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import classes from "./odpUserList.module.css";
import { selectEmptyFields } from "../backendInfoSlice";
import LoadingSpinner from "../../../ui/Loading Spinner/LoadingSpinner";
import { Typography } from "@mui/material";
import {
  selectIsLoadingDataOdpUserList,
  selectNoPrimaryUserFound,
  selectOdpUserList,
} from "./odpUserListSlice";
import { selectIsLoadingDataPrimaryUserClaims } from "../primaryUserClaimsSlice";
import { selectVehicleUsers } from "../vehicleUsersSlice";

function OdpUserList() {
  const odpVehicleUsers = useSelector(selectOdpUserList);
  const emptyFields = useSelector(selectEmptyFields);
  const noPrimaryUserFound = useSelector(selectNoPrimaryUserFound);
  const isLoadingDataOdpUserList = useSelector(selectIsLoadingDataOdpUserList);
  const isLoadingDataForPrimaryUser = useSelector(
    selectIsLoadingDataPrimaryUserClaims
  );
  const vehicleUsers = useSelector(selectVehicleUsers);

  useEffect(() => {
    vehicleUsers
      ? console.log(vehicleUsers.Data)
      : console.log("no users found");
  }, [vehicleUsers]);

  if (isLoadingDataOdpUserList || isLoadingDataForPrimaryUser) {
    return <LoadingSpinner />;
  } else if (
    !odpVehicleUsers ||
    !odpVehicleUsers.vehicleUsers ||
    !odpVehicleUsers.vehicleUsers.user ||
    odpVehicleUsers.vehicleUsers.user.idPID === ""
  ) {
    return "No data available";
  } else if (
    (!odpVehicleUsers.vehicleUsers.user ||
      odpVehicleUsers.vehicleUsers.user.length === 0) &&
    !emptyFields &&
    noPrimaryUserFound
  ) {
    return (
      <Typography
        style={{
          fontFamily: "var(--regular)",
          color: "var(--primary)",
          fontSize: "1.25rem",
        }}
      >
        "Not available due to missing primary user for vehicle"
      </Typography>
    );
  } else {
    // Check if odpVehicleUsers.vehicleUsers.user is an array before attempting to sort
    if (!Array.isArray(odpVehicleUsers.vehicleUsers.user)) {
      // Handle the case where odpVehicleUsers.vehicleUsers.user is not an array so the page does not crash
      return "Data is not available or not in the correct format";
    }
  }
  const sortedUsers = [...odpVehicleUsers.vehicleUsers.user].sort((a, b) => {
    if (a.role === "PRIMARY_USER" && b.role !== "PRIMARY_USER") {
      return -1;
    } else if (a.role !== "PRIMARY_USER" && b.role === "PRIMARY_USER") {
      return 1;
    }
    return 0;
  });
  return (
    <>
      <div className={classes.flexContainerRowWideTableTitle}>
        ODP Vehicle Users
      </div>
      <div className={classes.flexContainerRowWideTable}>
        <div className={classes.wideTableDiv}>
          {/* Header section */}
          <div className={classes.wideTableHeader}>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 1.5, userSelect: "text", cursor: "auto" }}
            >
              Role
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 3, userSelect: "text", cursor: "auto" }}
            >
              MBB ID
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 1.75, userSelect: "text", cursor: "auto" }}
            >
              SSO ID
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 3, userSelect: "text", cursor: "auto" }}
            >
              VWAC ID
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
            >
              Sec. Lv.
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 2.5, userSelect: "text", cursor: "auto" }}
            >
              Status
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 3, userSelect: "text", cursor: "auto" }}
            >
              Ownership Verification
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 1, userSelect: "text", cursor: "auto" }}
            >
              Country
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 1, userSelect: "text", cursor: "auto" }}
            >
              IDP
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
            >
              Org. Lv. 1
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
            >
              Org. Lv. 2
            </div>
            <div
              className={classes.wideTableHeaderTitle}
              style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
            >
              Org. Lv. 3
            </div>
          </div>
          {/* BODY SECTION */}
          <div className={classes.wideTableBody}>
            {sortedUsers.map((item, i) => (
              <div className={classes.wideTableRow} key={i}>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 1.5, userSelect: "text", cursor: "auto" }}
                >
                  {item.role}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 3, userSelect: "text", cursor: "auto" }}
                >
                  {item.mbbUserId}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 1.75, userSelect: "text", cursor: "auto" }}
                >
                  {item.ssoId}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 3, userSelect: "text", cursor: "auto" }}
                >
                  {/* {item.VwacUserId} */}-
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
                >
                  {item.securityLevel}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 2.5, userSelect: "text", cursor: "auto" }}
                >
                  {item.status}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 3, userSelect: "text", cursor: "auto" }}
                >
                  {vehicleUsers.Data !== undefined
                    ? vehicleUsers.Data &&
                      vehicleUsers.Data.find(
                        (user) => user.MbbUserId === item.mbbUserId
                      )?.VerificationStatus
                    : "-"}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 1, userSelect: "text", cursor: "auto" }}
                >
                  {vehicleUsers.Data !== undefined
                    ? vehicleUsers.Data &&
                      vehicleUsers.Data.find(
                        (user) => user.MbbUserId === item.mbbUserId
                      )?.HomeCountry
                    : "-"}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 1, userSelect: "text", cursor: "auto" }}
                >
                  {item.idPID}
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
                >
                  {/* {vehicleUuID.VehicleOrgLevel1} */}na
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
                >
                  {/* {vehicleUuID.VehicleOrgLevel2} */}na
                </div>
                <div
                  className={classes.wideTableRowContents}
                  style={{ flex: 0.6, userSelect: "text", cursor: "auto" }}
                >
                  {/* {vehicleUuID.VehicleOrgLevel3} */}na
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default OdpUserList;
