import { errorHandler } from "#components/Error Handler/errorHandler.js";
import { getXMLWithAuth } from "#utils/api.js";
import { logError } from "#utils/utils.js";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingDataAdminToolTraceTransformer: false,
};

// Slice
const adminToolTraceTransformerSlice = createSlice({
  name: "adminToolTraceTransformer",
  initialState,
  reducers: {
    setIsLoadingDataAdminToolTraceTransformer: (state, action) => {
      state.isLoadingDataAdminToolTraceTransformer = action.payload;
    },
  },
});

// Selectors

export const selectIsLoadingDataAdminToolTraceTransformer = (state) =>
  state.ccpGenerator.isLoadingDataAdminToolTraceTransformer;

// Actions

export const { setIsLoadingDataAdminToolTraceTransformer } =
  adminToolTraceTransformerSlice.actions;

//Getter

export const generateAdminToolTraceTransformer = (csvFile) => (dispatch) => {
  dispatch(setIsLoadingDataAdminToolTraceTransformer(true));
  const formData = new FormData();
  formData.append("file", csvFile);

  getXMLWithAuth("GETADMINTOOLTRACETRANSFORMER", {}, formData, {
    "Content-Type": "multipart/form-data",
  })
    .then((response) => {
      // Create a Blob object from the response data
      const blob = new Blob([response], { type: "application/json" });

      // Create a temporary anchor element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "AdminToolTranceTransformer.json");
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      URL.revokeObjectURL(url);
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
    })
    .finally(() => {
      dispatch(setIsLoadingDataAdminToolTraceTransformer(false));
    });
};

export default adminToolTraceTransformerSlice.reducer;
