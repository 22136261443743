import { createSlice } from "@reduxjs/toolkit";
import { postWithAuth } from "../../../utils/api";
import { logError } from "../../../utils/utils";
import { errorHandler } from "../../../components/Error Handler/errorHandler";

const initialState = {
  odpUserList: {
    vehicleUsers: {
      vin: "",
      user: [
        {
          idPID: "",
          pushToVehicle: false,
          deletedInBackend: false,
          isInVehicle: true,
          mbbUserId: "",
          ssoId: "",
          role: "",
          securityLevel: "",
          status: "",
          spinStatus: "",
          spinLockedWaitingTime: "",
        },
      ],
    },
  },
  isLoadingDataOdpUserList: false,
  noPrimaryUserFound: false,
};

// Slice
const odpUserListSlice = createSlice({
  name: "odpUserList",
  initialState,
  reducers: {
    setOdpUserList: (state, action) => {
      state.odpUserList = action.payload;
    },

    setIsLoadingDataOdpUserList: (state, action) => {
      state.isLoadingDataOdpUserList = action.payload;
    },
    setNoPrimaryUserFound: (state, action) => {
      state.noPrimaryUserFound = action.payload;
    },
  },
});

// Selectors

export const selectOdpUserList = (state) => state.odpUserList.odpUserList;

export const selectIsLoadingDataOdpUserList = (state) =>
  state.odpUserList.isLoadingDataOdpUserList;

export const selectNoPrimaryUserFound = (state) =>
  state.odpUserList.noPrimaryUserFound;
// Actions

export const {
  setOdpUserList,
  setIsLoadingDataOdpUserList,
  setNoPrimaryUserFound,
} = odpUserListSlice.actions;

//Getter

export const getOdpUserList = (environment, region, vin) => (dispatch) => {
  dispatch(setIsLoadingDataOdpUserList(true));
  postWithAuth("GETODPUSERLIST", {}, { environment, region, vin })
    .then((response) => {
      if (response !== null) {
        dispatch(setOdpUserList(response));
      } else {
        console.log(response);
      }
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
      dispatch(setNoPrimaryUserFound(true));
    })
    .finally(() => {
      dispatch(setIsLoadingDataOdpUserList(false));
    });
};

export default odpUserListSlice.reducer;
