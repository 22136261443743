import React from "react";
import classes from "./ModalCustom.module.css";
import close from "../Assets/Icons/close.png";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedTest,
  selectShowModal,
  setShowModal,
} from "../../pages/TestData Selection/testsSlice";

function ModalCustom() {
  const dispatch = useDispatch();
  const show = useSelector(selectShowModal);
  const selectedTest = useSelector(selectSelectedTest);

  return (
    <div style={{ border: "25px solid yellow" }}>
      {" "}
      <Modal
        isOpen={show}
        ariaHideApp={false}
        className={classes.modalBck}
        onClick={() => {
          dispatch(setShowModal(false));
        }}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(28, 28, 28, 0.9)",
          },
        }}
      >
        <img
          className={classes.closeImg}
          src={close}
          alt=""
          onClick={() => {
            dispatch(setShowModal(false));
          }}
        ></img>
        <div className={classes.container} data-cy="modal-custom">
          <div className={classes.title}>
            <p>{selectedTest.scenarioTitle}</p>
          </div>
          <div className={classes.message}>
            <p>{selectedTest.scenarioDescription}</p>
          </div>
          <div
            className={classes.okayBtn}
            data-cy="okay-button"
            onClick={() => {
              dispatch(setShowModal(false));
            }}
          >
            {" "}
            Okay
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ModalCustom;
