// import { rollen } from "./utils/authConfig";
import React from "react";
import HomePage from "./pages/Homepage/HomePage.jsx";
import "./App.css";
import NavigationSection from "./components/Navigation Section/NavigationSection";
import { Switch, Route } from "react-router-dom";
import TestDataSelectionPage from "./pages/TestData Selection/TestDataSelectionPage";
import RunningTestsPage from "./pages/RunningTests Page/RunningTestsPage";
import ResultsReportPage from "./pages/Results Report Page/ResultsReportPage";
import { EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import WelcomePage from "./pages/welcomePage/WelcomePage";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { pca, authRequest } from "./utils/authConfig.js";
import AuthError from "./pages/AuthError.jsx";
import AuthLoading from "./pages/AuthLoading.jsx";
import { InteractionType } from "@azure/msal-browser";
import BackendData from "./pages/backendDataAnalysis/BackendDataAnalysis.jsx";
import BackendDataInfoPage from "./pages/backendDataInfoPage/BackendDataInfoPage";
import LandingPageWithThumbnails from "./pages/landingPage/LandingPageWithThumbnails.jsx";
import CcpGenerator from "./pages/TOOL-ccpGenerator/CcpGenerator.jsx";
import VkmsCertificateCreator from "./pages/TOOL-vkmsCertificateCreator/VkmsCertificateCreator.jsx";
import VinByUuID from "./pages/TOOL-vinByUuID/VinByUuID.jsx";
import VdsCheckTool from "./pages/TOOL-vdsCheck/VdsCheckTool.jsx";
// import VdsCheckTool from "./pages/TOOL-vdsCheck/vdsCheckTool.jsx";
import SopViewerTool from "./pages/TOOL-sopViewer/SopViewerTool.jsx";
import Pseudonymizer from "./pages/TOOL-pseudonymizer/Pseudonymizer.jsx";
import Soundtrack from "#components/themesong/Soundtrack.jsx";
import CertificateBundles from "#pages/TOOL-certificateBundles/CertificateBundles.jsx";
import AdminToolTraceTransformer from "#pages/TOOL-adminToolTraceTransformer/AdminToolTraceTransformer.jsx";

const accounts = pca.getAllAccounts();
if (accounts.length > 0) pca.setActiveAccount(accounts[0]);

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    pca.setActiveAccount(account);
  }
});

pca.addEventCallback((event) => {
  if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    pca.setActiveAccount(account);
  }
});

const rollen = {
  everyone: "everyone",
};

export function isAuthorized(rolle) {
  if (rolle.includes(rollen.everyone)) {
    return true;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const userRoles = account?.idTokenClaims?.roles;

  if (userRoles) {
    return rolle.filter((element) => userRoles.includes(element)).length > 0;
  } else {
    return false;
  }
}

const routes = [
  {
    name: "LandingPage",
    // component: LandingPage,
    component: LandingPageWithThumbnails,
    path: "/",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "Welcome",
    component: WelcomePage,
    path: "/welcome",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "Home",
    component: HomePage,
    path: "/homepage",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "Data&Info",
    component: TestDataSelectionPage,
    path: "/data-and-info/:id",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "Progress",
    component: RunningTestsPage,
    path: "/progress/:id",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "Results",
    component: ResultsReportPage,
    path: "/results",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "BackendData",
    component: BackendData,
    path: "/backendData",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "BackendDataAnalysis",
    component: BackendDataInfoPage,
    path: "/vehicleBackendDataAnalysis/:searchParams?",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "CcpGenerator",
    component: CcpGenerator,
    path: "/ccpGenerator",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "VkmsCertificateCreator",
    component: VkmsCertificateCreator,
    path: "/vkmsCertificateCreator",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "VinByUuID",
    component: VinByUuID,
    path: "/vinByUuID",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "VdsCheck",
    component: VdsCheckTool,
    path: "/vdsCheck",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "SopViewer",
    component: SopViewerTool,
    path: "/sopViewer",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "Pseudonymizer",
    component: Pseudonymizer,
    path: "/pseudonymizer",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "CertificateBundles",
    component: CertificateBundles,
    path: "/certificateBundles",
    showInMenu: true,
    roles: [rollen.everyone],
  },
  {
    name: "AdminToolTraceTransformer",
    component: AdminToolTraceTransformer,
    path: "/adminToolTraceTransformer",
    showInMenu: true,
    roles: [rollen.everyone],
  },
];

const env = window.location.href;

function App() {
  if (env.includes("localHost")) {
    return (
      <div className="app-container">
        <div className="app">
          <NavigationSection />
          <Soundtrack />
          <Switch>
            <MapRoutes routes={routes}></MapRoutes>
          </Switch>
        </div>
      </div>
    );
  } else {
    return (
      <div className="app-container">
        <div className="app">
          <MsalProvider instance={pca}>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={authRequest}
              errorComponent={AuthError}
              loadingComponent={AuthLoading}
            >
              <NavigationSection />
              <Soundtrack />
              <Switch>
                <MapRoutes routes={routes}></MapRoutes>
              </Switch>
            </MsalAuthenticationTemplate>
          </MsalProvider>
        </div>
      </div>
    );
  }
}

const MapRoutes = (params) => {
  return (
    <React.Fragment>
      {params.routes.map((e, i) => {
        if (e.items) {
          return <MapRoutes key={i} routes={e.items}></MapRoutes>;
        } else {
          if ("path" in e && "component" in e)
            return (
              <Route key={e.name} exact path={e.path} component={e.component} />
            );
        }
      })}
    </React.Fragment>
  );
};

export default App;
