import React, { Fragment } from "react";
import classes from "./FullButton.module.css";
import next from "../../Assets/Icons/next.png";
import { useHistory } from "react-router-dom";

function FullButton({
  buttonText,
  destination,
  disabledConditions,
  extraInfo,
  extraInfoText = false,
}) {
  const history = useHistory();

  const fullButtonClass = disabledConditions
    ? classes.fullButtonDisabled
    : classes.fullButton;

  return (
    <Fragment>
      {extraInfo ? (
        <div
          style={{
            position: "relative",
            top: "-2rem",
            fontFamily: "var(--semiBold)",
            color: "var(--primary",
          }}
        >
          {" "}
          {extraInfoText}{" "}
        </div>
      ) : (
        ""
      )}
      {extraInfo ? (
        <div
          className={fullButtonClass}
          onClick={() => {
            !disabledConditions && history.push(`${destination}`);
          }}
          data-cy='full-button'
          style={{ position: "relative", top: "-1.4rem" }}
        >
          <img src={next} alt='' />
          <p>{buttonText}</p>
        </div>
      ) : (
        <div
          className={fullButtonClass}
          onClick={() => {
            !disabledConditions && history.push(`${destination}`);
          }}
          data-cy='full-button'
          style={{ position: "relative" }}
        >
          <img src={next} alt='' />
          <p>{buttonText}</p>
        </div>
      )}
    </Fragment>
  );
}

export default FullButton;
