import { useLayoutEffect } from "react";
import classes from "./TestItem.module.css";
import info from "../../../ui/Assets/Icons/info.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedTest,
  setSelectedTest,
  setShowModal,
} from "../../../pages/TestData Selection/testsSlice";

function TestItem({
  title,
  description,
  loadTest,
  selected,
  scenarioType,
  setSelected,
}) {
  const dispatch = useDispatch();
  const selectedTest = useSelector(selectSelectedTest);

  useLayoutEffect(() => {
    dispatch(setSelectedTest(scenarioType));
  }, [scenarioType]);

  const infoHandler = () => {
    dispatch(setShowModal(true));
  };

  const displayedDescription =
    description.length > 50 ? description.slice(0, 50) + "..." : description;

  const testClass = selected ? classes.testItemBordered : classes.testItem;

  return (
    <div
      className={testClass}
      data-cy="test-item"
      onClick={() => {
        loadTest(scenarioType);
        dispatch(setSelectedTest(scenarioType));
        // setSelected();
        console.log(scenarioType);
        console.log(selectedTest.scenarioType);
      }}
    >
      <div className={classes.testDescription}>
        <div className={classes.testName} data-cy="test-name">
          {title}
        </div>
        <div className={classes.testID} data-cy="test-description">
          {displayedDescription}
        </div>
      </div>
      <div
        className={classes.testDetails}
        data-cy="test-details"
        onClick={infoHandler}
      >
        <img src={info} alt="" />{" "}
      </div>
    </div>
  );
}

export default TestItem;
