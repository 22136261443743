import React from // , { useEffect }
"react";
import classes from "./SearchBox.module.css";
// import search from "../../ui/Assets/Icons/search.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  selectFilerValue,
  setFilterValue,
} from "../../pages/Homepage/projectSlice";

function SearchBox() {
  const dispatch = useDispatch();
  const filterValue = useSelector(selectFilerValue);

  const setFilters = (event) => {
    dispatch(setFilterValue(event.target.value));
  };

  // const searchHandler = () => {
  //   dispatch(setFilterValue(document.getElementById("searchDetails").value))
  // console.log(document.getElementById("searchDetails").value)
  // console.log(filterValue)
  // dispatch(setFilterValue(""))
  // }

  return (
    <div className={classes.searchElement} data-cy='searchElement'>
      <div className={classes.searchControl} data-cy='searchControl'>
        <div className={classes.searchBox} data-cy='searchBox'>
          <input
            id='searchDetails'
            autoComplete='off'
            required
            data-cy='searchInput'
            value={filterValue}
            onChange={setFilters}
          ></input>
          <label
            htmlFor='searchDetails'
            className={classes.searchLabel}
            data-cy='searchLabel'
          >
            <span className={classes.searchContent} data-cy='searchContent'>
              Search...{" "}
            </span>{" "}
          </label>
        </div>
      </div>
      {/* <div className={classes.searchAction} data-cy='searchButton' onClick={() => {searchHandler()}}>
        {" "}
        <img src={search} alt="" />{" "}
      </div> */}
    </div>
  );
}

export default SearchBox;
