import React, { useEffect, useState } from "react";
import classes from "./vinByUuID.module.css";
import HorizontalDivider from "../../components/Horizontal Divider/HorizontalDivider";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import {
  setVinByUuID,
  setRegByUuID,
  setEnvByUuID,
  selectEnvByUuID,
  selectRegByUuID,
  selectVinByUuID,
  selectIsLoadingVinByUuID,
  selectPresetEnvByUuID,
  selectPresetRegByUuID,
  getVehicleVinByUuid,
  selectVehicleByUuid,
  selectEmptyState,
  setVehicleByUuid,
  selectVinToUuIDValue,
  setVinToUuIDvalue,
} from "./vinByUuIDSlice";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../ui/Loading Spinner/LoadingSpinner";
import copy from "../../ui/Assets/Icons/copyToClipboard.png";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  selectVehicleUuID,
  getVehicleByUuid,
  selectIsLoadingDataVehicleUuID,
  selectEmptyStateUuid,
} from "../backendDataInfoPage/vehicleUuIDSlice";
// import { selectEmptyStateUuid } from "#pages/backendDataInfoPage/getVehiclebyUuidSlice.js";
function VinByUuID() {
  const [uuidError, setUuidError] = useState(false);
  const [vinError, setVinError] = useState(false);

  const dispatch = useDispatch();
  const vinByUuid = useSelector(selectVinByUuID);
  const envByUuid = useSelector(selectEnvByUuID);
  const presetEnvByUuid = useSelector(selectPresetEnvByUuID);
  const regByUuid = useSelector(selectRegByUuID);
  const presetRegByUuid = useSelector(selectPresetRegByUuID);
  const vehicleObjectByUuid = useSelector(selectVehicleByUuid);
  const isLoadingDataVinByUuid = useSelector(selectIsLoadingVinByUuID);
  const emptyState = useSelector(selectEmptyState);
  const emptyStateUuid = useSelector(selectEmptyStateUuid);
  const vinReceivedFromUuid = vehicleObjectByUuid?.VehicleUuid;

  const vehicleUuID = useSelector(selectVehicleUuID);
  const isLoadingDataVehicleUuID = useSelector(selectIsLoadingDataVehicleUuID);
  const uuidByVIN = useSelector(selectVehicleUuID);
  const vinToUuIDValue = useSelector(selectVinToUuIDValue).toUpperCase();
  const uuidReceivedFromVIN = vehicleUuID?.VehicleId;

  const vinHandlerVinByUuID = (userInsertedVIN) => {
    dispatch(setVinByUuID(userInsertedVIN));
  };

  const regionHandlerVinByUuID = (userSelectedRegion) => {
    dispatch(setRegByUuID(userSelectedRegion));
  };

  const environmentHandlerVinByUuID = (userSelectedEnvironment) => {
    dispatch(setEnvByUuID(userSelectedEnvironment));
  };

  const uuidHandlerUuidByVIN = (userInsertedVIN) => {
    dispatch(setVinToUuIDvalue(userInsertedVIN));
  };
  const searchVinByUuID = (environment, region, uuid) => {
    const uuidRegex = /^[A-Za-z0-9]{32}$/;
    if (uuid !== "") {
      if (uuidRegex.test(uuid.toUpperCase())) {
        console.log("uuid ok");

        setUuidError(false);
        dispatch(
          getVehicleVinByUuid({
            environment: envByUuid,
            region: regByUuid,
            uuid: vinByUuid,
          })
        );
      } else {
        console.log("Invalid UUID!");
        setUuidError(true);
      }
    } else {
      setUuidError(true);
      console.log("UUID is empty!");
    }

    getVehicleVinByUuid(environment, region, uuid);
  };

  const searchUuidByVIN = (environment, region, vin) => {
    const vinRegex = /^[A-Za-z0-9]{17}$/;
    if (vin !== "") {
      if (vinRegex.test(vin.toUpperCase())) {
        setVinError(false);
        dispatch(getVehicleByUuid(environment, region, vin));
        console.log("vin ok");
      } else {
        console.log("Invalid VIN!");
        setVinError(true);
      }
    } else {
      setVinError(true);
      console.log("VIN is empty!");
    }
    getVehicleByUuid(environment, region, vin);
  };

  useEffect(() => {
    if (
      vinByUuid === "" ||
      vinByUuid === undefined ||
      regByUuid === "" ||
      regByUuid === undefined ||
      envByUuid === "" ||
      envByUuid === undefined ||
      vinToUuIDValue === "" ||
      vinToUuIDValue === undefined
    ) {
      dispatch(setVinByUuID(""));
      dispatch(setVinToUuIDvalue(""));
      dispatch(setEnvByUuID(presetEnvByUuid));
      dispatch(setRegByUuID(presetRegByUuid));
    }
  }, []);
  return (
    <div className={classes.vinByUuIDContainer}>
      <div className={classes.vinByUuIDSection}>
        <div className={classes.vinByUuIDSectionTitle}>
          VWAC Vehicle ID (UuID) & VIN Transformer
        </div>
        <HorizontalDivider />
        <div className={classes.vinByUuIDSectionActionSection}>
          <div className={classes.regionSelectionRow}>
            <div className={classes.regionSection}>
              <FormControl>
                <FormLabel id="reg-radio-buttons-group-label">Region</FormLabel>
                <RadioGroup
                  aria-labelledby="reg-radio-buttons-group-label"
                  value={
                    regByUuid !== undefined && regByUuid !== ""
                      ? regByUuid
                      : presetRegByUuid
                  }
                  onChange={(event) => {
                    regionHandlerVinByUuID(event.target.value);
                    console.log(event.target.value);
                    console.log(regByUuid);
                  }}
                  name="radio-buttons-group"
                  row
                >
                  <FormControlLabel
                    value="ece"
                    control={<Radio size="small" />}
                    label="ECE"
                  />
                  <FormControlLabel
                    value="nar"
                    control={<Radio size="small" />}
                    // disabled
                    label="NAR"
                  />
                  <FormControlLabel
                    value="cn"
                    control={<Radio size="small" />}
                    // disabled
                    label="CN"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className={classes.environmentSection}>
              <FormControl>
                <FormLabel id="env-radio-buttons-group-label">
                  Environment
                </FormLabel>
                <RadioGroup
                  aria-labelledby="env-radio-buttons-group-label"
                  value={
                    envByUuid !== undefined && envByUuid !== ""
                      ? envByUuid
                      : presetEnvByUuid
                  }
                  onChange={(event) => {
                    environmentHandlerVinByUuID(event.target.value);
                  }}
                  name="radio-buttons-group"
                  row
                >
                  <FormControlLabel
                    value="demo"
                    disabled
                    control={<Radio size="small" />}
                    label="Demo"
                  />
                  <FormControlLabel
                    value="approval"
                    control={<Radio size="small" />}
                    label="Approval"
                  />
                  <FormControlLabel
                    value="live"
                    control={<Radio size="small" />}
                    label="Live"
                    disabled={regByUuid === "cn" ? true : false}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div
            className={classes.flexContainerRowSubtitle}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            {" "}
            Insert VWAC Vehicle ID:{" "}
          </div>
          <div className={classes.flexContainerRowSearch}>
            {/*  */}
            <input
              id="searchDetails"
              autoComplete="off"
              required
              data-cy="searchInput"
              value={vinByUuid || ""}
              onChange={(event) => {
                vinHandlerVinByUuID(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  searchVinByUuID(envByUuid, regByUuid, vinByUuid);
                }
              }}
              style={{ userSelect: "text", cursor: "auto" }}
              readOnly={false}
            ></input>

            <label
              htmlFor="searchDetails"
              className={classes.searchLabel}
              data-cy="searchLabel"
              style={{ userSelect: "text", cursor: "auto" }}
            ></label>

            <div
              className={classes.actionBtnSearchUUIDToVIN}
              onClick={() => {
                // dispatch(setVehicleByUuid({}));

                searchVinByUuID(envByUuid, regByUuid, vinByUuid);
              }}
            >
              Get VIN
            </div>
          </div>
        </div>
        {uuidError ? (
          <div className={classes.displayBoxVinSectionError}>
            Invalid UUID format
          </div>
        ) : (
          ""
        )}
        {!emptyState ? (
          <div className={classes.displayBoxVinSection}>
            {isLoadingDataVinByUuid ? (
              <LoadingSpinner />
            ) : (
              <div className={classes.displayBoxVin}>
                <div className={classes.displayBoxVinText}>
                  {vinReceivedFromUuid !== "" &&
                  vinReceivedFromUuid !== undefined &&
                  !isLoadingDataVinByUuid
                    ? "VIN Found for UuID:"
                    : "No VIN found for uuid "}
                </div>

                <div className={classes.vinAvailabilityBox}>
                  <div className={classes.vinAvailable}>
                    {vinReceivedFromUuid !== "" &&
                    vinReceivedFromUuid !== undefined
                      ? vinReceivedFromUuid
                      : "Not available"}
                  </div>
                  {vinReceivedFromUuid !== "" &&
                  vinReceivedFromUuid !== undefined ? (
                    <div className={classes.copyVIN}>
                      <CopyToClipboard text={vinReceivedFromUuid}>
                        <img src={copy} alt="" />
                      </CopyToClipboard>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        {/* End of Vin By UUID section */}
        <div className={classes.vinByUuIDSectionTitleVinToUuid}></div>
        <HorizontalDivider />
        {/* VIN to UUID */}
        <div className={classes.vinToUUIDSection}>
          <div
            className={classes.flexContainerRowSubtitle}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            {" "}
            Insert vehicle VIN:
          </div>
          <div className={classes.flexContainerRowSearch}>
            {/*  */}
            <input
              id="searchDetails"
              autoComplete="off"
              required
              data-cy="searchInput"
              value={vinToUuIDValue || ""}
              onChange={(event) => {
                uuidHandlerUuidByVIN(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  searchUuidByVIN(envByUuid, regByUuid, event.target.value);
                }
              }}
              style={{ userSelect: "text", cursor: "auto" }}
              readOnly={false}
            ></input>
            <label
              htmlFor="searchDetails"
              className={classes.searchLabel}
              data-cy="searchLabel"
              style={{ userSelect: "text", cursor: "auto" }}
            ></label>

            <div
              className={classes.actionBtnSearchUUIDToVIN}
              onClick={() => {
                // dispatch(setVehicleByUuid({}));
                searchUuidByVIN(envByUuid, regByUuid, vinToUuIDValue);
              }}
            >
              Get UuID
            </div>
          </div>
        </div>
        {vinError ? (
          <div className={classes.displayBoxVinSectionError}>
            Invalid VIN format
          </div>
        ) : (
          ""
        )}
        {!emptyStateUuid && (
          <div className={classes.displayBoxUUIDSection}>
            {isLoadingDataVehicleUuID ? (
              <LoadingSpinner />
            ) : (
              <div className={classes.displayBoxUUID}>
                <div className={classes.displayBoxUUIDText}>
                  {uuidReceivedFromVIN
                    ? "UuID Found for VIN:"
                    : "No UuID found for VIN"}
                </div>

                <div className={classes.uuidAvailabilityBox}>
                  <div className={classes.uuidAvailable}>
                    {uuidReceivedFromVIN || "Not available"}
                  </div>
                  {uuidReceivedFromVIN && (
                    <div className={classes.copyUUID}>
                      <CopyToClipboard text={uuidReceivedFromVIN}>
                        <img src={copy} alt="" />
                      </CopyToClipboard>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {/* End of VIN to UUID */}
      </div>
    </div>
  );
}

export default VinByUuID;
