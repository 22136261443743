import React, { useEffect, useState } from "react";
import classes from "./SearchBoxModel.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  selectFilterName,
  setFilterName,
} from "../../pages/Homepage/projectSlice";

function SearchBoxName() {
  const [filter, setfilter] = useState("");
  const dispatch = useDispatch();
  const filterName = useSelector(selectFilterName);

  useEffect(() => {
    dispatch(setFilterName(filter));
  }, [filter]);

  const setFilters = (event) => {
    setfilter(event.target.value);
  };

  return (
    <div className={classes.searchElementModel} data-cy='searchElement'>
      <div className={classes.searchControlModel} data-cy='searchControl'>
        <div className={classes.searchBoxModel} data-cy='searchBox'>
          <input
            id='searchDetailsModel'
            autoComplete='off'
            required
            data-cy='searchInput'
            value={filterName}
            onChange={setFilters}
          ></input>
          <label
            htmlFor='searchDetailsModel'
            className={classes.searchLabel}
            data-cy='searchLabel'
          >
            {/* <span className={classes.searchContent} data-cy='searchContent'>
              Search...{" "}
            </span>{" "} */}
          </label>
        </div>
      </div>
    </div>
  );
}

export default SearchBoxName;
