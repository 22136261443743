import { createSlice } from "@reduxjs/toolkit";
import { getWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";

const initialState = {
  isLoadingList: false,
  allTests: [],
  selectedTest: {
    scenarioType: "",
    scenarioTitle: "",
    scenarioDescription: "",
  },
  isSelected: false,
  showModal: false,
  testDataIsLocked: false,
  pageFirstLoad: true,
  selectedScenarioType: null,
};

// Slice
const testsSlice = createSlice({
  name: "tests",
  initialState,
  reducers: {
    setIsLoadingList: (state, action) => {
      state.isLoadingList = action.payload;
    },
    setAllTests: (state, action) => {
      state.allTests = action.payload;
    },
    setSelectedTest: (state, action) => {
      // console.log(state.selectedTest.scenarioType);
      // console.log(action.payload);
      state.selectedTest = state.allTests.find(
        (e) => e.scenarioType === action.payload
      );
    },
    setIsSelected: (state, action) => {
      state.isSelected = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setTestDataIsLocked: (state, action) => {
      state.testDataIsLocked = action.payload;
    },
    setPageFirstLoad: (state, action) => {
      state.pageFirstLoad = action.payload;
    },
    setSelectedScenarioType: (state, action) => {
      state.selectedScenarioType = action.payload;
    },
  },
});

// Selectors
export const selectIsLoadingList = (state) => state.tests.isLoadingList;
export const selectAllTests = (state) => state.tests.allTests;
export const selectSelectedTest = (state) => state.tests.selectedTest;
export const selectIsSelected = (state) => state.tests.isSelected;
export const selectShowModal = (state) => state.tests.showModal;
export const selectTestDataIsLocked = (state) => state.tests.testDataIsLocked;
export const selectPageFirstLoad = (state) => state.tests.pageFirstLoad;
export const selectSelectedScenarioType = (state) =>
  state.tests.selectedScenarioType;

// Actions

export const {
  setAllTests,
  setIsLoadingList,
  setSelectedTest,
  setIsSelected,
  setShowModal,
  setTestDataIsLocked,
  setPageFirstLoad,
  setSelectedScenarioType,
} = testsSlice.actions;

//Getter

export const getTests = () => (dispatch) => {
  dispatch(setIsLoadingList(true));
  getWithAuth("GETTESTSCENARIOS", {})
    .then((response) => {
      response !== null
        ? dispatch(setAllTests(response))
        : console.log(response);
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
    })
    .finally(dispatch(setIsLoadingList(false)));
};

export const checkIfTestDataIsLocked = (referenceId) => (dispatch) => {
  dispatch(setIsLoadingList(true));
  getWithAuth("BLOCKLIST", {})
    .then((response) => {
      console.log(response);
      if (JSON.stringify(response).includes(`${referenceId}`)) {
        dispatch(setTestDataIsLocked(true));
      } else {
        dispatch(setTestDataIsLocked(false));
      }
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
    })
    .finally(dispatch(setIsLoadingList(false)));
};

export default testsSlice.reducer;
