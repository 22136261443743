import React from "react";
import classes from "./RunningTestsPage.module.css";
import ProgressDetails from "../../components/Progress Details/ProgressDetails";
import FullButton from "../../ui/Buttons/Full Button/FullButton";
import ProjectDetails from "../../components/Project Details/ProjectDetails";
import { useSelector } from "react-redux";
import {
  selectLastResultAvailable,
  selectResultsArray,
  selectStatus,
} from "../Results Report Page/resultsSlice";
import { selectSelectedModelId } from "../Homepage/projectSlice";
import Snackbar from "../../components/Snackbar/Snackbar";
import HorizontalDevider from "../../components/Horizontal Divider/HorizontalDivider";
import BackButton from "../../ui/Buttons/Back Button/BackButton";
import LastResultBtn from "../../components/lastResultsBtn/LastResultBtn";

function RunningTestsPage() {
  // const paramsList = useParams();
  // const executionId = useSelector(selectExecutionId);
  const redirectId = useSelector(selectSelectedModelId);
  const resultsarray = useSelector(selectResultsArray);
  // const websocketMessage = useSelector(selectWebsocketMessage);

  const status = useSelector(selectStatus);
  const lastResultAvailable = useSelector(selectLastResultAvailable);

  //  WITH thunk
  // useEffect(() => {
  //   // connectWebSocket(dispatch);
  //   dispatch(setOpen(false));
  //     if (executionId === "") {
  //       dispatch(setStatus("Pending..."))
  //       return;
  //     } else {
  //       dispatch(getTestResults(executionId));
  //     }
  // }, [executionId, dispatch]);

  // dispatch(setIsTestDataInUse(websocketMessage.Data.hasOwnProperty(redirectId)));
  // websocketMessage.Data.hasOwnProperty(redirectId) ? dispatch(setIsTestDataInUse(true)) : dispatch(setIsTestDataInUse(false));

  return (
    <div className={classes.gridContainer}>
      <div className={classes.flexContainerHeader}>
        <div className={classes.flexItemHeader}>
          <div className={classes.dropdown}>
            <div className={classes.flexContainerRow}> </div>
            <div className={classes.flexContainerRow}> </div>
            <div className={classes.flexContainerRow}> </div>
            <div className={classes.flexContainerRowTitle}>
              {" "}
              Tests in progress...
            </div>
            <div
              style={{
                justifyContent: "flex-end",
                display: "flex",
                alignItems: "center",
                // width: " 100%",
                lineHeight: "1.5rem",
                padding: " 0.35rem 0",
                marginRight: "4.95rem",
              }}
            >
              {" "}
              {lastResultAvailable ? (
                <LastResultBtn />
              ) : (
                <div style={{ height: "1.5rem", width: "7.5rem" }} />
              )}{" "}
            </div>
            <HorizontalDevider />
            <BackButton
              backMessage={"Back to test scenario selection"}
              destination={`/data-and-info/${redirectId}`}
            />
            <div className={classes.flexContainerRow}> </div>
            <div className={classes.flexContainerRow}> </div>
          </div>
        </div>
        <div className={classes.runningTestsSection}>
          <div
            className={classes.progressList}
            style={{
              width: "45%",
              marginLeft: "5%",
              height: "47.5vh",
              overflowY: "scroll",
            }}
          >
            <ProjectDetails />
          </div>
          <div
            className={classes.testDetails}
            style={{
              width: "45%",
              height: "47.5vh",
              marginLeft: "5%",
              marginRight: "5%",
              border: "1px solid var(--dark-gray",
            }}
          >
            <ProgressDetails />
          </div>
        </div>
        {resultsarray.length > 0 ? (
          ""
        ) : (
          <div className={classes.gridItemRow}>
            <Snackbar />
          </div>
        )}
        <div className={classes.gridItemRow}></div>
        <div className={classes.gridItemRow}></div>
        <div className={classes.actionSection}>
          <div className={classes.showBtn}>
            <FullButton
              disabledConditions={status !== "Success"}
              buttonText={"Show result overview"}
              destination={"/results"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RunningTestsPage;
