import { createSlice } from "@reduxjs/toolkit";
import { getWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";

const initialState = {
  isloadingList: false,
  allProjects: [],
  allProjectsWithSOP: [],
  isSelected: false,
  allSOPs: [],
  selectedModel: {
    referenceId: " ",
    comment: " ",
    environment: " ",
    region: " ",
    sop: "",
    metadata: {
      owner: " ",
      access: " ",
      eol: " ",
      fPinProvider: " ",
      homeRegion: " ",
      vin: " ",
      masterdataProvider: " ",
    },
    masterdata: {
      country: " ",
      engineCode: " ",
      mmi: " ",
      colorFinish: " ",
      colorInterior: " ",
      pnrList: " ",
      modelYear: " ",
      modelRange: " ",
      modelDescription: " ",
      productionDate: " ",
      fuelType: " ",
      modelCode: " ",
      mappingVIN: " ",
      colorTop: " ",
      colorPaint: " ",
      vin: " ",
      countryOfMarket: " ",
      engineCodeExtension: " ",
      deliveryDate: " ",
      brand: " ",
      countryOfRegistration: " ",
    },
    releaseVersion: " ",
    primaryMbbUser: " ",
    secondaryMbbUser: " ",
    primaryPortalUser: " ",
    secondaryPortalUser: " ",
  },
  selectedModelId: "",
  filterValue: "",
  filterName: "",
  filterSOP: "",
  filterYear: "",
  filterRelease: "",
  filterDevice: "",
  isFirstVisit: false,
  progressURL: "",
  defaultModel: {},
  searchVIN: "",
};

// Slice
const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setIsLoadingList: (state, action) => {
      state.isloadingList = action.payload;
    },
    setAllProjects: (state, action) => {
      state.allProjects = action.payload;
    },
    setAllProjectsWithSOP: (state, action) => {
      state.allProjectsWithSOP = action.payload;
    },
    setIsSelected: (state, action) => {
      state.isSelected = action.payload;
    },
    setSelectedModel: (state, action) => {
      state.selectedModel = state.allProjects.find(
        (e) => e.referenceId === action.payload
      );
    },
    setSOP: (state, action) => {
      state.selectedModel.sop = action.payload;
    },
    setSelectedModelId: (state, action) => {
      state.selectedModelId = action.payload;
    },
    setFilterValue: (state, action) => {
      state.filterValue = action.payload;
    },
    setIsFirstVisit: (state, action) => {
      state.isFirstVisit = action.payload;
    },
    setProgressURL: (state, action) => {
      state.progressURL = action.payload;
    },
    setDefaultModel: (state, action) => {
      state.defaultModel = action.payload;
    },
    setFilterName: (state, action) => {
      state.filterName = action.payload;
    },
    setFilterSOP: (state, action) => {
      state.filterSOP = action.payload;
    },
    setFilterYear: (state, action) => {
      state.filterYear = action.payload;
    },
    setFilterRelease: (state, action) => {
      state.filterRelease = action.payload;
    },
    setFilterDevice: (state, action) => {
      state.filterDevice = action.payload;
    },
    setAllSOPs: (state, action) => {
      state.allSOPs = action.payload;
    },
    setSearchVIN: (state, action) => {
      state.searchVIN = action.payload;
    },
  },
});

// Selectors

export const selectIsLoadingList = (state) => state.projects.isloadingList;
export const selectAllProjects = (state) => state.projects.allProjects;
export const selectAllProjectsWithSOP = (state) =>
  state.projects.allProjectsWithSOP;
export const selectSelectedModel = (state) => state.projects.selectedModel;
export const selectIsSelected = (state) => state.projects.isSelected;
export const selectSelectedModelId = (state) => state.projects.selectedModelId;
export const selectFilerValue = (state) => state.projects.filterValue;
export const selectIsFirstVisit = (state) => state.projects.isFirstVisit;
export const selectProgressURL = (state) => state.projects.progressURL;
export const selectDefaultModel = (state) => state.projects.defaultModel;
export const selectFilterName = (state) => state.projects.filterName;
export const selectFilterSOP = (state) => state.projects.filterSOP;
export const selectFilterYear = (state) => state.projects.filterYear;
export const selectFilterRelease = (state) => state.projects.filterRelease;
export const selectFilterDevice = (state) => state.projects.filterDevice;
export const selectedSOP = (state) => state.projects.selectedModel.sop;
export const selectAllSOPs = (state) => state.projects.allSOPS;
export const selectsearchVIN = (state) => state.projects.searchVIN;

// Actions

export const {
  setAllProjects,
  setIsLoadingList,
  setSelectedModel,
  setIsSelected,
  setSelectedModelId,
  setFilterValue,
  setIsFirstVisit,
  setProgressURL,
  setDefaultModel,
  setFilterDevice,
  setFilterName,
  setFilterRelease,
  setFilterSOP,
  setFilterYear,
  setSOP,
  setAllSOPs,
  setAllProjectsWithSOP,
  setSearchVIN,
} = projectsSlice.actions;

//Getter

export const getProjects = () => (dispatch) => {
  dispatch(setIsLoadingList(true));
  getWithAuth("GETTESTDATA", {})
    .then((response) => {
      if (response !== null) {
        dispatch(setAllProjects(response));
      } else {
        console.log(response);
      }
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
    })
    .finally(() => {
      dispatch(setIsLoadingList(false));
    });
};

export default projectsSlice.reducer;
