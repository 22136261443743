import {
  setRegion,
  setEvironment,
  setEmptyFields,
  setVIN,
} from "./backendInfoSlice";
import {
  setConnectionStatus,
  getConnectionStatus,
  setPingTimestamp,
  setAccessForbidden,
  clearPingData,
  clearPingTimeStamp,
} from "./connectionStatusCacheSlice";
import { setServiceGroup, setRelatedSOPs } from "./serviceGroupSlice";
import { setVehicleClaims } from "./vehicleClaimsSlice";
import { setVehicleUuID } from "./vehicleUuIDSlice";
import { setVehicleUsers } from "./vehicleUsersSlice";
import { setVehicleJobs } from "./activationJobsSlice";
import { setAllVehicleServiceClaims } from "./allVehicleServiceClaimsSlice";
import { setPrimaryUserClaims } from "./primaryUserClaimsSlice";
import {
  setVehicleCurrentState,
  setAccessForbiddenToState,
} from "./vehicleCurrentStateSlice";
import {
  setConnectionStatusLive,
  getConnectionStatusLive,
  clearPingDataLive,
  clearPingTimeStampLive,
} from "./connectionStatusLiveSlice";
import { triggerBackendFunctions } from "./triggerBackendFunctions";
import {
  setSystemID,
  setUrl,
  setVinAvailableInOtherEnvOrReg,
  setVinStagesAndEnvironments,
  setVinStagesAndEnvironmentsCheck,
} from "./vdsCheckSlice";
import { triggerVDS } from "./triggerVDS";
import { setOdpUserList } from "./odpUserList/odpUserListSlice";
import {
  setManifest,
  setManifestHistory,
  setPendingManifest,
  setPendingManifestValue,
} from "./cube/cubeSlice";
import {
  setCsoTopicCount,
  setDigitalTwin,
} from "./digitalTwin/digitalTwinSlice";

export const regionHandler = (
  dispatch,
  timeouts,
  userSelectedRegion,
  history,
  vin,
  environment
) => {
  dispatch(setRegion(userSelectedRegion));
  dispatch(setVinAvailableInOtherEnvOrReg(false));
  dispatch(setVehicleUuID({}));
  dispatch(setVehicleUsers({}));
  dispatch(setOdpUserList({}));
  dispatch(setServiceGroup({}));
  dispatch(setVehicleJobs([]));
  dispatch(setRelatedSOPs([]));
  dispatch(setVehicleClaims({}));
  dispatch(setConnectionStatus({}));
  dispatch(setPingTimestamp([]));
  dispatch(setAllVehicleServiceClaims({}));
  dispatch(setPrimaryUserClaims({}));
  dispatch(setVehicleCurrentState({}));
  dispatch(setVinStagesAndEnvironmentsCheck(null));
  dispatch(setManifest({}));
  dispatch(setManifestHistory({}));
  dispatch(setPendingManifest(""));
  dispatch(setPendingManifestValue(""));
  dispatch(setDigitalTwin({}));
  dispatch(setCsoTopicCount({}));

  // Clear systemID & url fields
  dispatch(setUrl(""));
  dispatch(setSystemID(""));

  // Clear info for cahced data
  dispatch(setConnectionStatus({}));
  dispatch(clearPingData());
  dispatch(clearPingTimeStamp());

  // Clar info for live data
  dispatch(setConnectionStatusLive({}));
  dispatch(clearPingDataLive());
  dispatch(clearPingTimeStampLive());

  dispatch(setEmptyFields(true));
  dispatch(setAccessForbidden(false));
  dispatch(setAccessForbiddenToState(false));
  // Clear timeouts so no calls are made
  for (let i = 1; i < 4; i++) {
    clearTimeout(timeouts[i]);
  }
  // Clear the VDS check
  dispatch(setVinStagesAndEnvironments([]));
  dispatch(setVinStagesAndEnvironmentsCheck(false));

  //Create the new URL with new params
  // history.replace(`vin=${vin}&reg=${userSelectedRegion}&env=${environment}`);
};

export const environmentHandler = (
  dispatch,
  timeouts,
  userSelectedEnvn,
  history,
  vin,
  region
) => {
  dispatch(setEvironment(userSelectedEnvn));
  dispatch(setVinAvailableInOtherEnvOrReg(false));
  dispatch(setVehicleUuID({}));
  dispatch(setVehicleUsers({}));
  dispatch(setOdpUserList({}));
  dispatch(setServiceGroup({}));
  dispatch(setVehicleJobs([]));
  dispatch(setRelatedSOPs([]));
  dispatch(setVehicleClaims({}));
  dispatch(setConnectionStatus({}));
  dispatch(setPingTimestamp([]));
  dispatch(setAllVehicleServiceClaims({}));
  dispatch(setPrimaryUserClaims({}));
  dispatch(setVehicleCurrentState({}));
  dispatch(setVinStagesAndEnvironmentsCheck(null));
  dispatch(setManifest({}));
  dispatch(setManifestHistory({}));
  dispatch(setPendingManifest(""));
  dispatch(setPendingManifestValue(""));
  dispatch(setDigitalTwin({}));
  dispatch(setCsoTopicCount({}));

  // Clear systemID & url fields
  dispatch(setUrl(""));
  dispatch(setSystemID(""));

  // Clear info for cahced data
  dispatch(setConnectionStatus({}));
  dispatch(clearPingData());
  dispatch(clearPingTimeStamp());

  // Clar info for live data
  dispatch(setConnectionStatusLive({}));
  dispatch(clearPingDataLive());
  dispatch(clearPingTimeStampLive());

  dispatch(setEmptyFields(true));
  dispatch(setAccessForbidden(false));
  dispatch(setAccessForbiddenToState(false));
  // Clear timeouts so no calls are made
  for (let i = 1; i < 4; i++) {
    clearTimeout(timeouts[i]);
  }
  // Clear the VDS check
  dispatch(setVinStagesAndEnvironments([]));
  dispatch(setVinStagesAndEnvironmentsCheck(false));

  //Create the new URL with new params
  // history.replace(`vin=${vin}&reg=${region}&env=${userSelectedEnvn}`);
};

export const searchHandler = (
  dispatch,
  environment,
  region,
  vin,
  cleanSections,
  setError,
  timeouts,
  history,
  newSearchParams,
  vdsCheck,
  paramsEnvironment,
  paramsRegion,
  paramsVIN
) => {
  const vinRegex = /^[A-Za-z0-9]{17}$/;
  if (vdsCheck) {
    if (vin !== "") {
      if (vinRegex.test(vin.toUpperCase())) {
        // we need to clear existing data from table so it is not confusing
        cleanSections(dispatch);

        // if search is performed, means no VIN validation error
        setError(false);

        // Set the new URL with the user selection
        history.push(`/vehicleBackendDataAnalysis/${newSearchParams}`);

        // Trigger backend functions
        triggerBackendFunctions(dispatch, environment, region, vin, timeouts);
        // Data is incoming, so loading spinners should be shown
        dispatch(setEmptyFields(false));

        // No access forbidden messages
        dispatch(setAccessForbidden(false));
        dispatch(setAccessForbiddenToState(false));
        // Clear any timeouts:
        for (let i = 1; i < 4; i++) {
          clearTimeout(timeouts[i]);
        }

        // The getConnectionStatus at 15 seconds intevall, cached info:
        for (let i = 1; i < 4; i++) {
          timeouts[i] = setTimeout(() => {
            dispatch(
              getConnectionStatus(environment, region, vin.toUpperCase())
            );
            dispatch(
              getConnectionStatusLive(environment, region, vin.toUpperCase())
            );
            console.log("Timeouts in the iteraion" + timeouts[i]);
          }, i * 15000);
        }
      } else {
        console.log("Invalid VIN!");
        setError(true);
      }
    } else {
      console.log("VIN is empty!");

      setError(true);
    }
  } else {
    // we need to clear existing data from table so it is not confusing
    cleanSections(dispatch);
    triggerVDS(dispatch, paramsEnvironment, paramsRegion, paramsVIN);
  }
};

export const vinHandlerWithVDS = (
  dispatch,
  timeouts,
  environment,
  history,
  userEnteredVin,
  region
) => {
  dispatch(setVinAvailableInOtherEnvOrReg(false));
  dispatch(setVehicleUuID({}));
  dispatch(setVehicleUsers({}));
  dispatch(setServiceGroup({}));
  dispatch(setOdpUserList({}));
  dispatch(setVehicleJobs([]));
  dispatch(setRelatedSOPs([]));
  dispatch(setVehicleClaims({}));
  dispatch(setConnectionStatus({}));
  dispatch(setPingTimestamp([]));
  dispatch(setAllVehicleServiceClaims({}));
  dispatch(setPrimaryUserClaims({}));
  dispatch(setVehicleCurrentState({}));
  dispatch(setVinStagesAndEnvironmentsCheck(null));
  dispatch(setManifest({}));
  dispatch(setManifestHistory({}));
  dispatch(setPendingManifest(""));

  // Clear systemID & url fields
  dispatch(setUrl(""));
  dispatch(setSystemID(""));

  // Clear info for cahced data
  dispatch(setConnectionStatus({}));
  dispatch(clearPingData());
  dispatch(clearPingTimeStamp());

  // Clar info for live data
  dispatch(setConnectionStatusLive({}));
  dispatch(clearPingDataLive());
  dispatch(clearPingTimeStampLive());

  dispatch(setEmptyFields(true));
  dispatch(setAccessForbidden(false));
  dispatch(setAccessForbiddenToState(false));
  // Clear timeouts so no calls are made
  for (let i = 1; i < 4; i++) {
    clearTimeout(timeouts[i]);
  }
  // Clear the VDS check
  dispatch(setVinStagesAndEnvironments([]));
  dispatch(setVinStagesAndEnvironmentsCheck(false));

  //Create the new URL with new params
  history.push(`vin=${userEnteredVin}&reg=${region}&env=${environment}`);

  const vinRegex = /^[A-Za-z0-9]{17}$/;
  if (userEnteredVin !== "") {
    if (vinRegex.test(userEnteredVin.toUpperCase())) {
      triggerVDS(userEnteredVin, region, environment);
    } else {
      console.log("Invalid VIN!");
    }
  } else {
    console.log("VIN is empty!");
  }
};

export const vinHandler = (
  dispatch,
  timeouts,
  environment,
  history,
  userEnteredVin,
  region
) => {
  dispatch(setVinAvailableInOtherEnvOrReg(false));
  dispatch(setVehicleUuID({}));
  dispatch(setVehicleUsers({}));
  dispatch(setServiceGroup({}));
  dispatch(setOdpUserList({}));
  dispatch(setVehicleJobs([]));
  dispatch(setRelatedSOPs([]));
  dispatch(setVehicleClaims({}));
  dispatch(setConnectionStatus({}));
  dispatch(setPingTimestamp([]));
  dispatch(setAllVehicleServiceClaims({}));
  dispatch(setPrimaryUserClaims({}));
  dispatch(setVehicleCurrentState({}));
  dispatch(setVinStagesAndEnvironmentsCheck(null));

  // Clear systemID & url fields
  dispatch(setUrl(""));
  dispatch(setSystemID(""));

  // Clear info for cahced data
  dispatch(setConnectionStatus({}));
  dispatch(clearPingData());
  dispatch(clearPingTimeStamp());

  // Clar info for live data
  dispatch(setConnectionStatusLive({}));
  dispatch(clearPingDataLive());
  dispatch(clearPingTimeStampLive());

  dispatch(setEmptyFields(true));
  dispatch(setAccessForbidden(false));
  dispatch(setAccessForbiddenToState(false));
  // Clear timeouts so no calls are made
  for (let i = 1; i < 4; i++) {
    clearTimeout(timeouts[i]);
  }
  // Clear the VDS check
  dispatch(setVinStagesAndEnvironments([]));
  dispatch(setVinStagesAndEnvironmentsCheck(false));

  const vinRegex = /^[A-Za-z0-9]{17}$/;
  if (userEnteredVin !== "") {
    if (vinRegex.test(userEnteredVin.toUpperCase())) {
      // triggerVDS(userEnteredVin, region, environment);
      dispatch(setVIN(userEnteredVin));
    } else {
      console.log("Invalid VIN!");
    }
  } else {
    console.log("VIN is empty!");
  }
};

export const vdsVINHandler = (dispatch, userEnteredVin) => {
  dispatch(setVinAvailableInOtherEnvOrReg(false));
  dispatch(setVinStagesAndEnvironmentsCheck(null));

  dispatch(setVinStagesAndEnvironments([]));
  dispatch(setVinStagesAndEnvironmentsCheck(false));

  const vinRegex = /^[A-Za-z0-9]{17}$/;
  if (userEnteredVin !== "") {
    if (vinRegex.test(userEnteredVin.toUpperCase())) {
      triggerVDS(userEnteredVin, "ece", "live");
    } else {
      console.log("Invalid VIN!");
    }
  } else {
    console.log("VIN is empty!");
  }
};
