import { createSlice } from "@reduxjs/toolkit";
import { postWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";

const initialState = {
  primaryUserClaims: {},
  isLoadingDataPrimaryUserClaims: false,
  noPrimaryUserFound: false,
};

// Slice
const primaryUserClaimsSlice = createSlice({
  name: "primaryUserClaims",
  initialState,
  reducers: {
    setPrimaryUserClaims: (state, action) => {
      state.primaryUserClaims = action.payload;
    },

    setIsLoadingDataPrimaryUserClaims: (state, action) => {
      state.isLoadingDataPrimaryUserClaims = action.payload;
    },
    setNoPrimaryUserFound: (state, action) => {
      state.noPrimaryUserFound = action.payload;
    },
  },
});

// Selectors

export const selectPrimaryUserClaims = (state) =>
  state.primaryUserClaims.primaryUserClaims;

export const selectIsLoadingDataPrimaryUserClaims = (state) =>
  state.primaryUserClaims.isLoadingDataPrimaryUserClaims;

export const selectNoPrimaryUserFound = (state) =>
  state.primaryUserClaims.noPrimaryUserFound;
// Actions

export const {
  setPrimaryUserClaims,
  setIsLoadingDataPrimaryUserClaims,
  setNoPrimaryUserFound,
} = primaryUserClaimsSlice.actions;

//Getter

export const getUserClaimsPrimaryUser =
  (environment, region, vin) => (dispatch) => {
    dispatch(setIsLoadingDataPrimaryUserClaims(true));
    postWithAuth("GETUSERCLAIMSPRIMARYUSER", {}, { environment, region, vin })
      .then((response) => {
        if (response !== null) {
          dispatch(setPrimaryUserClaims(response));
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        logError(error);
        errorHandler(error, dispatch);
        dispatch(setNoPrimaryUserFound(true));
      })
      .finally(() => {
        dispatch(setIsLoadingDataPrimaryUserClaims(false));
      });
  };

export default primaryUserClaimsSlice.reducer;
