import React, { useState } from "react";
import classes from "./sopViewerTool.module.css";
import HorizontalDevider from "../../components/Horizontal Divider/HorizontalDivider";
import { Box, Switch } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { v4 as uuidv4 } from "uuid";
import { initialRows } from "./mbboauth_v1";

function SopViewerTool() {
  const [rows, setRows] = useState(initialRows);

  const columns = [
    {
      field: "service",
      type: "string",
      headerName: "Service",
      width: 175,
      headerClassName: classes.sopViewwerToolTableHeader,
    },
    {
      field: "sop",
      headerName: "SOP",
      type: "string",
      width: 302,
      editable: true,
      headerClassName: classes.sopViewwerToolTableHeader,
    },
    {
      field: "property",
      headerName: "Property",
      type: "string",
      width: 200,
      editable: true,
      headerClassName: classes.sopViewwerToolTableHeader,
    },
    {
      field: "sopWork",
      headerName: "SOP Work Version",
      type: "string",
      width: 165,
      editable: true,
      headerClassName: classes.sopViewwerToolTableHeader,
    },
    {
      field: "sopDemo",
      headerName: "SOP Work Version",
      type: "string",
      sortable: true,
      width: 165,
      headerClassName: classes.sopViewwerToolTableHeader,
    },
    {
      field: "sopApproval",
      headerName: "SOP Approval Version",
      type: "string",
      sortable: true,
      width: 165,
      headerClassName: classes.sopViewwerToolTableHeader,
    },
    {
      field: "sopLive",
      headerName: "SOP Live Version",
      type: "string",
      sortable: false,
      width: 165,
      headerClassName: classes.sopViewwerToolTableHeader,
    },
  ];

  const handleFilterChange = (filterModel) => {
    if (filterModel.items.length > 0 && filterModel.items[0].value) {
      const filteredRows = initialRows.filter((row) => {
        return Object.keys(row).some((key) =>
          row[key]
            .toLowerCase()
            .includes(filterModel.items[0].value.toLowerCase())
        );
      });
      setRows(filteredRows);
    } else {
      // If no filter value provided, reset to initial rows
      setRows(initialRows);
    }
  };

  return (
    <div className={classes.sopViewerPageContainer}>
      <div className={classes.sopViewerContents}>
        <div className={classes.sopViewerContentsTitle}>SOP Viewer Tool</div>
        <HorizontalDevider />
        <div className={classes.sopViewerTableContainer}>
          <div className={classes.sopViewerTableContainerTabs}>
            {" "}
            {/* Left side */}
            <div className={classes.sopViewerTableContainerTabsLeftSide}>
              <div className={classes.sopViewerTableContainerTabsTabLeft}>
                Dashboard
              </div>
              <div className={classes.sopViewerTableContainerTabsTabLeft}>
                Master Editor
              </div>
              <div
                className={classes.sopViewerTableContainerTabsTabLeftSelected}
              >
                Sops
              </div>
              <div className={classes.sopViewerTableContainerTabsTabLeft}>
                Services
              </div>
            </div>
            {/* space in between */}
            <div className={classes.sopViewerTableContainerTabsSpaceBetween} />
            {/* Right side  */}
            <div className={classes.sopViewerTableContainerTabsRightSide}>
              <div
                className={
                  classes.sopViewerTableContainerTabsTabRightSopSelector
                }
              >
                <div
                  className={
                    classes.sopViewerTableContainerTabsTabRightSopSelectorText
                  }
                >
                  Only Porsche SOPs
                </div>
                <div
                  className={
                    classes.sopViewerTableContainerTabsTabRightSopSelectorSwitch
                  }
                >
                  <Switch defaultChecked size="small" color="error" />
                </div>
              </div>
              <div className={classes.sopViewerTableContainerTabsTabRight}>
                Settings
              </div>
              <div className={classes.sopViewerTableContainerTabsTabRight}>
                Refresh Snapshot Data
              </div>
              <div className={classes.sopViewerTableContainerTabsTabRight}>
                Upload Master Data
              </div>
            </div>
          </div>
          <div className={classes.sopViewerTableContainerTableTabsSops}>
            <div className={classes.sopViewerTableContainerTableTabsSopsTab}>
              SOP View
            </div>
            <div className={classes.sopViewerTableContainerTableTabsSopsTab}>
              SOP Diff
            </div>
            <div
              className={
                classes.sopViewerTableContainerTableTabsSopsTabSelected
              }
            >
              SOP Versions Diff
            </div>
          </div>
          <div className={classes.sopViewerTableContainerTableTabsFilters}>
            <div
              className={classes.sopViewerTableContainerTableTabsFiltersFilter}
            >
              Total Entries: {rows.length}
            </div>
            <div
              className={classes.sopViewerTableContainerTableTabsFiltersFilter}
            >
              Only Diffs: <Switch defaultChecked size="small" color="error" />
            </div>
            <div
              className={
                classes.sopViewerTableContainerTableTabsFiltersFilterExport
              }
            >
              Export
            </div>
          </div>

          {/* ------------------------------------------------ */}
          {/* -------------------MUI TABLE------------------- */}
          {/* ------------------------------------------------ */}
          <Box sx={{ height: 575, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              sx={{ fontFamily: "var(--regular)" }}
              getRowId={() => uuidv4()} // Generate random UUID for each row
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 100,
                  },
                },
              }}
              onFilterModelChange={handleFilterChange}
              pageSizeOptions={[100]}
              //   checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
          {/* ------------------------------------------------ */}
          {/* ---------------END OF MUI TABLE------------------- */}
          {/* ------------------------------------------------ */}
        </div>
      </div>
    </div>
  );
}

export default SopViewerTool;
