import React from "react";
import classes from "./BackButton.module.css";
import back from "../../Assets/Icons/back.png";
import { Link } from "react-router-dom";

function BackButton({ backMessage, destination }) {
  return (
    <div className={classes.backButton}>
      <div className={classes.backArrow}>
        <img src={back} alt="" />
      </div>
      <div className={classes.backMessage} data-cy="back-button">
        <Link to={destination}>{backMessage || ""}</Link>
      </div>
    </div>
  );
}

export default BackButton;
