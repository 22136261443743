import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";
import { postWithAuth, postWithAuthForZipFile } from "../../utils/api";

const initialState = {
  certificateBundles: null,
  vinValueCertificateBundles: "",
  envValueCertificateBundles: "",
  presetEnvValueCertificateBundles: "approval",
  regValueCertificateBundles: "",
  presetRegValueCertificateBundles: "ece",
  registerAndPairCertificateBundles: false,
  isLoadingDataCertificateBundles: false,
  emptyState: "",
};

// Slice
const certificateBundlesSlice = createSlice({
  name: "certificateBundles",
  initialState,
  reducers: {
    setCertificateBundles: (state, action) => {
      state.certificateBundles = action.payload;
    },
    setVinCertificateBundles: (state, action) => {
      state.vinValueCertificateBundles = action.payload;
    },
    setEnvCertificateBundles: (state, action) => {
      state.envValueCertificateBundles = action.payload;
    },
    setRegCertificateBundles: (state, action) => {
      state.regValueCertificateBundles = action.payload;
    },
    setIsLoadingDataCertificateBundles: (state, action) => {
      state.isLoadingDataCertificateBundles = action.payload;
    },
    setEmptyState: (state, action) => {
      state.emptyState = action.payload;
    },
    setRegisterAndPairCertificateBundles: (state, action) => {
      state.registerAndPairCertificateBundles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCertificateBundles.fulfilled, (state, action) => {
      // Add other functionalities & handle state updates upon fulfillment as needed
    });
  },
});

// Selectors

export const selectCertificateBundles = (state) =>
  state.certificateBundles.certificateBundles;
export const selectVinCertificateBundles = (state) =>
  state.certificateBundles.vinValueCertificateBundles;
export const selectEnvCertificateBundles = (state) =>
  state.certificateBundles.envValueCertificateBundles;
export const selectPresetEnvCertificateBundles = (state) =>
  state.certificateBundles.presetEnvValueCertificateBundles;
export const selectRegCertificateBundles = (state) =>
  state.certificateBundles.regValueCertificateBundles;
export const selectPresetRegCertificateBundles = (state) =>
  state.certificateBundles.presetRegValueCertificateBundles;
export const selectIsLoadingDataCertificateBundles = (state) =>
  state.certificateBundles.isLoadingDataCertificateBundles;
export const selectEmptyState = (state) => state.certificateBundles.emptyState;
export const selectRegisterAndPairCertificateBundles = (state) =>
  state.certificateBundles.registerAndPairCertificateBundles;

// Actions

export const {
  setIsLoadingDataCertificateBundles,
  setCertificateBundles,
  setVinCertificateBundles,
  setEnvCertificateBundles,
  setRegCertificateBundles,
  setRegisterAndPairCertificateBundles,
  setEmptyState,
} = certificateBundlesSlice.actions;

//Getter

export const getCertificateBundles = createAsyncThunk(
  "getCertificateBundles/getCertificateBundles",
  async ({ region, vin, registration }, { dispatch }) => {
    dispatch(setIsLoadingDataCertificateBundles(true));
    dispatch(setEmptyState(false));
    try {
      const response = await postWithAuthForZipFile(
        "GETCERTIFICATEBUNDLES",
        {},
        { region, vin, registration }
      );
      if (response !== null) {
        dispatch(setCertificateBundles(response));
        // Create a Blob object from the response data
        const blob = new Blob([response], { type: "application/zip" });

        // Create a temporary anchor element to trigger the download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `DlcBundles_${vin.toUpperCase()}`);
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        URL.revokeObjectURL(url);
      } else {
        console.log(response);
      }
    } catch (error) {
      logError(error);
      errorHandler(error, dispatch);
    } finally {
      dispatch(setIsLoadingDataCertificateBundles(false));
    }
  }
);
export default certificateBundlesSlice.reducer;
