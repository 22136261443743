import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  executionId: "",
};

// Slice

const progressSlice = createSlice({
  name: "progress",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setExecutionId: (state, action) => {
      state.executionId = action.payload;
    },
  },
});

// Selectors

export const selectExecutionId = (state) => state.progress.executionId;
export const selectIsLoading = (state) => state.progress.isLoading;

// Actions

export const { setIsLoading, setExecutionId } = progressSlice.actions;

//Getter

// export const startScenario =
//   (scenarioTypeParam, testDataReferenceParam) => (dispatch) => {
//     dispatch(setIsLoading(true));
//     postWithAuth(
//       "STARTTESTSCENARIO",
//       {},
//       {
//         scenarioType: scenarioTypeParam,
//         testDataReference: testDataReferenceParam,
//       }
//     )
//       .then((response) => {
//         if (response !== null) {
//           dispatch(setExecutionId(response.executionId));
//           console.log(response);
//         } else {
//           console.log(response);
//         }
//       })
//       .catch((error) => {
//         logError(error);
//         errorHandler(error, dispatch);
//       })
//       .finally(dispatch(setIsLoading(false)));
//   };

export default progressSlice.reducer;
