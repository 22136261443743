import { createSlice } from "@reduxjs/toolkit";
import { postWithAuth, getWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";
import { setOpen, setSnackBar } from "../../components/Snackbar/snackbarSlice";

const initialState = {
  isLoading: false,
  testResults: {},
  resultsArray: [],
  status: "Pending...",
  lastResultAvailable: false,
};

// Slice

const resultsSlice = createSlice({
  name: "results",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setTestResults: (state, action) => {
      state.testResults = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setResultsArray: (state, action) => {
      state.resultsArray = action.payload;
    },
    setLastResultAvailable: (state, action) => {
      state.lastResultAvailable = action.payload;
    },
  },
});

// Selectors

export const selectIsLoading = (state) => state.results.isLoading;
export const selectTestResults = (state) => state.results.testResults;
export const selectStatus = (state) => state.results.status;
export const selectResultsArray = (state) => state.results.resultsArray;
export const selectLastResultAvailable = (state) =>
  state.results.lastResultAvailable;

// Actions

export const {
  setIsLoading,
  setTestResults,
  setStatus,
  setResultsArray,
  setLastResultAvailable,
} = resultsSlice.actions;

//Getter

export const getSessionCache = (executionId) => {
  try {
    const cachedResult = sessionStorage.getItem(`testResult_${executionId}`);
    if (cachedResult) {
      return JSON.parse(cachedResult);
    }
    return null;
  } catch (error) {
    console.error("Error getting cached result:", error);
    return null;
  }
};

export const deleteSessionCache = (executionId) => {
  try {
    sessionStorage.removeItem(`testResult_${executionId}`);
  } catch (error) {
    console.error("Error deleting cached result:", error);
  }
};

export const setSessionCache = (executionId, result) => {
  try {
    const key = `testResult_${executionId}`;
    const serializedResult = JSON.stringify(result);
    sessionStorage.setItem(key, serializedResult);
  } catch (error) {
    console.error("Error setting cached result:", error);
  }
};

// export const getTestResults = (executionId) => (dispatch) => {
//   dispatch(setIsLoading(false));
//   postWithAuth("GETSCENARIOEXECUTIONRESULT", {}, { executionId })
//     .then((response) => {
//       if (response !== null) {
//         dispatch(setTestResults(response));
//         dispatch(
//           setResultsArray(
//             response.testResults.map((result) => result.ResultStatus)
//           )
//         );
//         console.log(response.testResults.map((result) => result.ResultStatus));
//         dispatch(setStatus("Success"));
//         console.log(response);
//       } else {
//         console.log(response);
//       }
//     })
//     .catch((error) => {
//       logError(error);
//       errorHandler(error, dispatch);
//     })
//     .finally(() => {
//       dispatch(setIsLoading(false));
//     });
// };

////////// WITH POLLING MECHANISM

export const getTestResults =
  (scenarioTypeParam, testDataReferenceParam) => (dispatch) => {
    let responseReceived = false;
    dispatch(setIsLoading(false));
    postWithAuth(
      "STARTTESTSCENARIO",
      {},
      {
        scenarioType: scenarioTypeParam,
        testDataReference: testDataReferenceParam,
      }
    )
      .then((response) => {
        if (response !== null) {
          //  Inserted second call after getting the right executionId and checking
          const executionId = response.executionId;
          let testDataIsLocked = false;
          const poll = setInterval(() => {
            //   Check if testData is blocked before running the request
            getWithAuth("BLOCKLIST", {})
              .then((response) => {
                console.log(response);
                if (
                  JSON.stringify(response).includes(`${testDataReferenceParam}`)
                ) {
                  testDataIsLocked = true;
                } else {
                  testDataIsLocked = false;
                }
              })
              .catch((error) => {
                logError(error);
                errorHandler(error, dispatch);
              })
              .then(
                ///////////////// If testData is not locked:
                postWithAuth("GETSCENARIOEXECUTIONRESULT", {}, { executionId })
                  .then((response) => {
                    if (
                      JSON.stringify(response).includes("executionTimestamp") &&
                      executionId !== "" &&
                      !testDataIsLocked
                    ) {
                      dispatch(setIsLoading(false));
                      clearInterval(poll);
                      responseReceived = true;
                      dispatch(setTestResults(response));
                      dispatch(setLastResultAvailable(true));
                      dispatch(
                        setResultsArray(
                          response.testResults.map(
                            (result) => result.ResultStatus
                          )
                        )
                      );
                      dispatch(setStatus("Success"));
                    } else {
                      dispatch(setStatus("Pending"));
                    }
                  })
                  .catch((error) => {
                    logError(error);
                    dispatch(setStatus("Pending..."));
                    errorHandler(error, dispatch);
                  })
              );
          }, 1000);
          setTimeout(() => {
            if (!responseReceived) {
              clearInterval(poll);
              dispatch(setIsLoading(false));
              dispatch(setOpen(true));
              dispatch(setStatus("Failed"));
              dispatch(
                setSnackBar({
                  errorCode: "Timeout ",
                  errorMessage:
                    "Results not received after 30 seconds If issue persists, please contact helpdesk. ",
                })
              );
              throw new Error("Timeout: Results not received after 30 seconds");
            }
          }, 60000);
          //////////
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        logError(error);
        errorHandler(error, dispatch);
      })
      .finally(dispatch(setIsLoading(false)));
  };

export default resultsSlice.reducer;
