import React from "react";

import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { selectTestResults } from "../../pages/Results Report Page/resultsSlice";

function ReportChart() {
  const testResults = useSelector(selectTestResults);

  function getUniqueServiceIds() {
    const uniqueServiceIds = Array.from(
      new Set(testResults.testResults.map((test) => test.ServiceId))
    );
    return uniqueServiceIds;
  }

  const uniqueServiceIds = getUniqueServiceIds();

  const chartOptions = {
    chart: {
      toolbar: { show: false },
      id: "basic-bar",
      stacked: true,
      stackType: "100%",
    },
    fill: {
      colors: ["#01BA1D", "#96989A", "#D5001C"],
    },
    xaxis: {
      categories: [...uniqueServiceIds],
    },
  };

  function generateChartSeries() {
    const failedArr = [];
    const successArr = [];
    const blockedArr = [];
    const resultArr = [];

    uniqueServiceIds.forEach((currentServiceId) => {
      const filteredTestResults = testResults.testResults.filter(
        (x) => x.ServiceId === currentServiceId
      );
      const failed = filteredTestResults.filter(
        (x) => x.ResultStatus === "failed"
      ).length;
      const success = filteredTestResults.filter(
        (x) => x.ResultStatus === "success"
      ).length;
      const blocked = filteredTestResults.filter(
        (x) => x.ResultStatus === "blocked"
      ).length;

      failedArr.push(failed);
      blockedArr.push(blocked);
      successArr.push(success);
    });
    // console.log(failedArr)

    resultArr.push({
      name: "failed",
      data: failedArr,
      color: getColorForStatus("failed"),
    });
    resultArr.push({
      name: "blocked",
      data: blockedArr,
      color: getColorForStatus("blocked"),
    });
    resultArr.push({
      name: "success",
      data: successArr,
      color: getColorForStatus("success"),
    });

    return resultArr;
  }

  function getColorForStatus(status) {
    switch (status) {
      case "success":
        return "#01BA1D";
      case "blocked":
        return "#96989A";
      case "failed":
        return "#D5001C";
      default:
        return "#000000"; // Default color if status is unrecognized
    }
  }

  const chartSeries = generateChartSeries();

  return (
    <div>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        width="500"
      />
    </div>
  );
}

export default ReportChart;
