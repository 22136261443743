import React from "react";
import classes from "./ResultsReportPage.module.css";
import ReportChart from "../../components/Chart/ReportChart";
import Accordion from "../../components/Accordion/Accordion";
import { useSelector } from "react-redux";
import { selectSelectedModelId } from "../Homepage/projectSlice";
import InfoSnackbar from "../../components/InfoSnackbar/infoSnackbar";
import HorizontalDivider from "../../components/Horizontal Divider/HorizontalDivider";
import { selectTestResults } from "./resultsSlice";
import RedirectPage from "../redirectPage/RedirectPage";
import BackButton from "../../ui/Buttons/Back Button/BackButton";

function ResultsReportPage() {
  const testResults = useSelector(selectTestResults);
  const id = useSelector(selectSelectedModelId);

  function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  return (
    <div className={classes.gridContainer}>
      <div className={classes.flexContainerHeader}>
        <div className={classes.flexContainerRow}> </div>
        <div className={classes.flexContainerRow}> </div>

        <div className={classes.flexContainerRow}> </div>
        <div className={classes.flexContainerRowTitle}> Scenario summary</div>
        <div className={classes.flexContainerRow}> </div>
        <div className={classes.flexContainerRow}> </div>
        <div className={classes.flexContainerRow}> </div>
        <HorizontalDivider />
        <div className={classes.flexContainerRow} style={{ height: "2rem" }}>
          <BackButton
            backMessage={"Back to device vehicle selection"}
            destination={"/homepage"}
          />{" "}
        </div>
        <div className={classes.flexContainerRow} style={{ height: "1rem" }}>
          {" "}
          <BackButton
            backMessage={"Back to test scenario selection"}
            destination={`/data-and-info/${id}`}
          />
        </div>

        <div className={classes.gridItemRow}>
          <div
            style={{
              width: "45%",
              marginLeft: "5%",
              height: "47.5vh",
              marginTop: "10rem",
            }}
          >
            {isObjectEmpty(testResults) || testResults === undefined ? (
              <RedirectPage />
            ) : (
              // ""
              <ReportChart />
            )}
          </div>
          <div
            style={{
              width: "45%",
              marginLeft: "5%",
              marginRight: "5%",
              minHeight: "47.5vh",
              border: "1px solid var(--dark-gray)",
            }}
          >
            {isObjectEmpty(testResults) || testResults === undefined ? (
              <RedirectPage />
            ) : (
              // ""
              <Accordion />
            )}
          </div>
        </div>

        <div className={classes.flexContainerRow}> </div>
        <div className={classes.actionSection}>
          {/* <div className={classes.exportBtn}>
            <FullButton buttonText={"Export results"} destination={"/"} />
          </div> */}
          {/* <div className={classes.newBtn}>
            <OutlineButton
              buttonText={"New testset"}
              destination={`/data-and-info/${id}`}
              type={next}
            />
          </div>
          <div className={classes.newTestBtn}>
            <OutlineButton
              buttonText={"New test data"}
              destination={"/homepage"}
              type={next}
            />
          </div> */}
        </div>
        <div className={classes.flexContainerRow}> </div>
        <div className={classes.flexContainerRow}> </div>
        <div className={classes.flexContainerRow}> </div>
        <div className={classes.flexContainerRow}> </div>
        <div className={classes.flexContainerRow}> </div>
        <div className={classes.infoSnackBar}>
          <InfoSnackbar />
        </div>
        <div className={classes.flexContainerRow}> </div>
      </div>
    </div>
  );
}

export default ResultsReportPage;
