import React from "react";
import classes from "../ProjectList.module.css";
import macan from "../../../images/Macan.png";

function ProjectItem({
  masterdata,
  releaseVersion,
  referenceId,
  loadProjectDetails,
  selected,
  setSelected,
  sop,
}) {
  const projectClass = selected
    ? classes.flexItemRowSelected
    : classes.flexItemRow;

  return (
    <div
      className={projectClass}
      onClick={() => {
        loadProjectDetails(referenceId);
        setSelected(referenceId + sop);
      }}
    >
      <div
        className={classes.flexItemDetail}
        style={{ maxWidth: "7.5%", textAlign: "center" }}
      >
        <img src={macan} alt="" style={{ width: "100%" }} />
      </div>
      <div
        className={classes.flexItemDetail}
        style={{ maxWidth: "13%", textAlign: "center" }}
      >
        <p>{masterdata.modelRange}</p>
      </div>
      <div
        className={classes.flexItemDetail}
        style={{
          maxWidth: "45%",
          textAlign: "center",
          fontSize: "0.75rem",
        }}
      >
        <p>{sop}</p>
      </div>
      <div
        className={classes.flexItemDetail}
        style={{
          maxWidth: "5%",
          textAlign: "center",
        }}
      >
        <p>{releaseVersion}</p>
      </div>
      <div
        className={classes.flexItemDetail}
        style={{ maxWidth: "10%", textAlign: "center" }}
      >
        <p>{masterdata.modelYear}</p>
      </div>
      <div
        className={classes.flexItemDetail}
        style={{ maxWidth: "10%", textAlign: "center" }}
      >
        <p>{sop.includes("HCP3") ? "HCP3" : "HCP5"}</p>
      </div>
    </div>
  );
}

export default ProjectItem;
