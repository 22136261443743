import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedModel } from "../../pages/Homepage/projectSlice";
import classes from "./ProjectDetails.module.css";

function ProjectDetails() {
  const model = useSelector(selectSelectedModel);


  return (
    <div className={classes.gridContainer} cy-data="details-container">
      <div className={classes.flexContainerHeader} cy-data="details-project-title">
        <div className={classes.flexItemHeader}>Project Info</div>
      </div>
      <div className={classes.gridItemRow}>
        <div className={classes.flexContainerRow} cy-data="details-environment">
          <div className={classes.flexItemRowLeft} cy-data="details-environment-left">Environment:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight} cy-data="details-environment-right">{model.environment}</div>}
        </div>

        <div className={classes.flexContainerRow}>
          <div className={classes.flexItemRowLeft}>Region:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.region}</div>}
        </div>

        <div className={classes.flexContainerRow}>
          <div className={classes.flexItemRowLeft}>Comment:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.comment}</div>}

        </div>

        <div className={classes.flexContainerRow}>
          <div className={classes.flexItemRowLeft}>Release Version:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.releaseVersion}</div>}
        </div>

        <div className={classes.flexContainerRow}>
          <div className={classes.flexItemRowLeft}>Primary MBB User:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.primaryMbbUser}</div>}
        </div>

        <div className={classes.flexContainerRow}>
          <div className={classes.flexItemRowLeft}>Secondary MBB User:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.secondaryMbbUser}</div>}
        </div>

        <div className={classes.flexContainerRow}>
          <div className={classes.flexItemRowLeft}>Primary Portal User:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.primaryPortalUser}</div>}
        </div>

        <div className={classes.flexContainerRow}>
          <div className={classes.flexItemRowLeft}>Secondary Portal User:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.secondaryPortalUser}</div>}
        </div>
      </div>
      <div className={classes.flexContainerHeader}>
        <div className={classes.flexItemHeader}>Metadata</div>
      </div>
      <div className={classes.gridItemRow}>
        <div className={classes.flexContainerRow}>
          <div className={classes.flexItemRowLeft}>Owner:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.metadata.owner}</div>}
        </div>

        <div className={classes.flexContainerRow}>
          <div className={classes.flexItemRowLeft}>Access:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.metadata.access}</div>}
        </div>

        <div className={classes.flexContainerRow}>
          <div className={classes.flexItemRowLeft}>EOL:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.metadata.eol}</div>}
        </div>

        <div className={classes.flexContainerRow}>
          <div className={classes.flexItemRowLeft}>F PIN Provider:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.metadata.fPinProvider}</div>}
        </div>

        <div className={classes.flexContainerRow}>
          <div className={classes.flexItemRowLeft}>Homeregion:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.metadata.homeRegion}</div>}
        </div>

        <div className={classes.flexContainerRow}>
          <div className={classes.flexItemRowLeft}>VIN:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.metadata.vin}</div>}
        </div>

        <div className={classes.flexContainerRow}>
          <div className={classes.flexItemRowLeft}>Master Data Provider:</div>
          {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.metadata.masterdataProvider}</div>}
        </div>
        <div className={classes.flexContainerHeader}>
          <div className={classes.flexItemHeader}>Masterdata</div>
        </div>
        <div className={classes.gridItemRow}>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Country:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.country}</div>}
          </div>

          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Engine Code:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.engineCode}</div>}
          </div>

          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>MMI:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.mmi}</div>}
          </div>

          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Color Finish:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.colorFinish}</div>}
          </div>

          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Color Interior:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.colorInterior}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>PNR List:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.pnrList}</div>}
          </div>



          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Model Year:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.modelYear}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Model Range:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.modelRange}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Model Description:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.modelDescription}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Production Date:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.productionDate}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Fuel Type:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.fuelType}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Model Code:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.modelCode}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Mapping VIN:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.mappingVIN}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Color Top:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.colorTop}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Color Paint:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.colorPaint}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>VIN:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.vin}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Country of Market:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.countryOfMarket}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Engine Code Extension:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.engineCodeExtension}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Delivery Date:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.deliveryDate}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Brand:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.brand}</div>}
          </div>
          <div className={classes.flexContainerRow}>
            <div className={classes.flexItemRowLeft}>Country of Registration:</div>
            {model === undefined ? <div className={classes.flexItemRowRight} /> : <div className={classes.flexItemRowRight}>{model.masterdata.countryOfRegistration}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetails;
