import { createSlice } from "@reduxjs/toolkit";
import { postWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";

const initialState = {
  connectionStatus: {},
  connectionPingTimestamp: "",
  pingData: [],
  pingTimeStamp: [],
  accessForbidden: false,
  isLoadingDataConnectionStatusCache: [],
};

// Slice
const connectionStatusCacheSlice = createSlice({
  name: "connectionStatusCache",
  initialState,
  reducers: {
    setConnectionStatus: (state, action) => {
      state.connectionStatus = action.payload;
    },
    setPingData: (state, action) => {
      state.pingData = [...state.pingData, action.payload];
    },
    setPingTimestamp: (state, action) => {
      state.pingTimeStamp = [...state.pingTimeStamp, action.payload];
    },
    setConnectionPingTimestamp: (state, action) => {
      state.connectionPingTimestamp = action.payload;
    },
    clearPingData: (state) => {
      state.pingData = [];
    },
    clearPingTimeStamp: (state) => {
      state.pingTimeStamp = [];
    },
    setAccessForbidden: (state, action) => {
      state.accessForbidden = action.payload;
    },

    setIsLoadingDataConnectionStatusCache: (state, action) => {
      state.isLoadingDataConnectionStatusCache = action.payload;
    },
  },
});

// Selectors

export const selectConnectionStatus = (state) =>
  state.connectionStatusCache.connectionStatus;
export const selectPingData = (state) => state.connectionStatusCache.pingData;
export const selectPingTimestamp = (state) =>
  state.connectionStatusCache.pingTimeStamp;
export const selectConnectionPingTimestamp = (state) =>
  state.connectionStatusCache.connectionPingTimestamp;
export const selectAccessForbidden = (state) =>
  state.connectionStatusCache.accessForbidden;
export const selectIsLoadingDataConnectionStatusCache = (state) =>
  state.connectionStatusCache.isLoadingDataConnectionStatusCache;
// Actions

export const {
  setConnectionStatus,
  setPingData,
  setPingTimestamp,
  setConnectionPingTimestamp,
  clearPingData,
  clearPingTimeStamp,
  setAccessForbidden,
  setIsLoadingDataConnectionStatusCache,
} = connectionStatusCacheSlice.actions;

//Getter

export const getConnectionStatus = (environment, region, vin) => (dispatch) => {
  dispatch(setIsLoadingDataConnectionStatusCache(true));
  postWithAuth("GETCONNECTIONSTATUS", {}, { environment, region, vin })
    .then((response) => {
      if (response !== null) {
        const endTime = new Date();
        const hours = String(endTime.getHours()).padStart(2, "0");
        const minutes = String(endTime.getMinutes()).padStart(2, "0");
        const seconds = String(endTime.getSeconds()).padStart(2, "0");
        let endTimestamp = `${hours}:${minutes}:${seconds}`;
        dispatch(setConnectionStatus(response));
        dispatch(setConnectionPingTimestamp(endTimestamp));
        dispatch(setPingData(response));
        dispatch(setPingTimestamp(endTimestamp));
      } else {
        console.log(response);
      }
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
      if (error.code === "ERR_BAD_REQUEST") {
        dispatch(setAccessForbidden(true));
      }
    })
    .finally(() => {
      dispatch(setIsLoadingDataConnectionStatusCache(false));
    });
};

// // with Timeout in it
// export const getConnectionStatus = (environment, region, vin) => (dispatch) => {
//   dispatch(setIsLoadingDataConnectionStatusCache(true));

//   // Helper function to send request and update state
//   const sendRequestAndUpdateState = () => {
//     postWithAuth("GETCONNECTIONSTATUS", {}, { environment, region, vin })
//       .then((response) => {
//         if (response !== null) {
//           const endTime = new Date();
//           const hours = String(endTime.getHours()).padStart(2, "0");
//           const minutes = String(endTime.getMinutes()).padStart(2, "0");
//           const seconds = String(endTime.getSeconds()).padStart(2, "0");
//           let endTimestamp = `${hours}:${minutes}:${seconds}`;

//           // Update state with the relevant outcome
//           dispatch(setPingData(response));
//           dispatch(setPingTimestamp(endTimestamp));
//         } else {
//           console.log(response);
//         }
//       })
//       .catch((error) => {
//         logError(error);
//         errorHandler(error, dispatch);
//         if (error.code === "ERR_BAD_REQUEST") {
//           dispatch(setAccessForbidden(true));
//         }
//       })
//       .finally(() => {
//         dispatch(setIsLoadingDataConnectionStatusCache(false));
//       });
//   };

//   // Send the request three times in sequence
//   sendRequestAndUpdateState(); // First call
//   setTimeout(() => sendRequestAndUpdateState(), 15000); // Second call after 15 second
//   setTimeout(() => sendRequestAndUpdateState(), 30000); // Third call after 30 seconds
// };

export default connectionStatusCacheSlice.reducer;
