import React, { useEffect, useRef, useState } from "react";
import classes from "./pseudonymizer.module.css";
import Papa from "papaparse";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import info from "../../ui/Assets/Icons/infoNoBorder.png";

import {
  clearPseudoVINFromVINList,
  clearPseudoVINListWithVINS,
  clearPseudoVinList,
  getpVINFromVIN,
  selectAudiencePseudonymizer,
  selectEnvironmentPseudonymizer,
  selectPseudoVINFromVINList,
  selectRegiontPseudonymizer,
  selectVinListForCSV,
  setAudiencePseudonymizer,
  setEnvironmentPseudonymizer,
  setRegionPseudonymizer,
  setVinListForCSV,
} from "./pseudonymizerSlice";
import HorizontalDevider from "#components/Horizontal Divider/HorizontalDivider.jsx";

function UploadCSV() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDownloadAvailable, setIsDownloadAvailable] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [correspondingVIN, setCorrespondingVIN] = useState([]);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [isDownloadInProgress, setIsDownloadInProgress] = useState(false);
  const [maxEntriesrrorMessage, setMaxEntriesErrorMessage] = useState("");

  const dispatch = useDispatch();

  const audience = useSelector(selectAudiencePseudonymizer);

  const envPseudonymizer = useSelector(selectEnvironmentPseudonymizer);
  const regPseudonymizer = useSelector(selectRegiontPseudonymizer);

  const vinListForCsv = useSelector(selectVinListForCSV);

  const pseudoVinsFromVINList = useSelector(selectPseudoVINFromVINList);

  useEffect(() => {
    if (
      pseudoVinsFromVINList.length > 0 &&
      !isDownloadInProgress &&
      isDownloadAvailable &&
      selectedFile !== null
    ) {
      createCSVToDownload(pseudoVinsFromVINList);
    }
  }, [
    pseudoVinsFromVINList,
    isDownloadInProgress,
    isDownloadAvailable,
    maxEntriesrrorMessage,
  ]);

  const handleFileChange = (event) => {
    setMaxEntriesErrorMessage("");
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setIsDownloadAvailable(false);
      setFileName(file.name);
      dispatch(clearPseudoVinList());
      dispatch(clearPseudoVINFromVINList());
      setCorrespondingVIN([]);

      Papa.parse(file, {
        complete: handleParseComplete,
        header: true, // There are headers now in the CSV file
        transformHeader: (header) => (header.trim() === "VIN" ? "VIN" : header),
      });
    } else {
      console.log("No file selected");
    }
  };

  const handleParseComplete = (result) => {
    const vinList = result.data.map((row) => row.VIN); // Access VINs using the header name
    if (vinList.length > 1000) {
      setMaxEntriesErrorMessage("Too many VINs. Maximum accepted: 1000");
      return;
    }
    setIsDownloadAvailable(true);
    // Here I set the list of VINs to be sent to BE
    dispatch(setVinListForCSV(vinList));
  };

  const createCSVToDownload = (responseMap) => {
    // Prepare CSV data with headers
    let csvData = "VIN,PseudoVIN\n";

    // Iterate through the responseMap array
    responseMap.forEach(({ Vin, PVin }) => {
      // Append each VIN and PseudoVIN pair to the CSV data
      csvData += `${Vin},${PVin}\n`;
    });

    // Get current date and time
    const now = new Date();
    const timestamp =
      now.getDate().toString().padStart(2, "0") +
      "/" +
      (now.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      now.getFullYear() +
      "_" +
      now.getHours().toString().padStart(2, "0") +
      "." +
      now.getMinutes().toString().padStart(2, "0") +
      "." +
      now.getSeconds().toString().padStart(2, "0");

    // Create Blob object from the CSV data
    const blob = new Blob([csvData], { type: "text/csv" });

    // Create URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element and set download attribute
    const link = document.createElement("a");
    link.href = url;
    link.download = "pseudoVINs_" + timestamp + ".csv";

    // Simulate click on the link to trigger download
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
    // setFileName("");
    setMaxEntriesErrorMessage("");
  };

  // For all requests at once
  const sendRequests = async (vinList) => {
    setIsDownloadInProgress(true);
    try {
      const requestPayload = vinList.map((vin) => ({
        environment: envPseudonymizer,
        region: regPseudonymizer,
        vin: vin,
        pVin: "",
        mbbUserId: "",
        pUser: "",
        audience: audience,
      }));

      const response = await dispatch(
        getpVINFromVIN({ payload: requestPayload })
      ).unwrap();

      const vinListWithResponses = response.map((item) => ({
        vin: item.Vin,
        pseudoVIN: item.PVin,
      }));

      setCorrespondingVIN(vinListWithResponses.map((item) => item.vin));
      setIsDownloadInProgress(false);
    } catch (error) {
      console.error("Error sending requests:", error);
      setIsDownloadInProgress(false);
    }
  };

  const downloadHandler = () => {
    if (maxEntriesrrorMessage === "") {
      dispatch(clearPseudoVinList());
      dispatch(clearPseudoVINListWithVINS());
      // Send requests to the backend and wait for the best
      sendRequests(vinListForCsv);
    }
  };

  const downloadAvailable =
    selectedFile !== null && maxEntriesrrorMessage === ""
      ? classes.pseudonymizerDownloadContentsActionSectionSelectButton
      : classes.pseudonymizerDownloadContentsActionSectionSelectButtonDisabled;

  return (
    <div className={classes.pseudonymizerUploadContentsInputFields}>
      <div className={classes.pseudonymizerUploadContentsActionSection}>
        {/*  */}
        <div className={classes.pseudonymizerUploadContentsActionSectionInfo}>
          Select Region, Environment & Audience
        </div>
        {/*  */}
        <div className={classes.regionSelectionRow}>
          <div className={classes.regionSection}>
            <FormControl>
              <FormLabel id="reg-radio-buttons-group-label">Region</FormLabel>
              <RadioGroup
                aria-labelledby="reg-radio-buttons-group-label"
                value={regPseudonymizer}
                onChange={(event) => {
                  dispatch(setRegionPseudonymizer(event.target.value));
                }}
                name="radio-buttons-group"
                row
              >
                <FormControlLabel
                  value="ece"
                  control={<Radio size="small" />}
                  label="ECE"
                />
                <FormControlLabel
                  value="nar"
                  control={<Radio size="small" />}
                  label="NAR"
                />
                <FormControlLabel
                  value="cn"
                  control={<Radio size="small" />}
                  label="CN"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className={classes.environmentSection}>
            <FormControl>
              <FormLabel id="env-radio-buttons-group-label">
                Environment
              </FormLabel>
              <RadioGroup
                aria-labelledby="env-radio-buttons-group-label"
                value={envPseudonymizer}
                onChange={(event) => {
                  dispatch(setEnvironmentPseudonymizer(event.target.value));
                }}
                name="radio-buttons-group"
                row
              >
                <FormControlLabel
                  value="demo"
                  disabled
                  control={<Radio size="small" />}
                  label="Demo"
                />
                <FormControlLabel
                  value="approval"
                  control={<Radio size="small" />}
                  label="Approval"
                />
                <FormControlLabel
                  value="live"
                  control={<Radio size="small" />}
                  label="Live"
                  disabled={regPseudonymizer === "cn" ? true : false}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        {/*  */}
        <div className={classes.horizontalDividerContainerUpload}>
          <HorizontalDevider />
        </div>
        {/* AUDIENCE related section */}
        <div className={classes.audienceInputSectionUpload}>
          <div
            className={classes.audienceInputSectionTextUpload}
            style={{ userSelect: "text", cursor: "auto" }}
          >
            {" "}
            Audience:{" "}
          </div>
          {/*  */}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RadioGroup
              aria-labelledby="audience-radio-buttons-group-label"
              value={audience || ""}
              onChange={(event) => {
                dispatch(setAudiencePseudonymizer(event.target.value));
                // setInvalidAudienceText(false);
              }}
              name="radio-buttons-group"
              row
            >
              <FormControlLabel
                value="t1-v_p:odc|porsche|default|vehicle"
                // disabled
                control={<Radio size="small" />}
                label="odc"
              />
              <FormControlLabel
                value="t1-v_p:here_sparta|porsche|default|vehicle"
                control={<Radio size="small" />}
                label="here_sparta"
              />
              <FormControlLabel
                value="t1-v_p:onlinespeech|porsche|default|vehicle"
                control={<Radio size="small" />}
                label="onlinespeech"
              />
            </RadioGroup>
          </div>
          <div className={classes.actionBtnSearchInvisibleUpload}></div>
        </div>
        {/* End of AUDIENCE section */}
        {/*  */}
        <div className={classes.horizontalDividerContainerUpload}>
          <HorizontalDevider />
        </div>
        {/*  */}
        <div className={classes.pseudonymizerUploadContentsActionSectionInfo2}>
          Upload CSV file with VIN list
        </div>
        {/*  */}
        <div className={classes.pseudonymizerUploadContentsActionSectionSelect}>
          <input
            type="file"
            accept=".csv"
            style={{ display: "none" }}
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <div
            className={
              classes.pseudonymizerUploadContentsActionSectionSelectButton
            }
            onClick={() => fileInputRef.current.click()}
          >
            Upload CSV
          </div>
          <Tooltip
            title={
              <Typography fontSize={15}>
                The uploaded CSV should have only 1 column with 'VIN' header and
                up to 1000 rows containing VINs
              </Typography>
            }
          >
            <img
              src={info}
              alt=""
              style={{ width: "1.25rem", height: "1.25rem" }}
            />
          </Tooltip>
          <div className={downloadAvailable} onClick={downloadHandler}>
            Get CSV with pseudoVIN(s){" "}
          </div>
        </div>
        {fileName !== "" ? (
          <div className={classes.fileNameBox}>File selected: {fileName}</div>
        ) : (
          <div></div>
        )}
      </div>
      {/* Info on upload/download state */}
      {isDownloadInProgress ? (
        <div className={classes.uploadIsLoadingBox}>
          <div className={classes.spinnerBck}>
            <div className={classes.spinner}></div>
          </div>{" "}
          <div className={classes.uploadIsLoadingBoxText}>
            Please wait while we fetch pseudo VIN list...
          </div>
        </div>
      ) : (
        ""
      )}
      {maxEntriesrrorMessage !== "" ? (
        <div className={classes.tooManyEntriesErrorMessage}>
          {maxEntriesrrorMessage}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default UploadCSV;
