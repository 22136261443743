// Check the format of the params
export const checkEnvironmentParams = (paramsEnvironment) => {
  if (
    paramsEnvironment === "demo" ||
    paramsEnvironment === "live" ||
    paramsEnvironment === "approval"
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkRegionParams = (paramsRegion) => {
  if (
    paramsRegion === "ece" ||
    paramsRegion === "nar" ||
    paramsRegion === "cn"
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkVinParams = (paramsVin) => {
  const vinRegex = /^[A-Za-z0-9]{17}$/;
  if (vinRegex.test(paramsVin)) {
    return true;
  } else {
    return false;
  }
};

export const checkUuidParams = (paramsUuid) => {
  const uuidRegex = /^[A-Za-z0-9]{32}$/;
  if (uuidRegex.test(paramsUuid)) {
    return true;
  } else {
    return false;
  }
};
