import React, { useEffect } from "react";
import classes from "./themesong.module.css";
import { selectPlayMusic, setPlayMusic } from "./themesongSlice";
import { useDispatch, useSelector } from "react-redux";
import soundOnDark from "../../ui/Assets/Icons/volumeDark.png";
import soundOffDark from "../../ui/Assets/Icons/muteDark.png";
import soundOnLight from "../../ui/Assets/Icons/volumeLight.png";
import soundOffLight from "../../ui/Assets/Icons/muteLight.png";

function Themesong({ theme }) {
  const playMusic = useSelector(selectPlayMusic);
  const dispatch = useDispatch();
  const toggleSpeakerphone = () => {
    dispatch(setPlayMusic(!playMusic));
  };

  // useEffect(() => {
  //   console.log("shoud muzic play ? __ " + playMusic);
  // }, [playMusic]);

  return (
    <div className={classes.themesongButtonContainer}>
      <div className={classes.themesongButtonBtn} onClick={toggleSpeakerphone}>
        {playMusic ? (
          <img src={theme === "light" ? soundOnLight : soundOnDark} alt="" />
        ) : (
          <img src={theme === "light" ? soundOffLight : soundOffDark} alt="" />
        )}
      </div>
    </div>
  );
}

export default Themesong;
