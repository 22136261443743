import { EventType, PublicClientApplication, LogLevel } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

export const msalConfig = {
  auth: {
    authority:
      "https://login.microsoftonline.com/" + process.env.REACT_APP_TENANT_ID,
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    redirectUri:
      window.location.href,
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      }
    }
  },
};

export const pca = new PublicClientApplication(msalConfig);

const accounts = pca.getAllAccounts();
if (accounts.length > 0) pca.setActiveAccount(accounts[0]);

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    pca.setActiveAccount(account);
  }
});

pca.addEventCallback((event) => {
  if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    pca.setActiveAccount(account);
  }
});

export const authRequest = {
  scopes: ["api://" + process.env.REACT_APP_API_CLIENT_ID + "/user_impersonation",],
};


export const rollen = {
  everyone: "everyone",
};

export function isAuthorized(rolle) {
  if (rolle.includes(rollen.everyone)) { return true; }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const userRoles = account?.idTokenClaims?.roles;

  if (userRoles) {
    return rolle.filter((element) => userRoles.includes(element)).length > 0;
  }
  else {
    return false;
  }

}