import React, { useEffect, useState } from "react";
import classes from "./pseudonymizer.module.css";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import {
  setEnvironmentPseudonymizer,
  setRegionPseudonymizer,
  setVinPseudonymizer,
  selectEnvironmentPseudonymizer,
  selectRegiontPseudonymizer,
  selectVinPseudonymizer,
  selectAudiencePseudonymizer,
  selectPVinPseudonymizer,
  selectMbbUserIdPseudonymizer,
  selectPUserPseudonymizer,
  selectResultContent,
  clearResultContent,
  selectErrorDisplay,
} from "./pseudonymizerSlice";
import { useDispatch, useSelector } from "react-redux";
import HorizontalDevider from "#components/Horizontal Divider/HorizontalDivider.jsx";
import VinToPseudoVinInput from "./VinToPseudoVinInput";
import PseudoVinToVinInput from "./PseudoVinToVinInput";
import UserToPseudoUser from "./UserToPseudoUser";
import PseudoUserToUser from "./PseudoUserToUser";
import SingleResult from "./SingleResult.jsx";

function ManualInput() {
  const dispatch = useDispatch();

  const envPseudonymizer = useSelector(selectEnvironmentPseudonymizer);
  const regPseudonymizer = useSelector(selectRegiontPseudonymizer);

  const vinPseudonymizer = useSelector(selectVinPseudonymizer);
  const audience = useSelector(selectAudiencePseudonymizer);
  const pVin = useSelector(selectPVinPseudonymizer);
  const userID = useSelector(selectMbbUserIdPseudonymizer);
  const pUser = useSelector(selectPUserPseudonymizer);

  const resultContent = useSelector(selectResultContent);

  // const showErrorDisplay = useSelector(selectErrorDisplay);

  useEffect(() => {
    if (!vinPseudonymizer) {
      dispatch(setVinPseudonymizer(""));
    }
    if (!vinPseudonymizer && !pVin && !audience && !userID && !pUser) {
      dispatch(clearResultContent());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultContent]);

  return (
    <>
      <div className={classes.vinByUuIDSectionActionSection}>
        <div className={classes.regionSelectionRow}>
          <div className={classes.regionSection}>
            <FormControl>
              <FormLabel id="reg-radio-buttons-group-label">Region</FormLabel>
              <RadioGroup
                aria-labelledby="reg-radio-buttons-group-label"
                value={regPseudonymizer}
                onChange={(event) => {
                  dispatch(setRegionPseudonymizer(event.target.value));
                }}
                name="radio-buttons-group"
                row
              >
                <FormControlLabel
                  value="ece"
                  control={<Radio size="small" />}
                  label="ECE"
                />
                <FormControlLabel
                  value="nar"
                  control={<Radio size="small" />}
                  label="NAR"
                />
                <FormControlLabel
                  value="cn"
                  control={<Radio size="small" />}
                  label="CN"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className={classes.environmentSection}>
            <FormControl>
              <FormLabel id="env-radio-buttons-group-label">
                Environment
              </FormLabel>
              <RadioGroup
                aria-labelledby="env-radio-buttons-group-label"
                value={
                  regPseudonymizer === "cn" ? "approval" : envPseudonymizer
                }
                onChange={(event) => {
                  dispatch(setEnvironmentPseudonymizer(event.target.value));
                }}
                name="radio-buttons-group"
                row
              >
                <FormControlLabel
                  value="demo"
                  disabled
                  control={<Radio size="small" />}
                  label="Demo"
                />
                <FormControlLabel
                  value="approval"
                  control={<Radio size="small" />}
                  label="Approval"
                />
                <FormControlLabel
                  value="live"
                  control={<Radio size="small" />}
                  label="Live"
                  disabled={regPseudonymizer === "cn" ? true : false}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>

        {/* Vin to Pseudo VIN Input Section */}
        <VinToPseudoVinInput />
        <div className={classes.horizontalDividerContainer}>
          <HorizontalDevider />
        </div>
        {/* End of Vin to Pseudo VIN Input Section */}

        {/* Pseudo VIN input section */}
        <PseudoVinToVinInput />
        <div className={classes.horizontalDividerContainerSection}>
          <HorizontalDevider />
        </div>
        {/* END of pseudo VIN section */}

        {/* MBB input section */}
        <UserToPseudoUser />
        <div className={classes.horizontalDividerContainerSection}>
          <HorizontalDevider />
        </div>
        {/* END of MBB section */}

        {/* PSEUDO USER input section */}
        <PseudoUserToUser />
        {/* END of PSEUDO USER section */}
      </div>
      {/* Single Result section */}
      <SingleResult />
      {/* End of Single Result section */}
    </>
  );
}

export default ManualInput;
