import React, { useState } from "react";
import classes from "./pseudonymizer.module.css";
import { useDispatch, useSelector } from "react-redux";

import {
  setPVinPseudonymizer,
  selectEnvironmentPseudonymizer,
  selectRegiontPseudonymizer,
  selectAudiencePseudonymizer,
  selectPVinPseudonymizer,
  getVINFrompVIN,
} from "./pseudonymizerSlice";

function PseudoVinToVinInput() {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [invalidPVINText, setInvalidPVINText] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [resultText, setResultText] = useState("Result:");

  const envPseudonymizer = useSelector(selectEnvironmentPseudonymizer);
  const regPseudonymizer = useSelector(selectRegiontPseudonymizer);

  const audience = useSelector(selectAudiencePseudonymizer);
  const pVin = useSelector(selectPVinPseudonymizer);

  const vinFromPseudoVinHandler = (
    dispatch,
    environment,
    region,
    vin,
    pVin,
    mbbUserId,
    pUser,
    audience
  ) => {
    dispatch(
      getVINFrompVIN({
        environment,
        region,
        vin,
        pVin,
        mbbUserId,
        pUser,
        audience,
      })
    );
  };
  // TO DO: Insert UseEffect with proper conditions
  // ......
  //
  return (
    <>
      <div className={classes.pseudoVINInputSection}>
        <div
          className={classes.pseudoVINInputSectionText}
          style={{ userSelect: "text", cursor: "auto" }}
        >
          {" "}
          Pseudo VIN:{" "}
        </div>
        <div className={classes.pseudoVinSearchContainer}>
          <input
            id="searchPseudoVin"
            autoComplete="off"
            required
            data-cy="searchInput"
            value={pVin || ""}
            onChange={(event) => {
              dispatch(setPVinPseudonymizer(event.target.value));
              setInvalidPVINText(false);
            }}
            style={{ userSelect: "text", cursor: "auto" }}
            readOnly={false}
          ></input>
          <label
            htmlFor="searchPseudoVin"
            className={classes.searchLabel}
            data-cy="searchLabel"
            style={{ userSelect: "text", cursor: "auto" }}
          ></label>
        </div>
        <div
          className={classes.actionBtnSearchForPseudoVin}
          onClick={() => {
            setResultText("Result:");
            if (!pVin) {
              setInvalidPVINText(true);
            } else {
              vinFromPseudoVinHandler(
                dispatch,
                envPseudonymizer,
                regPseudonymizer,
                "",
                pVin,
                "",
                "",
                audience
              );
              setResultText("VIN for pseudo VIN:");
              setInvalidPVINText(false);
            }
          }}
        >
          Get VIN
        </div>
      </div>
      <div>
        {invalidPVINText ? (
          <div className={classes.invalidValueWarning}>
            Invalid input value for Pseudo VIN
          </div>
        ) : (
          <div className={classes.invalidValueWarning}></div>
        )}
      </div>
    </>
  );
}

export default PseudoVinToVinInput;
