import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMbbUserIdPseudonymizer,
  selectEnvironmentPseudonymizer,
  selectRegiontPseudonymizer,
  selectAudiencePseudonymizer,
  selectMbbUserIdPseudonymizer,
  getPseudoUserFromUser,
} from "./pseudonymizerSlice";
import classes from "./pseudonymizer.module.css";

function UserToPseudoUser() {
  // const [invalidError, setInvalidError] = useState(false);
  const [resultText, setResultText] = useState("Result:");
  const [invalidUserIdText, setInvalidUserIdText] = useState(false);

  const dispatch = useDispatch();

  const envPseudonymizer = useSelector(selectEnvironmentPseudonymizer);
  const regPseudonymizer = useSelector(selectRegiontPseudonymizer);

  const audience = useSelector(selectAudiencePseudonymizer);
  const userID = useSelector(selectMbbUserIdPseudonymizer);

  const pseudoUserFromUserHandler = (
    dispatch,
    environment,
    region,
    vin,
    pVin,
    mbbUserId,
    pUser,
    audience
  ) => {
    dispatch(
      getPseudoUserFromUser({
        environment,
        region,
        vin,
        pVin,
        mbbUserId,
        pUser,
        audience,
      })
    );
  };

  return (
    <>
      <div className={classes.pseudoVINInputSection}>
        <div
          className={classes.pseudoVINInputSectionText}
          style={{ userSelect: "text", cursor: "auto" }}
        >
          {" "}
          MBB User:{" "}
        </div>
        {/*  */}
        <div className={classes.pseudoVinSearchContainer}>
          <input
            id="searchMBBUser"
            autoComplete="off"
            required
            data-cy="searchInput"
            value={userID || ""}
            onChange={(event) => {
              dispatch(setMbbUserIdPseudonymizer(event.target.value));
              setInvalidUserIdText(false);
            }}
            style={{ userSelect: "text", cursor: "auto" }}
            readOnly={false}
          ></input>
          <label
            htmlFor="searchMBBUser"
            className={classes.searchLabel}
            data-cy="searchLabel"
            style={{ userSelect: "text", cursor: "auto" }}
          ></label>
        </div>
        <div
          className={classes.actionBtnSearchForPseudoVin}
          onClick={() => {
            if (!userID) {
              setInvalidUserIdText(true);
            } else {
              setResultText("Result:");
              pseudoUserFromUserHandler(
                dispatch,
                envPseudonymizer,
                regPseudonymizer,
                "",
                "",
                userID,
                "",
                audience
              );
              setInvalidUserIdText(false);
              setResultText("Pseudo User for MBB User ID:");
            }
          }}
        >
          Get pUser
        </div>
      </div>
      <div>
        {invalidUserIdText ? (
          <div className={classes.invalidValueWarning}>
            Invalid input value for MBB User ID
          </div>
        ) : (
          <div className={classes.invalidValueWarning}></div>
        )}
      </div>
    </>
  );
}

export default UserToPseudoUser;
