import React, { useEffect, useState } from "react";
import classes from "./pseudonymizer.module.css";
import HorizontalDivider from "../../components/Horizontal Divider/HorizontalDivider";

import {
  // setEnvironmentPseudonymizer,
  // setRegionPseudonymizer,
  setVinPseudonymizer,
  // selectIsLoadingDataPseudonymizer,
  // selectPresetEnvironmentPseudonymizer,
  // selectPresetRegionPseudonymizer,
  // selectEnvironmentPseudonymizer,
  // selectRegiontPseudonymizer,
  selectVinPseudonymizer,
  selectAudiencePseudonymizer,
  selectPVinPseudonymizer,
  selectMbbUserIdPseudonymizer,
  selectPUserPseudonymizer,
  selectResultContent,
  clearResultContent,
  // selectErrorDisplay,
} from "./pseudonymizerSlice";
import { useDispatch, useSelector } from "react-redux";
import ManualInput from "./ManualInput.jsx";
import UploadCSV from "./UploadCSV.jsx";

function Pseudonymizer() {
  const [selectedTab, setSelectedTab] = useState("manualInput");

  useEffect(() => {
    setSelectedTab("manualInput");
  }, []);

  const dispatch = useDispatch();

  const vinPseudonymizer = useSelector(selectVinPseudonymizer);
  const audience = useSelector(selectAudiencePseudonymizer);
  const pVin = useSelector(selectPVinPseudonymizer);
  const userID = useSelector(selectMbbUserIdPseudonymizer);
  const pUser = useSelector(selectPUserPseudonymizer);

  const resultContent = useSelector(selectResultContent);

  // const showErrorDisplay = useSelector(selectErrorDisplay);

  useEffect(() => {
    if (!vinPseudonymizer) {
      dispatch(setVinPseudonymizer(""));
    }
    if (!vinPseudonymizer && !pVin && !audience && !userID && !pUser) {
      dispatch(clearResultContent());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultContent]);

  return (
    <div className={classes.vinByUuIDContainer}>
      <div className={classes.vinByUuIDSection}>
        <div className={classes.vinByUuIDSectionTitle}>Pseudonymizer</div>
        <HorizontalDivider />
        <div className={classes.pseudonymizerTabsSection}>
          <div
            className={
              selectedTab === "manualInput"
                ? classes.pseudonymizerTabSelected
                : classes.pseudonymizerTab
            }
            onClick={() => {
              setSelectedTab("manualInput");
            }}
          >
            Manual Input
          </div>
          <div
            className={
              selectedTab === "upload"
                ? classes.pseudonymizerTabSelected
                : classes.pseudonymizerTab
            }
            onClick={() => {
              setSelectedTab("upload");
            }}
          >
            Upload CSV
          </div>
        </div>
        {/*  */}
        {/* Tab components containers */}
        {selectedTab === "upload" ? <UploadCSV /> : <ManualInput />}
      </div>
    </div>
  );
}

export default Pseudonymizer;
