import { createSlice } from "@reduxjs/toolkit";
import { postWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";

const initialState = {
  vehicleCurrentState: {},
  currentStateStatus: "",
  currentVehicleStatus: false,
  accessForbiddenToState: false,

  isLoadingDataVehicleCurrentState: [],
};

// Slice
const vehicleCurrentStateSlice = createSlice({
  name: "vehicleCurrentState",
  initialState,
  reducers: {
    setVehicleCurrentState: (state, action) => {
      state.vehicleCurrentState = action.payload;
    },
    setCurrentStateStatus: (state, action) => {
      state.currentStateStatus = action.payload;
    },
    setCurrentVehicleStatus: (state, action) => {
      state.currentVehicleStatus = action.payload;
    },
    setAccessForbiddenToState: (state, action) => {
      state.accessForbiddenToState = action.payload;
    },
    setIsLoadingDataVehicleCurrentState: (state, action) => {
      state.isLoadingDataVehicleCurrentState = action.payload;
    },
  },
});

// Selectors

export const selectVehicleCurrentState = (state) =>
  state.vehicleCurrentState.vehicleCurrentState;
export const selectCurrentStateStatus = (state) =>
  state.vehicleCurrentState.currentStateStatus;
export const selectAccessForbiddenToState = (state) =>
  state.vehicleCurrentState.accessForbiddenToState;
export const selectCurrentVehicleStatus = (state) =>
  state.vehicleCurrentState.currentVehicleStatus;
export const selectIsLoadingDataVehicleCurrentState = (state) =>
  state.vehicleCurrentState.isLoadingDataVehicleCurrentState;

// Actions

export const {
  setVehicleCurrentState,
  setCurrentStateStatus,
  setCurrentVehicleStatus,
  setAccessForbiddenToState,
  setIsLoadingDataVehicleCurrentState,
} = vehicleCurrentStateSlice.actions;

//Getter

export const getVehicleCurrentState =
  (environment, region, vin) => async (dispatch) => {
    dispatch(setIsLoadingDataVehicleCurrentState(true));

    const pollUntilFinished = async (attempt) => {
      if (attempt > 6) {
        // If attempted 3 times and still not finished, dispatch setCurrentVehicleStatus(true)
        dispatch(setCurrentVehicleStatus(true));
        return Promise.reject("Polling timed out");
      }

      const response = await postWithAuth(
        "GETVEHICLECURRENTSTATE",
        {},
        { environment, region, vin }
      );

      if (response !== null) {
        dispatch(setVehicleCurrentState(response));

        if (response.status === "NotFinished") {
          dispatch(setCurrentStateStatus("NotFinished"));
          // If the status is "NotFinished," wait for 10 seconds and poll again
          setTimeout(() => pollUntilFinished(attempt + 1), 5000);
        } else {
          // If status is not "NotFinished," resolve the promise
          dispatch(setCurrentStateStatus(""));
          return Promise.resolve(response);
        }
      } else {
        console.log(response);
        return Promise.reject(response);
      }
    };

    try {
      await pollUntilFinished(1); // Start with the first attempt
    } catch (error) {
      logError(error);
      errorHandler(error, dispatch);
      if (error.code === "ERR_BAD_REQUEST") {
        dispatch(setAccessForbiddenToState(true));
      }
    } finally {
      dispatch(setIsLoadingDataVehicleCurrentState(false));
    }
  };

export default vehicleCurrentStateSlice.reducer;
