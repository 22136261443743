import React from "react";
import classes from "./themesong.module.css";
import soundtrack from "../../ui/Assets/Sounds/Long_Mix.mp3";
import { selectPlayMusic } from "./themesongSlice";
import { useSelector } from "react-redux";

function Soundtrack({ theme }) {
  const playMusic = useSelector(selectPlayMusic);

  // useEffect(() => {
  //   console.log("shoud muzic play ? __ " + playMusic);
  // }, [playMusic]);

  return (
    <div className={classes.themesongButtonContainer}>
      {playMusic && (
        <audio autoPlay loop>
          <source src={soundtrack} type="audio/mpeg" />
        </audio>
      )}
    </div>
  );
}

export default Soundtrack;
