import React, { useEffect, useLayoutEffect } from "react";
import classes from "./TestDataSelection.module.css";
import ProjectDetails from "../../components/Project Details/ProjectDetails";
import TestList from "../../components/Test List/TestList";
import FullButton from "../../ui/Buttons/Full Button/FullButton";
import { useParams } from "react-router-dom";
import {
  selectSelectedModelId,
  setProgressURL,
  setSelectedModel,
} from "../Homepage/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  checkIfTestDataIsLocked,
  selectIsSelected,
  selectSelectedTest,
  selectTestDataIsLocked,
  setIsSelected,
  setSelectedTest,
  selectAllTests,
} from "./testsSlice";
import {
  selectLastResultAvailable,
  setLastResultAvailable,
  setStatus,
  getTestResults,
} from "../Results Report Page/resultsSlice";
import RedirectPage from "../redirectPage/RedirectPage";
import HorizontalDevider from "../../components/Horizontal Divider/HorizontalDivider";
import BackButton from "../../ui/Buttons/Back Button/BackButton";
import LastResultBtn from "../../components/lastResultsBtn/LastResultBtn";

function TestDataSelectionPage() {
  const dispatch = useDispatch();
  const paramsList = useParams();
  const id = paramsList.id;
  const isTestSelected = useSelector(selectIsSelected);
  const selectedTest = useSelector(selectSelectedTest);
  const selectedModelId = useSelector(selectSelectedModelId);
  const scenario = selectedTest.scenarioType;
  const testDataIsLocked = useSelector(selectTestDataIsLocked);
  const lastResultAvailable = useSelector(selectLastResultAvailable);
  const allTests = useSelector(selectAllTests);

  useEffect(() => {
    dispatch(setSelectedModel(id));
    dispatch(setProgressURL(id));
    dispatch(checkIfTestDataIsLocked());
  }, [id, dispatch]);

  useLayoutEffect(() => {
    if (allTests && allTests.length > 0) {
      dispatch(setSelectedTest(allTests[0].scenarioType));
      dispatch(setIsSelected(true));
    }
  }, [dispatch]);

  function testLoadingHandler(scenarioType) {
    dispatch(setSelectedTest(scenarioType));
    dispatch(setIsSelected(true));
  }

  function handleRunTestClick() {
    lastResultAvailable && dispatch(setLastResultAvailable(false));

    if (!isTestSelected) {
      console.log(1);
    } else {
      dispatch(setStatus("Pending..."));
      dispatch(getTestResults(scenario, selectedModelId));
      dispatch(setStatus("Pending..."));
    }
  }

  return (
    <div className={classes.gridContainer}>
      <div className={classes.flexContainerHeader} data-cy="">
        <div className={classes.flexItemHeader}>
          <div className={classes.dropdown}>
            <div className={classes.flexContainerRow}> </div>
            <div className={classes.flexContainerRow}> </div>
            <div className={classes.flexContainerRow}> </div>
            <div className={classes.flexContainerRowTitle}>
              {" "}
              Select Test Scenario
            </div>
            <div
              style={{
                justifyContent: "flex-end",
                display: "flex",
                alignItems: "center",
                lineHeight: "1.5rem",
                padding: " 0.35rem 0",
                marginRight: "4.95rem",
              }}
            >
              {" "}
              {lastResultAvailable ? (
                <LastResultBtn />
              ) : (
                <div style={{ height: "1.5rem", width: "7.5rem" }} />
              )}{" "}
            </div>
            <HorizontalDevider />
            <BackButton
              backMessage={"Back to vehicle device selection"}
              destination={"/homepage"}
            />
          </div>
        </div>

        <div className={classes.bodySection}>
          <div
            className={classes.leftSide}
            style={{
              width: "45%",
              marginLeft: "5%",
              height: "47.5vh",
              overflowY: "scroll",
            }}
          >
            {Object.keys(selectedModelId).length === 0 ? (
              <RedirectPage />
            ) : (
              <ProjectDetails />
            )}
          </div>
          <div
            className={classes.rightSide}
            style={{
              width: "45%",
              height: "47.5vh",
              marginLeft: "5%",
              marginRight: "5%",
              border: "1px solid var(--dark-gray",
              overflow: "scroll",
            }}
          >
            {Object.keys(selectedModelId).length === 0 ? (
              <RedirectPage />
            ) : (
              <TestList loadTest={testLoadingHandler} scenarioType={scenario} />
            )}
          </div>
        </div>
        <div className={classes.gridItemRow}></div>
        <div className={classes.gridItemRow}>
          <div
            className={classes.actionSection}
            onClick={handleRunTestClick}
            data-cy="run-test-selection"
          >
            <FullButton
              destination={`/progress/${id}`}
              disabledConditions={!isTestSelected || testDataIsLocked}
              buttonText={"Run selected scenario"}
              extraInfo={testDataIsLocked}
              extraInfoText={"Test Data already in use, please wait..."}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestDataSelectionPage;
