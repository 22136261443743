import { createSlice} from "@reduxjs/toolkit";



const initialState = {
    open: false,
    snackBar: {
        errorCode: "The error code",
        errorMessage: "The message of the error",
    },
  
}

// Slice
const snackBarSlice = createSlice({
    name: "snackBar",
    initialState,
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload;
        },
        setSnackBar: (state, action) => {
            state.snackBar.errorCode = action.payload.errorCode;
            state.snackBar.errorMessage = action.payload.errorMessage;
            
        },
    }
})

// Selectors

export const selectOpen = (state) => state.snackBar.open;
export const selectSnackBar = (state) => state.snackBar.snackBar;

// Actions

export const {
    setSnackBar,
    setOpen,
    
} = snackBarSlice.actions;


export default snackBarSlice.reducer;
