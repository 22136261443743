import React, { useState } from "react";
import classes from "./Accordion.module.css";
import downArrow from "../../ui/Assets/Icons/downArrow.png";
import upArrow from "../../ui/Assets/Icons/upArrow.png";
import success from "../../ui/Assets/Icons/done.png";
import failed from "../../ui/Assets/Icons/fail.png";
import blocked from "../../ui/Assets/Icons/blocked.png";
import Modal from "react-modal";
import close from "../../ui/Assets/Icons/close.png";
import { useDispatch, useSelector } from "react-redux";
import { selectTestResults } from "../../pages/Results Report Page/resultsSlice";
import copy from "../../ui/Assets/Icons/copy-results.png";
import copyHttp from "../../ui/Assets/Icons/copy-http.png";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  selectOpenInfoSnackBar,
  setInfoSnackBar,
} from "../InfoSnackbar/infoSnackbarSlice";
import { useEffect } from "react";

function Accordion() {
  const dispatch = useDispatch();
  const openInfo = useSelector(selectOpenInfoSnackBar);
  const resultObject = useSelector(selectTestResults);
  const results = resultObject.testResults;
  const exception = resultObject.scenarioException;

  const [clicked, setClicked] = useState(-1);
  const [details, setDetails] = useState(false);
  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(-1);
    }
    setClicked(index);
  };

  useEffect(() => {
    openInfo
      ? setTimeout(() => {
          dispatch(setInfoSnackBar(false));
        }, 1500)
      : console.log();
  }, [dispatch, openInfo]);

  const hiddenVSshow =
    clicked > -1
      ? `${classes.accordionContents}`
      : `${classes.accordionContentsHidden}`;

  // this works pretty well: but doesn't parse json correctly :

  // const prettyPrintJson = (data) => {
  //   // Function to recursively prettify JSON objects
  //   const prettifyJson = (jsonData, indentLevel = 0) => {
  //     if (typeof jsonData === "object") {
  //       // If jsonData is an object, recursively prettify its properties
  //       return Object.entries(jsonData).map(([key, value]) => {
  //         const formattedKey = `${" ".repeat(indentLevel * 2)}${key}`;
  //         if (typeof value === "object") {
  //           return (
  //             <div key={formattedKey}>
  //               <span style={{ fontWeight: "bold" }}>"{formattedKey}":</span>
  //               <div style={{ marginLeft: "10px" }}>
  //                 {prettifyJson(value, indentLevel + 1)}
  //               </div>
  //             </div>
  //           );
  //         } else {
  //           // Check if value is a string
  //           if (typeof value === "string") {
  //             // Insert line break after every 150 characters
  //             const formattedValue = value.replace(
  //               /><(?!\/?br\s*\/?>)/g,
  //               "><br/>"
  //             );
  //             return (
  //               <div key={formattedKey}>
  //                 <span style={{ fontWeight: "bold" }}>"{formattedKey}":</span>
  //                 <span
  //                   dangerouslySetInnerHTML={{ __html: `"${formattedValue}"` }}
  //                 />
  //               </div>
  //             );
  //           } else {
  //             return (
  //               <div key={formattedKey}>
  //                 <span style={{ fontWeight: "bold" }}>"{formattedKey}":</span>
  //                 <span>"{value}"</span>
  //               </div>
  //             );
  //           }
  //         }
  //       });
  //     } else {
  //       // If jsonData is not an object, display it as is
  //       return <span>"{jsonData}"</span>;
  //     }
  //   };

  //   // Parse JSON if data is not a string
  //   const jsonData = typeof data === "string" ? JSON.parse(data) : data;

  //   // Prettify the JSON data
  //   const formattedData = prettifyJson(jsonData);

  //   return (
  //     <div>
  //       <pre
  //         style={{
  //           fontFamily: "var(--regular)",
  //           opacity: "0.7",
  //           fontSize: "1rem",
  //         }}
  //       >
  //         {formattedData}
  //       </pre>
  //     </div>
  //   );
  // };

  // pretifies json in json

  // const prettyPrintJson = (data) => {
  //   // Function to recursively prettify JSON objects
  //   const prettifyJson = (jsonData, indentLevel = 0) => {
  //     if (typeof jsonData === "object") {
  //       // If jsonData is an object, recursively prettify its properties
  //       return Object.entries(jsonData).map(([key, value]) => {
  //         const formattedKey = `${"".repeat(indentLevel * 2)}${key}`;
  //         if (typeof value === "object") {
  //           return (
  //             <div key={formattedKey}>
  //               <span style={{ fontWeight: "bold" }}>"{formattedKey}":</span>
  //               <div style={{ marginLeft: "10px" }}>
  //                 {prettifyJson(value, indentLevel + 1)}
  //               </div>
  //             </div>
  //           );
  //         } else {
  //           // Check if value is a string
  //           if (typeof value === "string") {
  //             // Insert line break after every "," preceded by quote mark
  //             const formattedValue = value.replace(/(",")/g, '",<br/>"');
  //             return (
  //               <div key={formattedKey}>
  //                 <span style={{ fontWeight: "bold" }}>"{formattedKey}":</span>
  //                 <span
  //                   dangerouslySetInnerHTML={{ __html: `"${formattedValue}"` }}
  //                 />
  //               </div>
  //             );
  //           } else {
  //             return (
  //               <div key={formattedKey}>
  //                 <span style={{ fontWeight: "bold" }}>"{formattedKey}":</span>
  //                 <span>"{value}"</span>
  //               </div>
  //             );
  //           }
  //         }
  //       });
  //     } else {
  //       // If jsonData is not an object, display it as is
  //       return <span>"{jsonData}"</span>;
  //     }
  //   };

  //   // Parse JSON if data is not a string
  //   const jsonData = typeof data === "string" ? JSON.parse(data) : data;

  //   // Prettify the JSON data
  //   const formattedData = prettifyJson(jsonData);

  //   return (
  //     <div>
  //       <pre
  //         style={{
  //           fontFamily: "var(--regular)",
  //           opacity: "0.7",
  //           fontSize: "1rem",
  //         }}
  //       >
  //         {formattedData}
  //       </pre>
  //     </div>
  //   );
  // };

  // COMBINED xml + json in json
  const prettyPrintJson = (data) => {
    // Function to recursively prettify JSON objects
    const prettifyJson = (jsonData, indentLevel = 0) => {
      if (typeof jsonData === "object") {
        // If jsonData is an object, recursively prettify its properties
        return Object.entries(jsonData).map(([key, value]) => {
          const formattedKey = `${" ".repeat(indentLevel * 2)}${key}`;
          if (typeof value === "object") {
            return (
              <div key={formattedKey}>
                <span style={{ fontWeight: "bold" }}>"{formattedKey}":</span>
                <div style={{ marginLeft: "10px" }}>
                  {prettifyJson(value, indentLevel + 1)}
                </div>
              </div>
            );
          } else {
            // Check if value is a string and contains "><", if so, split it into separate spans with a line break
            if (typeof value === "string" && value.includes("><")) {
              const splitValues = value.split("><");
              return (
                <div key={formattedKey}>
                  <span style={{ fontWeight: "bold" }}>"{formattedKey}":</span>
                  <div style={{ marginLeft: "10px" }}>
                    {splitValues.map((val, index) => (
                      <React.Fragment key={index}>
                        <span>"{val}"</span>
                        {index < splitValues.length - 1 && <br />}{" "}
                        {/* Add line break if not the last item */}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              );
            } else if (typeof value === "string") {
              // Added this condition to ensure value is a string
              // Insert line break after every "," preceded by quote mark
              const formattedValue = value.replace(/(",")/g, '",<br/>"');
              return (
                <div key={formattedKey}>
                  <span style={{ fontWeight: "bold" }}>"{formattedKey}":</span>
                  <span
                    dangerouslySetInnerHTML={{ __html: `"${formattedValue}"` }}
                  />
                </div>
              );
            } else {
              return (
                <div key={formattedKey}>
                  <span style={{ fontWeight: "bold" }}>"{formattedKey}":</span>
                  <span>"{value}"</span>
                </div>
              );
            }
          }
        });
      } else {
        // If jsonData is not an object, display it as is
        return <span>"{jsonData}"</span>;
      }
    };

    // Parse JSON if data is not a string
    const jsonData = typeof data === "string" ? JSON.parse(data) : data;

    // Prettify the JSON data
    const formattedData = prettifyJson(jsonData);

    return (
      <div>
        <pre
          style={{
            fontFamily: "var(--regular)",
            opacity: "0.7",
            fontSize: "1rem",
          }}
        >
          {formattedData}
        </pre>
      </div>
    );
  };

  const prettyCopyJson = (data) => {
    return JSON.stringify(data, null, 2);
  };

  function checkStatus(result) {
    if (result.ResultStatus === "success") {
      // console.log(result)
      return success;
    } else if (result.ResultStatus === "blocked") {
      // console.log(result)
      return blocked;
    } else {
      // console.log(result)
      return failed;
    }
  }

  console.log(exception);
  console.log(resultObject);

  return (
    <div className={classes.accordionSection}>
      {exception !== undefined ? (
        <div className={classes.acordionTitle} data-cy="accordion-title">
          Test data preparation failed
        </div>
      ) : (
        <div className={classes.acordionTitle} data-cy="accordion-title">
          Test results
        </div>
      )}
      {exception !== undefined ? (
        <div
          className={classes.exception}
          style={{ marginBottom: "2rem" }}
          data-cy="time-stamp"
        >
          <p>{exception}</p>
        </div>
      ) : (
        <div
          className={classes.timestamp}
          style={{ marginBottom: "2rem" }}
          data-cy="time-stamp"
        >
          <p>Time stamp: {resultObject.executionTimestamp}</p>
        </div>
      )}

      <div className={classes.accordionResults}>
        {results.map((result, index) => (
          <div
            className={classes.accordionItem}
            key={index}
            data-cy="accordion-item"
          >
            <div className={classes.accordionTitle}>
              <div className={classes.progressImg}>
                <img src={checkStatus(result)} alt="" />
              </div>
              <div
                className={classes.accordionName}
                onClick={() => toggle(index)}
                data-cy="operation-id"
              >
                {result.OperationId}
              </div>
              {/*  */}
              <div className={classes.copyIcon}>
                <CopyToClipboard
                  text={`Operation ID: ${result.OperationId} 
                  Service ID: ${result.ServiceId} 
                  Status: ${result.Failed} 
                  TestData: ${prettyCopyJson(resultObject.testData)}
               `}
                >
                  <img
                    src={copy}
                    alt=""
                    title="Copy test data on clipboard"
                    onClick={() => dispatch(setInfoSnackBar(true))}
                  />
                </CopyToClipboard>
                <CopyToClipboard
                  text={`Request Result: ${prettyCopyJson(
                    result.RequestResult
                  )} `}
                >
                  <img
                    src={copyHttp}
                    alt=""
                    title="Copy request result on clipboard"
                    onClick={() => dispatch(setInfoSnackBar(true))}
                  />
                </CopyToClipboard>
              </div>
              {/*  */}
              <div
                className={classes.accordionArrow}
                onClick={() => toggle(index)}
                data-cy="expand-result"
              >
                {clicked === index ? (
                  <img src={upArrow} alt="" />
                ) : (
                  <img src={downArrow} alt="" />
                )}
              </div>
            </div>
            {clicked === index ? (
              <div className={hiddenVSshow} style={{ paddingBottom: "1rem" }}>
                <div className={classes.accordionTestName}>
                  Status: <p>{result.ResultStatus}</p>
                </div>
                <div className={classes.accordionTestID}>
                  Operation ID: <p>{result.OperationId}</p>
                </div>
                <div className={classes.accordionStatus}>
                  Service ID: <p>{result.ServiceId}</p>
                </div>
                <div className={classes.accordionMessage}>
                  {result.ResultStatus === "failed" ||
                  result.ResultStatus === "blocked"
                    ? "Observed Result: "
                    : ""}
                  <p>
                    {" "}
                    {result.ResultStatus === "failed" ||
                    result.ResultStatus === "blocked"
                      ? result.ObservedResult
                      : ""}
                  </p>
                </div>
                <div className={classes.accordionResult}>
                  {result.ResultStatus === "failed" ||
                  result.ResultStatus === "blocked"
                    ? "Expected Result: "
                    : ""}
                  <p>
                    {" "}
                    {result.ResultStatus === "failed" ||
                    result.ResultStatus === "blocked"
                      ? result.ExpectedResult
                      : ""}
                  </p>
                </div>
                <div className={classes.accordionResult}>
                  {result.ResultStatus === "failed" ||
                  result.ResultStatus === "blocked"
                    ? `Result Analysis: `
                    : ""}
                  <p>
                    {" "}
                    {result.ResultStatus === "failed" ||
                    result.ResultStatus === "blocked"
                      ? result.ResultAnalysis
                      : ""}
                  </p>
                </div>
                <div className={classes.expand} style={{ marginTop: "1.5rem" }}>
                  <p onClick={() => setDetails(true)} data-cy="details-button">
                    View full report
                  </p>
                </div>

                <Modal
                  data-cy="full-report-modal"
                  isOpen={details}
                  ariaHideApp={false}
                  className={classes.modalBck}
                  style={{
                    overlay: {
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(28, 28, 28, 0.9)",
                    },
                  }}
                >
                  <img
                    className={classes.closeImg}
                    src={close}
                    alt=""
                    onClick={() => setDetails(!details)}
                    data-cy="close-img"
                  ></img>
                  <div className={classes.container}>
                    <div className={classes.detailsContents}>
                      <div className={classes.titleReq}>
                        <p>Request Result:</p>
                      </div>
                      <div className={classes.messageReq}>
                        <p>{prettyPrintJson(result.RequestResult)}</p>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ margin: "0.75rem 0" }}
                      >
                        <p style={{ fontSize: "1.25rem", marginLeft: "1rem" }}>
                          Project info
                        </p>
                      </div>
                      <div className={classes.titleReq}>
                        <p>Primary MBB User:</p>
                      </div>
                      <div
                        className={classes.messageReq}
                        style={{ marginBottom: "1rem" }}
                      >
                        <p> {resultObject.testData.primaryMbbUser}</p>
                      </div>
                      <div className={classes.titleReq}>
                        <p>Primary Portal User:</p>
                      </div>
                      <div
                        className={classes.messageReq}
                        style={{ marginBottom: "1rem" }}
                      >
                        <p> {resultObject.testData.primaryPortalUser}</p>
                      </div>
                      <div className={classes.titleReq}>
                        <p>Comment:</p>
                      </div>
                      <div
                        className={classes.messageReq}
                        style={{ marginBottom: "1rem" }}
                      >
                        <p> {resultObject.testData.comment}</p>
                      </div>
                      <div className={classes.titleReq}>
                        <p>Environment:</p>
                      </div>
                      <div
                        className={classes.messageReq}
                        style={{ marginBottom: "1rem" }}
                      >
                        <p> {resultObject.testData.environment}</p>
                      </div>
                      <div className={classes.titleReq}>
                        <p>Release Version:</p>
                      </div>
                      <div
                        className={classes.messageReq}
                        style={{ marginBottom: "1rem" }}
                      >
                        <p> {resultObject.testData.releaseVersion}</p>
                      </div>
                      <div className={classes.titleReq}>
                        <p>Region:</p>
                      </div>
                      <div
                        className={classes.messageReq}
                        style={{ marginBottom: "1rem" }}
                      >
                        <p> {resultObject.testData.region}</p>
                      </div>
                      <div className={classes.titleReq}>
                        <p>Result status:</p>
                      </div>
                      <div
                        className={classes.messageReq}
                        style={{ marginBottom: "1rem" }}
                      >
                        <p> {result.ResultStatus}</p>
                      </div>
                      <div className={classes.titleReq}>
                        <p>Operation ID:</p>
                      </div>
                      <div
                        className={classes.messageReq}
                        style={{ marginBottom: "1rem" }}
                      >
                        <p> {result.OperationId}</p>
                      </div>
                      <div className={classes.titleReq}>
                        <p>Service ID:</p>
                      </div>
                      <div
                        className={classes.messageReq}
                        style={{ marginBottom: "1rem" }}
                      >
                        <p> {result.ServiceId}</p>
                      </div>
                      <div className={classes.titleReq}>
                        <p>Secondary MBB User:</p>
                      </div>
                      <div
                        className={classes.messageReq}
                        style={{ marginBottom: "1rem" }}
                      >
                        <p> {resultObject.testData.secondaryMbbUser}</p>
                      </div>
                      <div className={classes.titleReq}>
                        <p>Secondary Portal User:</p>
                      </div>
                      <div
                        className={classes.messageReq}
                        style={{ marginBottom: "1rem" }}
                      >
                        <p> {resultObject.testData.secondaryPortalUser}</p>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ margin: "0.75rem 0" }}
                      >
                        <p style={{ fontSize: "1.25rem", marginLeft: "1rem" }}>
                          Masterdata
                        </p>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Country:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.country}</p>
                        </div>
                      </div>

                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Engine Code:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.engineCode}</p>
                        </div>
                      </div>

                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>MMI:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.mmi}</p>
                        </div>
                      </div>

                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Color Finish:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.colorFinish}</p>
                        </div>
                      </div>

                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Color Interior:</p>
                        <div className={classes.messageReq}>
                          <p>
                            {resultObject.testData.masterdata.colorInterior}
                          </p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>PNR List:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.pnrList}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Model Year:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.modelYear}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Model Range:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.modelRange}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Model Description:</p>
                        <div className={classes.messageReq}>
                          <p>
                            {resultObject.testData.masterdata.modelDescription}
                          </p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Production Date:</p>
                        <div className={classes.messageReq}>
                          <p>
                            {resultObject.testData.masterdata.productionDate}
                          </p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Fuel Type:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.fuelType}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Model Code:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.modelCode}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Mapping VIN:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.mappingVIN}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Color Top:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.colorTop}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Color Paint:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.colorPaint}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>VIN:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.vin}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Country of Market:</p>
                        <div className={classes.messageReq}>
                          <p>
                            {resultObject.testData.masterdata.countryOfMarket}
                          </p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Engine Code Extension:</p>
                        <div className={classes.messageReq}>
                          <p>
                            {
                              resultObject.testData.masterdata
                                .engineCodeExtension
                            }
                          </p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Delivery Date:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.deliveryDate}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Brand:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.masterdata.brand}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Country of Registration:</p>
                        <div className={classes.messageReq}>
                          <p>
                            {
                              resultObject.testData.masterdata
                                .countryOfRegistration
                            }
                          </p>
                        </div>
                      </div>
                      <div className={classes.titleReq}>
                        <p>
                          {result.ResultStatus === "failed" ||
                          result.ResultStatus === "blocked"
                            ? " Expected result: "
                            : ""}
                        </p>
                      </div>
                      <div
                        className={classes.messageReq}
                        style={{ marginBottom: "1rem" }}
                      >
                        <p>
                          {" "}
                          {result.ResultStatus === "failed" ||
                          result.ResultStatus === "blocked"
                            ? result.ExpectedResult
                            : ""}
                        </p>
                      </div>
                      <div className={classes.titleReq}>
                        <p>
                          {result.ResultStatus === "failed" ||
                          result.ResultStatus === "blocked"
                            ? " Observed Result: "
                            : ""}
                        </p>
                      </div>
                      <div
                        className={classes.messageReq}
                        style={{ marginBottom: "1rem" }}
                      >
                        <p>
                          {" "}
                          {result.ResultStatus === "failed" ||
                          result.ResultStatus === "blocked"
                            ? result.ObservedResult
                            : ""}
                        </p>
                      </div>
                      <div className={classes.titleReq}>
                        <p>
                          {result.ResultStatus === "failed" ||
                          result.ResultStatus === "blocked"
                            ? " Result Analysis: "
                            : ""}
                        </p>
                      </div>
                      <div
                        className={classes.messageReq}
                        style={{ marginBottom: "1rem" }}
                      >
                        <p>
                          {" "}
                          {result.ResultStatus === "failed" ||
                          result.ResultStatus === "blocked"
                            ? result.ResultAnalysis
                            : ""}
                        </p>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ margin: "0.75rem 0" }}
                      >
                        <p style={{ fontSize: "1.25rem", marginLeft: "1rem" }}>
                          Metadata
                        </p>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Access:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.metadata.access}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>EOL:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.metadata.eol}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>VIN:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.metadata.vin}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>F PIN Provider:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.metadata.fPinProvider}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Masterdata Provider</p>
                        <div className={classes.messageReq}>
                          <p>
                            {resultObject.testData.metadata.masterdataProvider}
                          </p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Home Region:</p>
                        <div className={classes.messageReq}>
                          <p>{resultObject.testData.metadata.homeRegion}</p>
                        </div>
                      </div>
                      <div
                        className={classes.titleReq}
                        style={{ marginTop: "0.5rem" }}
                      >
                        <p>Owner:</p>
                        <div
                          className={classes.messageReq}
                          style={{ marginBottom: "1rem" }}
                        >
                          <p>{resultObject.testData.metadata.owner}</p>
                        </div>
                      </div>
                      {/* <div className={classes.titleReq}>
                        <p>Request Result:</p>
                      </div>
                      <div className={classes.messageReq}>
                        <p>{prettyPrintJson(result.RequestResult)}</p>
                      </div> */}
                      {/* <div className={classes.titleRes}>
                        <p>HTTP Response</p>
                      </div>
                      <div className={classes.messageRes}>
                        <p>{prettyPrintJson(result.HttpResponse)}</p>{" "}
                      </div> */}
                    </div>
                    <div className={classes.buttons}>
                      <div
                        className={classes.okayBtn}
                        onClick={() => setDetails(!details)}
                        data-cy="okay-button"
                      >
                        {" "}
                        Okay
                      </div>
                      <div className={classes.copyBtn}>
                        <CopyToClipboard
                          text={`MasterData: ${prettyCopyJson(
                            resultObject.testData.masterdata
                          )}; Metadata: ${prettyCopyJson(
                            resultObject.testData.metadata
                          )} Request Result: ${prettyCopyJson(
                            result.RequestResult
                          )};
                          )} `}
                        >
                          <img
                            style={{ width: "2.1rem" }}
                            src={copyHttp}
                            alt=""
                            title="Copy request result on clipboard"
                            onClick={() => dispatch(setInfoSnackBar(true))}
                          />
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Accordion;
