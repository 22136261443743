import React from "react";
import classes from "./auth.module.css";
import LoadingSpinner from "../ui/Loading Spinner/LoadingSpinner";
import logo from "../ui/Assets/Icons/logo.svg";


export default function AuthLoading() {
  return <div className={classes.gridContainer}>
    <div className={classes.flexContainerHeader}>
    <div className={classes.logo}>
        <img
          src={logo}
          alt=""
          style={{ width: "150px" }}
        />
        </div>
    </div>
    <div className={classes.flexContainerRow}> Authentication required</div>    
    <div className={classes.gridItemRow}>You will be redirected to authentication page...</div>
    <div className={classes.gridItemRow}><LoadingSpinner/></div>


  </div>
}
