import React, { useRef, useState } from "react";
import "react-international-phone/style.css";
import classes from "./adminToolTraceTransformer.module.css";
import HorizontalDevider from "../../components/Horizontal Divider/HorizontalDivider";
import { useDispatch } from "react-redux";
import { generateAdminToolTraceTransformer } from "./adminToolTraceTransformerSlice";

function AdminToolTraceTransformer() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };

  const uploadHandler = () => {
    if (selectedFile) {
      dispatch(generateAdminToolTraceTransformer(selectedFile));
      setFileName("");
    } else {
      console.log("No file selected");
    }
  };

  const downloadAvailable =
    selectedFile !== null
      ? classes.adminToolTraceTransformerDownloadContentsActionSectionSelectButton
      : classes.adminToolTraceTransformerDownloadContentsActionSectionSelectButtonDisabled;

  return (
    <div className={classes.adminToolTraceTransformerPageContainer}>
      <div className={classes.adminToolTraceTransformerContents}>
        <div className={classes.adminToolTraceTransformerContentsTitle}>
          Admin Tool Trace Transformer
        </div>
        <HorizontalDevider />

        {/* upload section */}
        <div
          className={classes.adminToolTraceTransformerUploadContentsInputFields}
        >
          <div
            className={
              classes.adminToolTraceTransformerUploadContentsActionSection
            }
          >
            <div
              className={
                classes.adminToolTraceTransformerUploadContentsActionSectionInfo
              }
            >
              Upload an Admin Tool Trace CSV (max. file size 50MB).
            </div>
            <div
              className={
                classes.adminToolTraceTransformerUploadContentsActionSectionSelect
              }
            >
              <input
                type="file"
                accept=".csv"
                style={{ display: "none" }}
                onChange={handleFileChange}
                ref={fileInputRef}
              />
              <div
                className={
                  classes.adminToolTraceTransformerUploadContentsActionSectionSelectButton
                }
                onClick={() => fileInputRef.current.click()}
              >
                Upload Admin Tool Trace CSV
              </div>
              <div className={downloadAvailable} onClick={uploadHandler}>
                Download Admin Tool Trace one-liner
              </div>
            </div>
          </div>
          {fileName !== "" ? (
            <div className={classes.fileNameBox}>File selected: {fileName}</div>
          ) : (
            <div className={classes.fileNameBox}></div>
          )}{" "}
          <div className={classes.adminToolTraceTransformerExampleContainer}>
            <p className={classes.adminToolTraceTransformerExampleText}>
              {" "}
              The input will be transformed into a one-line-file which can be
              read by common log-viewers like "Chipmunk"
            </p>
          </div>
        </div>
        {/* END of upload section */}
      </div>
    </div>
  );
}

export default AdminToolTraceTransformer;
