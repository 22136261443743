import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";
import { postWithAuth } from "../../utils/api";

const initialState = {
  vehicleByUuid: null,
  vinValueByUuid: "",
  envValueByUuid: "",
  presetEnvValueByUuid: "approval",
  regValueByUuid: "",
  presetRegValueByUuid: "ece",
  isLoadingDataVinByUuID: false,
  emptyState: true,
  vinToUuIDvalue: "",
  uuidByVin: null,
};

// Slice
const vinByUuIDSlice = createSlice({
  name: "vinByUuID",
  initialState,
  reducers: {
    setVehicleByUuid: (state, action) => {
      state.vehicleByUuid = action.payload;
    },
    setVinByUuID: (state, action) => {
      state.vinValueByUuid = action.payload;
    },
    setEnvByUuID: (state, action) => {
      state.envValueByUuid = action.payload;
    },
    setRegByUuID: (state, action) => {
      state.regValueByUuid = action.payload;
    },
    setIsLoadingDataVinByUuID: (state, action) => {
      state.isLoadingDataVinByUuID = action.payload;
    },
    setEmptyState: (state, action) => {
      state.emptyState = action.payload;
    },

    setVinToUuIDvalue: (state, action) => {
      state.vinToUuIDvalue = action.payload;
    },
    setUuidByVIN: (state, action) => {
      state.uuidByVin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVehicleVinByUuid.fulfilled, (state, action) => {
      // Add other functionalities & handle state updates upon fulfillment as needed
    });
  },
});

// Selectors

export const selectVehicleByUuid = (state) => state.vinByUuID.vehicleByUuid;
export const selectVinByUuID = (state) => state.vinByUuID.vinValueByUuid;
export const selectEnvByUuID = (state) => state.vinByUuID.envValueByUuid;
export const selectPresetEnvByUuID = (state) =>
  state.vinByUuID.presetEnvValueByUuid;
export const selectRegByUuID = (state) => state.vinByUuID.regValueByUuid;
export const selectPresetRegByUuID = (state) =>
  state.vinByUuID.presetRegValueByUuid;
export const selectIsLoadingVinByUuID = (state) =>
  state.vinByUuID.isLoadingDataVinByUuID;
export const selectEmptyState = (state) => state.vinByUuID.emptyState;
export const selectVinToUuIDValue = (state) => state.vinByUuID.vinToUuIDvalue;
export const selectUuidByVIN = (state) => state.vinByUuID.uuidByVin;

// Actions

export const {
  setIsLoadingDataVinByUuID,
  setVehicleByUuid,
  setVinByUuID,
  setEnvByUuID,
  setRegByUuID,
  setEmptyState,
  setVinToUuIDvalue,
  setUuidByVIN,
} = vinByUuIDSlice.actions;

//Getter

export const getVehicleVinByUuid = createAsyncThunk(
  "getVehicleByUuid/getVehicleByUuid",
  async ({ environment, region, uuid }, { dispatch }) => {
    dispatch(setIsLoadingDataVinByUuID(true));
    dispatch(setEmptyState(false));
    try {
      const response = await postWithAuth(
        "GETVEHICLEVINBYUUID",
        {},
        { environment, region, vin: uuid }
      );
      if (response !== null) {
        dispatch(setVehicleByUuid(response));
        console.log(response);
      } else {
        console.log(response);
      }
    } catch (error) {
      logError(error);
      errorHandler(error, dispatch);
    } finally {
      dispatch(setIsLoadingDataVinByUuID(false));
    }
  }
);
export default vinByUuIDSlice.reducer;
