import React from "react";
import { useHistory } from "react-router";
import classes from "./lastResultBtn.module.css";
function LastResultBtn() {
  const history = useHistory();
  return (
    <div
      onClick={() => history.push("/results")}
      className={classes.lastResultBtn}
    >
      <div className={classes.lastResultBtnText}>View last result</div>
    </div>
  );
}

export default LastResultBtn;
