import { createSlice } from "@reduxjs/toolkit";
import { postWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";

const initialState = {
  vehicleUsers: {},
  isLoadingDataVehicleUsers: false,
};

// Slice
const vehicleUsersSlice = createSlice({
  name: "vehicleUsers",
  initialState,
  reducers: {
    setVehicleUsers: (state, action) => {
      state.vehicleUsers = action.payload;
    },

    setIsLoadingDataVehicleUsers: (state, action) => {
      state.isLoadingDataVehicleUsers = action.payload;
    },
  },
});

// Selectors

export const selectVehicleUsers = (state) => state.vehicleUsers.vehicleUsers;
export const selectIsLoadingDataVehicleUsers = (state) =>
  state.vehicleUsers.isLoadingDataVehicleUsers;
// Actions

export const { setVehicleUsers, setIsLoadingDataVehicleUsers } =
  vehicleUsersSlice.actions;

//Getter

export const getVehicleUsers = (environment, region, vin) => (dispatch) => {
  dispatch(setIsLoadingDataVehicleUsers(true));
  postWithAuth("GETALLVEHICLEUSERS", {}, { environment, region, vin })
    .then((response) => {
      if (response !== null) {
        dispatch(setVehicleUsers(response));
      } else {
        console.log(response);
      }
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
    })
    .finally(() => {
      dispatch(setIsLoadingDataVehicleUsers(false));
    });
};

export default vehicleUsersSlice.reducer;
