import { createSlice } from "@reduxjs/toolkit";
import { postWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";

const initialState = {
  serviceGroup: {},
  isLoadingDataServiceGroup: false,
  relatedSOPs: [],
};

// Slice
const serviceGroupSlice = createSlice({
  name: "serviceGroup",
  initialState,
  reducers: {
    setServiceGroup: (state, action) => {
      state.serviceGroup = action.payload;
    },
    setIsLoadingDataServiceGroup: (state, action) => {
      state.isLoadingDataServiceGroup = action.payload;
    },
    setRelatedSOPs: (state, action) => {
      state.relatedSOPs = action.payload;
    },
  },
});

// Selectors

export const selectServiceGroup = (state) => state.serviceGroup.serviceGroup;
export const selectisLoadingDataServiceGroup = (state) =>
  state.serviceGroup.isLoadingDataServiceGroup;
export const selectRelatedSOPs = (state) => state.serviceGroup.relatedSOPs;

// Actions

export const { setServiceGroup, setIsLoadingDataServiceGroup, setRelatedSOPs } =
  serviceGroupSlice.actions;

//Getter

export const getServiceGroup = (environment, region, vin) => (dispatch) => {
  dispatch(setIsLoadingDataServiceGroup(true));
  postWithAuth("GETSERVICEGROUP", {}, { environment, region, vin })
    .then((response) => {
      if (response !== null) {
        dispatch(setServiceGroup(response));
        dispatch(
          setRelatedSOPs(
            Object.values(
              response.AdditionalProperties.ServiceManagement.VehicleProjects
            ).map((project) => project.Name)
          )
        );
      } else {
        console.log(response);
      }
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
    })
    .finally(() => {
      dispatch(setIsLoadingDataServiceGroup(false));
    });
};

export default serviceGroupSlice.reducer;
