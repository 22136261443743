import React, { useLayoutEffect, useState } from "react";
import classes from "./SearchBoxSop.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  selectFilterSOP,
  setFilterSOP,
} from "../../pages/Homepage/projectSlice";

function SearchBoxSOP() {
  const [filter, setfilter] = useState("");
  const dispatch = useDispatch();
  const filterSOP = useSelector(selectFilterSOP);

  useLayoutEffect(() => {
    dispatch(setFilterSOP(filter));
  }, [filter]);

  const setFilters = (event) => {
    setfilter(event.target.value);
  };

  return (
    <div className={classes.searchElementSOP} data-cy="searchElement">
      <div className={classes.searchControlSOP} data-cy="searchControl">
        <div className={classes.searchBoxSOP} data-cy="searchBox">
          <input
            id="searchSOP"
            autoComplete="off"
            required
            data-cy="searchInput"
            value={filterSOP}
            onChange={setFilters}
          ></input>
          <label
            htmlFor="searchSOP"
            className={classes.searchLabel}
            data-cy="searchLabel"
          ></label>
        </div>
      </div>
    </div>
  );
}

export default SearchBoxSOP;
