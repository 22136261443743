import React from "react";
import classes from "./backendDataInfoPage.module.css";
import { selectRelatedSOPs } from "./serviceGroupSlice";
import { useSelector } from "react-redux";

function SopTable() {
  const sopNames = useSelector(selectRelatedSOPs);
  return (
    <div className={classes.tableDivSOP}>
      <div className={classes.tableTitle}>
        <div
          className={classes.tableTitleText}
          style={{ userSelect: "text", cursor: "auto" }}
        >
          Related SOPs
        </div>
      </div>
      {sopNames && sopNames.length !== 0
        ? sopNames.map((device, i) => (
            <div className={classes.tableRow} key={i}>
              <div
                className={classes.tableRowText}
                style={{ userSelect: "text", cursor: "auto" }}
              >
                {device}
              </div>
            </div>
          ))
        : ""}
    </div>
  );
}

export default SopTable;
