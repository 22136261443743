import { createSlice } from "@reduxjs/toolkit";
import { getXMLWithAuth } from "../../utils/api";
import { logError } from "../../utils/utils";
import { errorHandler } from "../../components/Error Handler/errorHandler";

const initialState = {
  phone1: "",
  phone2: "",
  phone3: "",
  phone4: "",
  phone5: "",
  phone6: "",
  phone7: "",
  phone8: "",
  selectedDevice: "",
  selectedEnvironment: "",
  selectedBackend: "",
  selectedCountry1: "de",
  isLoadingDataXML: false,
};

// Slice
const ccpGeneratorSlice = createSlice({
  name: "ccpGenerator",
  initialState,
  reducers: {
    setPhone1: (state, action) => {
      state.phone1 = action.payload;
    },
    setPhone2: (state, action) => {
      state.phone2 = action.payload;
    },
    setPhone3: (state, action) => {
      state.phone3 = action.payload;
    },
    setPhone4: (state, action) => {
      state.phone4 = action.payload;
    },
    setPhone5: (state, action) => {
      state.phone5 = action.payload;
    },
    setPhone6: (state, action) => {
      state.phone6 = action.payload;
    },
    setPhone7: (state, action) => {
      state.phone7 = action.payload;
    },
    setPhone8: (state, action) => {
      state.phone8 = action.payload;
    },

    setSelectedDevice: (state, action) => {
      state.selectedDevice = action.payload;
    },
    setSelectedEnvironment: (state, action) => {
      state.selectedEnvironment = action.payload;
    },
    setSelectedBackend: (state, action) => {
      state.selectedBackend = action.payload;
    },
    setIsLoadingDataXML: (state, action) => {
      state.isLoadingDataXML = action.payload;
    },

    setSelectedCountry1: (state, action) => {
      state.selectedCountry1 = action.payload;
    },
  },
});

// Selectors

export const selectPhone1 = (state) => state.ccpGenerator.phone1;
export const selectPhone2 = (state) => state.ccpGenerator.phone2;
export const selectPhone3 = (state) => state.ccpGenerator.phone3;
export const selectPhone4 = (state) => state.ccpGenerator.phone4;
export const selectPhone5 = (state) => state.ccpGenerator.phone5;
export const selectPhone6 = (state) => state.ccpGenerator.phone6;
export const selectPhone7 = (state) => state.ccpGenerator.phone7;
export const selectPhone8 = (state) => state.ccpGenerator.phone8;
export const selectSelectedDevice = (state) =>
  state.ccpGenerator.selectedDevice;
export const selectSelectedEnvironment = (state) =>
  state.ccpGenerator.selectedEnvironment;
export const selectSelectedBackend = (state) =>
  state.ccpGenerator.selectedBackend;
export const selectIsLoadingDataXML = (state) =>
  state.ccpGenerator.isLoadingDataXML;
export const selectSelectedCountry1 = (state) =>
  state.ccpGenerator.selectedCountry1;

// Actions

export const {
  setPhone1,
  setPhone2,
  setPhone3,
  setPhone4,
  setPhone5,
  setPhone6,
  setPhone7,
  setPhone8,
  setIsLoadingDataXML,
  setSelectedBackend,
  setSelectedDevice,
  setSelectedEnvironment,
  setSelectedCountry1,
} = ccpGeneratorSlice.actions;

//Getter

export const generateCCPFile = (csvFile) => (dispatch) => {
  dispatch(setIsLoadingDataXML(true));

  const formData = new FormData();
  formData.append("file", csvFile);

  getXMLWithAuth("GENERATECCP", {}, formData, {
    "Content-Type": "multipart/form-data",
  })
    .then((response) => {
      // Create a Blob object from the response data
      const blob = new Blob([response], { type: "application/zip" });

      // Create a temporary anchor element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "CCP-Files.zip");
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      URL.revokeObjectURL(url);
    })
    .catch((error) => {
      logError(error);
      errorHandler(error, dispatch);
    })
    .finally(() => {
      dispatch(setIsLoadingDataXML(false));
    });
};

export default ccpGeneratorSlice.reducer;
