import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import backendData from "../../ui/Assets/Vehicle Images/backendData.jpg";
import classes from "./backendDataAnalysis.module.css";
import { useSelector, useDispatch } from "react-redux";
import arrow from "../../ui/Assets/Icons/back.png";
import { useHistory } from "react-router";
import {
  selectEnvironment,
  selectRegion,
  selectVIN,
  setVIN,
  setRegion,
  setEvironment,
  selectPresetEnvironment,
  selectPresetRegion,
} from "../backendDataInfoPage/backendInfoSlice";
import {
  setServiceGroup,
  setRelatedSOPs,
} from "../backendDataInfoPage/serviceGroupSlice";
import { setVehicleClaims } from "../backendDataInfoPage/vehicleClaimsSlice";
import { setVehicleUuID } from "../backendDataInfoPage/vehicleUuIDSlice";
import { setVehicleUsers } from "../backendDataInfoPage/vehicleUsersSlice";
import { setVehicleJobs } from "../backendDataInfoPage/activationJobsSlice";
import { setAllVehicleServiceClaims } from "../backendDataInfoPage/allVehicleServiceClaimsSlice";
import { setPrimaryUserClaims } from "../backendDataInfoPage/primaryUserClaimsSlice";
import {
  setVehicleCurrentState,
  setAccessForbiddenToState,
} from "../backendDataInfoPage/vehicleCurrentStateSlice";
import {
  setConnectionStatus,
  clearPingData,
  setPingTimestamp,
  clearPingTimeStamp,
} from "../backendDataInfoPage/connectionStatusCacheSlice";
import { setOdpUserList } from "../backendDataInfoPage/odpUserList/odpUserListSlice";
import { setAccessForbidden } from "../backendDataInfoPage/connectionStatusCacheSlice";
import { Typography } from "@mui/material";
import Themesong from "#components/themesong/Themesong.jsx";

function BackendData() {
  // Initiate a timeouts array (outside of component, so it persists even if component gets re-rendered) to be able to reset it
  const timeouts = [];
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const vin = useSelector(selectVIN);
  const environment = useSelector(selectEnvironment);
  const preselectEnvironment = useSelector(selectPresetEnvironment);
  const region = useSelector(selectRegion);
  const preselectRegion = useSelector(selectPresetRegion);

  const history = useHistory();
  const searchParams = `vin=${vin}&reg=${region}&env=${environment}`;

  const setFilters = (event) => {
    dispatch(setVIN(event.target.value));
  };

  // Clean up timeouts when the component unmounts
  useEffect(() => {
    return () => {
      for (let i = 1; i < 4; i++) {
        clearTimeout(timeouts[i]);
      }
    };
  }, []);

  const searchHandler = () => {
    // Regular expression to validate VIN (17 characters, letters and digits only)
    const vinRegex = /^[A-Za-z0-9]{17}$/;

    if (vin !== "") {
      if (vinRegex.test(vin)) {
        setError(false);

        // Set the URL
        history.push(`/vehicleBackendDataAnalysis/${searchParams}`);

        // Clear the "forbidden access" message if it was true
        dispatch(setAccessForbidden(false));
        dispatch(setAccessForbiddenToState(false));
      } else {
        console.log("Invalid VIN!");
        setError(true);
      }
    } else {
      console.log("VIN is empty!");
      setError(true);
    }
  };

  const regionHandler = (region) => {
    console.log(region);
    dispatch(setRegion(region));
    dispatch(setVehicleUuID({}));
    dispatch(setVehicleUsers({}));
    dispatch(setOdpUserList({}));
    dispatch(setServiceGroup({}));
    dispatch(setVehicleJobs([]));
    dispatch(setRelatedSOPs([]));
    dispatch(setVehicleClaims({}));
    dispatch(setConnectionStatus({}));
    dispatch(setPingTimestamp([]));
    dispatch(setAllVehicleServiceClaims({}));
    dispatch(setPrimaryUserClaims({}));
    dispatch(setVehicleCurrentState({}));
    dispatch(setConnectionStatus({}));
    dispatch(clearPingData());
    dispatch(clearPingTimeStamp());
    // Clear timeouts so no calls are made
    for (let i = 1; i < 4; i++) {
      clearTimeout(timeouts[i]);
    }
    // Clear the "forbidden access" message if it was true
    dispatch(setAccessForbidden(false));
    dispatch(setAccessForbiddenToState(false));
  };

  const environmentHandler = (env) => {
    console.log(env);
    dispatch(setEvironment(env));
    dispatch(setVehicleUuID({}));
    dispatch(setVehicleUsers({}));
    dispatch(setOdpUserList({}));
    dispatch(setServiceGroup({}));
    dispatch(setVehicleJobs([]));
    dispatch(setRelatedSOPs([]));
    dispatch(setVehicleClaims({}));
    dispatch(setConnectionStatus({}));
    dispatch(setPingTimestamp([]));
    dispatch(setAllVehicleServiceClaims({}));
    dispatch(setPrimaryUserClaims({}));
    dispatch(setVehicleCurrentState({}));
    dispatch(setConnectionStatus({}));
    dispatch(clearPingData());
    dispatch(clearPingTimeStamp());
    // Clear timeouts so no calls are made
    for (let i = 1; i < 4; i++) {
      clearTimeout(timeouts[i]);
    }
    // Clear the "forbidden access" message if it was true
    dispatch(setAccessForbidden(false));
    dispatch(setAccessForbiddenToState(false));
  };

  return (
    <>
      <div className={classes.pageBackground}>
        <div style={{ position: "relative", height: "100vh", width: "100vw" }}>
          <img src={backendData} alt="" className={classes.backgroundImg} />
        </div>

        <div className={classes.pageContainer}>
          <div className={classes.titleSection}>
            <div className={classes.title}>
              {" "}
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: "1.5rem",
                }}
              >
                <div
                  style={{ position: "absolute", left: "50%", top: "-2rem" }}
                >
                  <Themesong theme={"light"} />
                </div>
              </div>
              Vehicle Backend Data Analysis
            </div>
          </div>

          <div className={classes.subTitleDiv}>
            <div className={classes.subTitle}>Enter Your VIN:</div>
            <div className={classes.searchElement} data-cy="searchElement">
              <div className={classes.searchControl} data-cy="searchControl">
                <div className={classes.searchBox} data-cy="searchBox">
                  <input
                    id="searchDetails"
                    autoComplete="off"
                    required
                    data-cy="searchInput"
                    value={vin}
                    onChange={setFilters}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        searchHandler();
                      }
                    }}
                  ></input>
                  <label
                    htmlFor="searchDetails"
                    className={classes.searchLabel}
                    data-cy="searchLabel"
                  ></label>
                  <div className={classes.actionBtn}>
                    <img
                      className={classes.arrowBtn}
                      src={arrow}
                      alt=""
                      onClick={searchHandler}
                    ></img>
                  </div>
                </div>
                {error ? (
                  <div
                    style={{
                      height: "2rem",
                      lineHeight: "2rem",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        color: "var(--primary)",
                        fontSize: "1.5rem",
                        fontFamily: "var(--thin",
                      }}
                    >
                      Please enter a valid VIN
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      color: "var(--success)",
                      fontSize: "1.5rem",
                      fontFamily: "var(--thin",
                    }}
                  ></div>
                )}
              </div>
            </div>
            <div className={classes.regionAndEnvSection}>
              <div className={classes.backgroundEnvAndRegion}>
                <div className={classes.regionSection}>
                  <FormControl>
                    <FormLabel
                      id="reg-radio-buttons-group-label"
                      style={{ fontSize: "1.5rem", color: "var(--light-gray" }}
                    >
                      Region
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="reg-radio-buttons-group-label"
                      value={region === "" ? preselectRegion : region}
                      onChange={(event) => regionHandler(event.target.value)}
                      name="radio-buttons-group"
                      row
                    >
                      <FormControlLabel
                        value="ece"
                        control={<Radio size="small" />}
                        label="ECE"
                        style={{
                          fontSize: "1.5rem",
                          color: "var(--light-gray",
                        }}
                      />
                      <FormControlLabel
                        value="nar"
                        control={<Radio size="small" />}
                        label="NAR"
                        style={{
                          fontSize: "1.5rem",
                          color: "var(--light-gray",
                        }}
                      />
                      <FormControlLabel
                        value="cn"
                        control={<Radio size="small" />}
                        style={{
                          fontSize: "1.5rem",
                          color: "var(--light-gray",
                        }}
                        // disabled
                        label="CN"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className={classes.environmentSection}>
                  <FormControl>
                    <FormLabel
                      id="env-radio-buttons-group-label"
                      style={{ fontSize: "1.5rem", color: "var(--light-gray" }}
                    >
                      Environment
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="env-radio-buttons-group-label"
                      value={
                        environment === "" ? preselectEnvironment : environment
                      }
                      onChange={(event) =>
                        environmentHandler(event.target.value)
                      }
                      name="radio-buttons-group"
                      row
                    >
                      <FormControlLabel
                        value="demo"
                        disabled
                        control={<Radio size="small" />}
                        label="Demo"
                        style={{
                          fontSize: "1.5rem",
                          color: "var(--light-gray",
                        }}
                      />
                      <FormControlLabel
                        value="approval"
                        control={<Radio size="small" />}
                        label="Approval"
                        style={{
                          fontSize: "1.5rem",
                          color: "var(--light-gray",
                        }}
                      />
                      <FormControlLabel
                        value="live"
                        disabled={region === "cn" ? true : false}
                        control={<Radio size="small" />}
                        label="Live"
                        style={{
                          fontSize: "1.5rem",
                          color: "var(--light-gray",
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0.75rem",
          left: "12.5%",
          width: "75%",
          textAlign: "center",
          color: "var(--light-gray)",
        }}
      >
        <Typography fontFamily={"var(--thin)"} fontSize={"0.75rem"}>
          This code is protected by intellectual property rights. Dr. Ing. h.c.
          F. Porsche AG owns exclusive rights of use. © 2024, Dr. Ing. h.c. F.
          Porsche AG.
        </Typography>
      </div>
    </>
  );
}

export default BackendData;
