import React, { useLayoutEffect, useState } from "react";
import classes from "./SearchBoxYear.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  selectFilterYear,
  setFilterYear,
} from "../../pages/Homepage/projectSlice";

function SearchBoxYear() {
  const [filter, setfilter] = useState("");
  const dispatch = useDispatch();
  const filterYear = useSelector(selectFilterYear);

  useLayoutEffect(() => {
    dispatch(setFilterYear(filter));
  }, [filter]);

  const setFilters = (event) => {
    setfilter(event.target.value);
  };

  return (
    <div className={classes.searchElementYear} data-cy='searchElement'>
      <div className={classes.searchControlYear} data-cy='searchControl'>
        <div className={classes.searchBoxYear} data-cy='searchBox'>
          <input
            id='searchDetailsYear'
            autoComplete='off'
            required
            data-cy='searchInput'
            value={filterYear}
            onChange={setFilters}
          ></input>
          <label
            htmlFor='searchDetailsYear'
            className={classes.searchLabel}
            data-cy='searchLabel'
          ></label>
        </div>
      </div>
    </div>
  );
}

export default SearchBoxYear;
