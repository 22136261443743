import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  presetEnvironment: "approval",
  paramsEnvironment: "",
  environment: "approval",
  presetRegion: "ece",
  paramsRegion: "",
  region: "ece",
  vin: "",
  paramsVIN: "",
  vinForVDSCheck: "",
  uuid: "",
  paramsUuid: "",
  emptyFields: false,
};

// Slice
const backendDataSlice = createSlice({
  name: "backendData",
  initialState,
  reducers: {
    setIsLoadingData: (state, action) => {
      state.isLoadingData = action.payload;
    },
    setEvironment: (state, action) => {
      state.environment = action.payload;
    },
    setParamsEnvironment: (state, action) => {
      state.paramsEnvironment = action.payload;
    },
    setRegion: (state, action) => {
      state.region = action.payload;
    },
    setParamsRegion: (state, action) => {
      state.paramsRegion = action.payload;
    },
    setVIN: (state, action) => {
      state.vin = action.payload;
    },
    setUuid: (state, action) => {
      state.uuid = action.payload;
    },
    setParamsVIN: (state, action) => {
      state.paramsVIN = action.payload;
    },
    setParamsUuid: (state, action) => {
      state.paramsUuid = action.payload;
    },
    setEmptyFields: (state, action) => {
      state.emptyFields = action.payload;
    },
    setVinForVDSCheck: (state, action) => {
      state.vinForVDSCheck = action.payload;
    },
  },
});

// Selectors

export const selectEnvironment = (state) => state.backendData.environment;
export const selectPresetEnvironment = (state) =>
  state.backendData.presetEnvironment;
export const selectRegion = (state) => state.backendData.region;
export const selectPresetRegion = (state) => state.backendData.presetRegion;
export const selectVIN = (state) => state.backendData.vin;
export const selectUuid = (state) => state.backendData.uuid;
export const selectEmptyFields = (state) => state.backendData.emptyFields;
export const selectParamsVIN = (state) => state.backendData.paramsVIN;
export const selectParamsUuid = (state) => state.backendData.paramsUuid;
export const selectParamsRegion = (state) => state.backendData.paramsRegion;
export const selectParamsEnvironment = (state) =>
  state.backendData.paramsEnvironment;
export const selectVinForVDSCheck = (state) => state.backendData.vinForVDSCheck;

// Actions

export const {
  setEvironment,
  setRegion,
  setVIN,
  setEmptyFields,
  setParamsEnvironment,
  setParamsRegion,
  setParamsVIN,
  setUuid,
  setParamsUuid,
  setVinForVDSCheck,
} = backendDataSlice.actions;

// Setters &/or thunk actions

export default backendDataSlice.reducer;
